import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Autocomplete} from "@mui/material";
import { createFilterOptions } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import {makeStyles} from '@mui/styles';

import {SnackbarFormattedError} from "../../../../helpers/snackbar";
import {networkService} from "../store/services";

const useStyle = makeStyles(() => ({
  textField: {},
  option: {
    width: '100%',
    borderBottom: `1px dashed ${grey[600]}`,
    '& p': {
      margin: 0,
      padding: 0,
    },
    '& span': {
      marginRight: 5,
    },
    '& .identifier': {
      margin: 0,
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      color: grey[800]
    },
  },
}));

const SearchConsultantAutocomplete = ({autoFocus, onChange}) => {

  const {t} = useTranslation();
  const classes = useStyle();
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {

    if(users.length === 0) {
      setUserSearchLoading(true);
      networkService.getConsultants()
        .then((users) => {
          setUsers(users);
          setUserSearchLoading(false);
        })
        .catch(e => {
          SnackbarFormattedError(e.response.data.error);
          setUserSearchLoading(false);
        });
    }
  }, [userSearch]);

  const filterOptions = createFilterOptions({
    stringify: option => `${option.directLink}${option.company}${option.zip}${option.city}${option.firstName} ${option.lastName} ${option.firstName}`,
  });

  const handleUserSearchChange = (e) => {

    setUserSearch(e.target.value);
  };

  return (
    <Autocomplete
      style={{ width: '100%' }}
      open={userSearchOpen}
      filterOptions={filterOptions}
      autoHighlight={true}
      onOpen={() => {
        setUserSearchOpen(true);
      }}
      onClose={() => {
        setUserSearchOpen(false);
      }}
      onChange={onChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={
        (option) => `${option.directLink ? `${option.directLink.toUpperCase()} - ` : ''} ${option.company ? `${option.company} - ` : ''} ${option.firstName && option.lastName ? `${option.firstName} ${option.lastName} - ` : ''} ${option.zip} ${option.city}`
      }
      renderOption={(option) => <div className={classes.option}>
        {option.directLink ? <p className="identifier">[ {option.directLink} ]</p> : ``}
        {option.company ? <p>{option.company}</p> : ``}
        {option.firstName && option.lastName ? <span>{option.firstName} {option.lastName} - </span> : ''}
        <span>{option.zip} {option.city}</span>
      </div>}
      options={users}
      noOptionsText={t('noNutritionConsultantFound')}
      loading={userSearchLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          autoFocus={autoFocus}
          label={t('consultants')}
          placeholder={t('searchByIdentifierCityOrPostalCode')}
          variant="outlined"
          onChange={handleUserSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {userSearchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default SearchConsultantAutocomplete;
