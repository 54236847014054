import React from "react";
import {useSelector} from "react-redux";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import theme from "../../assets/styles/theme/theme";
import {paymentMethodSelector} from "../../containers/Customer/Shop/store/selectors";

import BancontactPaymentMethod from "./PaymentMethodCards/BancontactPaymentMethod";
import CreditCardPaymentMethod from "./PaymentMethodCards/CreditCardPaymentMethod";


const MultisafepayPaymentMethodsSelector = () => {

  const paymentMethod = useSelector(paymentMethodSelector);

  return (
    <Box>
      <Grid container spacing={2}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontFamily: "Open sans",
            fontSize: "1.3rem",
            p: 1
          }}>
              Veuillez choisir votre mode de paiement
        </Typography>
        <Grid item xs={6} sm={5} xl={3}>
          <CreditCardPaymentMethod paymentMethod={paymentMethod}/>
        </Grid>
        <Grid item xs={6} sm={5} xl={3}>
          <BancontactPaymentMethod paymentMethod={paymentMethod}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultisafepayPaymentMethodsSelector;