import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

import PageLoader from "../../../components/PageLoader";

import CustomerCard from "./components/CustomerCard";
import CustomerNotesCard from "./components/CustomerNotesCard";
import { customerSelector } from "./store/selectors";

const Customer = () => {

  const customer = useSelector(customerSelector());

  if(!customer) {
    return (
      <PageLoader/>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CustomerCard customer={customer}/>
      </Grid>
      <Grid item xs={12}>
        <CustomerNotesCard customer={customer} />
      </Grid>
    </Grid>
  );
};
export default Customer;
