import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import {SnackbarFormattedError} from "../../../../helpers/snackbar";
import useMoment from "../../../../helpers/useMoment";
import CustomerGiftCardModal from "../../../Pro/Customers/components/CustomerGiftCardModal";
import {userService} from "../store/services";

import UserOfferGiftCardModal from "./UserOfferGiftCardModal";

const UserGiftCardsCard = ({user}) => {

  const {t} = useTranslation();
  const moment = useMoment();
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardSelected, setGiftCardSelected] = useState(null);
  const [giftCardModalOpen, setGiftCardModalOpen] = useState(false);
  const [isOfferGiftModalOpen, setOfferGiftModalOpen] = useState(false);

  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.hash?.substring(1));
    if(searchParams.get('checkoutResponse')) {
      setOfferGiftModalOpen(true);
    }
  }, []);

  useEffect(() => {
    loadGiftCards();
  }, [user.id]);

  const loadGiftCards = () => {
    userService.getUserGiftCards(user.id)
      .then(giftCards => {
        setGiftCards(giftCards.filter(a => a.type === "gift"));
        setCoupons(giftCards.filter(a => a.type === "refund"));
      })
      .catch(e => SnackbarFormattedError(e.error));
  };

  const handleOfferGiftCartModalOpen = () => {
    setOfferGiftModalOpen(true);
  };

  const handleOpenGiftCardModal = (giftCard) => () => {
    setGiftCardSelected(giftCard);
    setGiftCardModalOpen(true);
  };

  const handleSendGiftCardModal = () => {
    loadGiftCards();
  };

  return (
    <Card>
      <CardContent>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item xs={6}>
                <Typography component="h3" variant={"h6"}>
                  <Trans i18nKey={"giftCards"}>Gift cards</Trans> :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align={"right"}>
                  <Button variant="outlined" onClick={handleOfferGiftCartModalOpen} size={"large"} color={"primary"} disableElevation>
                    <Trans i18nKey={"offerGiftCard"}>Offer gift card</Trans>
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!giftCards || giftCards.length === 0 && (
              <Typography><Trans i18nKey={"noGiftCard"}>No gift card</Trans></Typography>
            )}
            {giftCards && giftCards.length > 0 && (
              <List>
                {giftCards.map(giftCard => (
                  <ListItem key={giftCard.id} onClick={handleOpenGiftCardModal(giftCard)} sx={{cursor: "pointer", ":hover":{backgroundColor: "primary.main"}}}>
                    <ListItemAvatar>
                      <Avatar>
                        <CardGiftcardIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${t('sentAt')} ${moment(giftCard.sentAt).format('LLL')}`} secondary={giftCard.usedAt ? `${t("usedAt")} ${moment(giftCard.usedAt).format('LLL')}` : `${t("cardHasNotBeenUsed")}`} />
                  </ListItem>
                ))}
              </List>
            )}
            <Grid item xs={6}>
              <Typography component="h3" variant={"h6"}>
                <Trans i18nKey={"coupons"}>Coupons</Trans> :
              </Typography>
            </Grid>
            {coupons && coupons.length > 0 && (
              <List>
                {coupons.map(coupon => (
                  <ListItem key={coupon.id} onClick={handleOpenGiftCardModal(coupon)} sx={{cursor: "pointer", ":hover":{backgroundColor: "primary.main"}}}>
                    <ListItemAvatar>
                      <Avatar>
                        <CardGiftcardIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${t('sentAt')} ${moment(coupon.sentAt).format('LLL')}`} secondary={coupon.usedAt ? `${t("usedAt")} ${moment(coupon.usedAt).format('LLL')}` : `${t("cardHasNotBeenUsed")}`} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CustomerGiftCardModal giftCard={giftCardSelected} onSubmit={handleSendGiftCardModal} setOpen={setGiftCardModalOpen} open={giftCardModalOpen}/>
      <UserOfferGiftCardModal
        setOpen={setOfferGiftModalOpen}
        open={isOfferGiftModalOpen}
        onChange={handleSendGiftCardModal}
        customer={user}
      />
    </Card>
  );
};
export default UserGiftCardsCard;
