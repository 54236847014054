import React from "react";
import { useSelector } from "react-redux";

import CustomerUpdateCard from "./components/CustomerUpdateCard";
import { customerSelector } from "./store/selectors";

const UpdateCustomer = () => {

  const customer = useSelector(customerSelector());

  return (
    <CustomerUpdateCard customer={customer} />
  );
};
export default UpdateCustomer;
