import React from "react";
import {useSelector} from "react-redux";
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import AllInclusiveTwoToneIcon from '@mui/icons-material/AllInclusiveTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import BugReportTwoToneIcon from '@mui/icons-material/BugReportTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import EuroIcon from '@mui/icons-material/Euro';
import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import SwapHorizontalCircleTwoToneIcon from '@mui/icons-material/SwapHorizontalCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import theme from "../../../assets/styles/theme/theme";
import {
  hasAuthSuperAdmin,
  hasRoleAdmin,
  isEditor, isEmployee,
  isLogistic
} from "../../../containers/Authentication/store/selectors";
import {Link} from "../../../helpers/multilingual";
import Menu from "../Menu/Menu";
import MenuItem from "../Menu/MenuItem";
import MenuSubItem from "../Menu/MenuSubItem";


const MenuDrawer = ({setMobileOpen}) => {

  const userIsAdmin = useSelector(hasRoleAdmin);
  const userIsEmployee = useSelector(isEmployee);
  const userIsLogistic = useSelector(isLogistic);
  const userIsEditor = useSelector(isEditor);
  const userHasAuthSuperAdmin = useSelector(hasAuthSuperAdmin);

  return (
    <div>
      <Toolbar>
        <Link to="/admin">
          <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`} alt="logo" sx={{width: '100%'}}/>
        </Link>
      </Toolbar>
      <Divider />
      <Menu sx={{
        '& .MuiListItemText-primary': {
          fontFamily: 'Open sans',
          color: 'black'
        },
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main
        }
      }}>
        <MenuItem
          title={"Dashboard"}
          to={"/admin"}
          icon={<SpeedTwoToneIcon/>}
          setMobileOpen={setMobileOpen}

        />
        <MenuItem
          title={"Statistiques"}
          to={"/statistics/sales"}
          icon={<AssessmentTwoToneIcon/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Boutique"}
          expandTo={"/admin/shop"}
          icon={<StorefrontTwoToneIcon/>}
        >
          <MenuSubItem
            title={"Laboratoires"}
            expandTo={"/admin/shop/brand"}
            to={"/admin/shop/brands"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Catégories"}
            expandTo={"/admin/shop/category"}
            to={"/admin/shop/categories"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Themes"}
            expandTo={"/admin/shop/theme"}
            to={"/admin/shop/themes"}
            icon={<ListAltTwoToneIcon />}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Mots clés"}
            expandTo={"/admin/shop/keyword"}
            to={"/admin/shop/keywords"}
            icon={<ListAltTwoToneIcon />}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Produits"}
            expandTo={"/admin/shop/product"}
            to={"/admin/shop/products"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Stocks"}
            to={"/admin/shop/stocks"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Unités"}
            to={"/admin/shop/packagings"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
        </MenuItem>
        <MenuItem
          title={"Rapports"}
          expandTo={"/admin/reports"}
          icon={<StorefrontTwoToneIcon/>}
        >
          <MenuSubItem
            title={"Utilisateurs"}
            expandTo={"/admin/reports/user"}
            to={"/admin/reports/users"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          {userHasAuthSuperAdmin && (
            <MenuSubItem
              title={"Commandes"}
              expandTo={"/admin/reports/order"}
              to={"/admin/reports/orders"}
              icon={<ListAltTwoToneIcon/>}
              setMobileOpen={setMobileOpen}
            />
          )}
          <MenuSubItem
            title={"Stocks"}
            expandTo={"/admin/reports/stock"}
            to={"/admin/reports/stocks"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
        </MenuItem>
        <MenuItem
          title={"Comptabilité"}
          expandTo={"/admin/accounting"}
          hidden={!userIsEmployee}
          icon={<AccountBalanceTwoToneIcon/>}
          //notificationsCount={commissionsCreditNoteCount + waitingTransfersCount + waitingChecksCount}
        >
          <MenuSubItem
            title={"Factures"}
            expandTo={"/admin/accounting/invoice"}
            to={"/admin/accounting/invoices"}
            icon={<AccountBalanceWalletTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Factures nulles"}
            expandTo={"/admin/accounting/proforma"}
            to={"/admin/accounting/proformas"}
            icon={<AccountBalanceWalletTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Notes de crédit"}
            expandTo={"/admin/accounting/creditNote"}
            to={"/admin/accounting/creditNotes"}
            icon={<AccountBalanceWalletTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Bons de commandes"}
            expandTo={"/admin/accounting/orderForms"}
            to={"/admin/accounting/orderForms"}
            icon={<AccountBalanceWalletTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuItem
            title={"Points"}
            hidden={!userIsAdmin}
            expandTo={"/admin/accounting/points"}
            icon={<MonetizationOnTwoToneIcon/>}
          >
            <MenuSubItem
              title={"Thérapeutes"}
              sx={{pl: {md:"50px"}}}
              expandTo={"/admin/accounting/points/therapists"}
              to={"/admin/accounting/points/therapists"}
              icon={<MonetizationOnTwoToneIcon/>}
              setMobileOpen={setMobileOpen}
            />
            <MenuSubItem
              title={"Médecins"}
              sx={{pl: {md:"50px"}}}
              expandTo={"/admin/accounting/points/doctors"}
              to={"/admin/accounting/points/doctors"}
              icon={<MonetizationOnTwoToneIcon/>}
              setMobileOpen={setMobileOpen}
            />
            <MenuSubItem
              title={"Délégués"}
              sx={{pl: {md:"50px"}}}
              expandTo={"/admin/accounting/points/delegates"}
              to={"/admin/accounting/points/delegates"}
              icon={<MonetizationOnTwoToneIcon/>}
              setMobileOpen={setMobileOpen}
            />
          </MenuItem>
          <MenuSubItem
            title={"Transferts"}
            expandTo={"/admin/accounting/transfer"}
            to={"/admin/accounting/transfers/published"}
            icon={<SwapHorizontalCircleTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Chèques"}
            expandTo={"/admin/accounting/check"}
            to={"/admin/accounting/checks"}
            icon={<SwapHorizontalCircleTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Contre remboursement"}
            expandTo={"/admin/accounting/cash-on-delivery"}
            to={"/admin/accounting/cash-on-delivery"}
            icon={<SwapHorizontalCircleTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Espace comptable"}
            to={"/accounting"}
            icon={<BusinessTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
        </MenuItem>
        <MenuItem
          title={"Systèmes"}
          hidden={!userIsAdmin}
          expandTo={"/admin/system"}
          icon={<MenuBookTwoToneIcon/>}
        >
          <MenuSubItem
            title={"Commissions"}
            to={"/admin/systems/commissions"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Promotions"}
            to={"/admin/systems/promotions"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Pays et codes TVA"}
            to={"/admin/systems/vats"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Transporteurs"}
            to={"/admin/systems/shippings"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Remises"}
            to={"/admin/systems/discounts"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Sacs postaux"}
            to={"/admin/systems/bags"}
            icon={<ListAltTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
        </MenuItem>
        <MenuItem
          title={"Manager"}
          to={"/manager"}
          hidden={!userIsEditor}
          icon={<MenuBookTwoToneIcon/>}
          setMobileOpen={setMobileOpen}
          //notificationsCount={shopReviewsTodoCount}
        />
        <MenuItem
          title={"Logistique"}
          to={"/logistic/orders/todo"}
          hidden={!userIsLogistic}
          icon={<EventNoteTwoToneIcon/>}
          setMobileOpen={setMobileOpen}
          //notificationsCount={starterKitToSendCount}
        />
        <MenuItem
          title={"Erreurs"}
          expandTo={"/admin/errors"}
          hidden={!userIsAdmin}
          to={"/admin/errors"}
          icon={<WarningTwoToneIcon/>}
          setMobileOpen={setMobileOpen}
        />
        <MenuItem
          title={"Logs"}
          expandTo={"/admin/logs"}
          hidden={!userIsAdmin}
          icon={<BugReportTwoToneIcon/>}
        >
          <MenuSubItem
            title={"Tous les logs"}
            to={"/admin/logs/all"}
            icon={<AllInclusiveTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Erreurs"}
            to={"/admin/logs/error"}
            icon={<ErrorTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Infos"}
            to={"/admin/logs/info"}
            icon={<InfoTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Infos panier"}
            to={"/admin/logs/cartInfo"}
            icon={<ShoppingCartIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Paiements"}
            to={"/admin/logs/paymentInfo"}
            icon={<EuroIcon/>}
            setMobileOpen={setMobileOpen}
          />
          <MenuSubItem
            title={"Debug"}
            to={"/admin/logs/debug"}
            icon={<BugReportTwoToneIcon/>}
            setMobileOpen={setMobileOpen}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MenuDrawer;
