export const CHIROPRACTIC = 'Chiropracteur';
export const SPORT_COACH = 'Coach sportif et nutrition';
export const DENTIST = 'Dentiste';
export const DERMATOLOGIST = 'Dermatologue';
export const DIETICIAN = 'Diététicien.ne';
export const GENERALIST = 'Généraliste';
export const GYNECOLOGIST = 'Gynécologue';
export const NURSE = 'Infirmier.e';
export const KINE = 'Kiné / ostéopathe';
export const AESTHETIC_MEDICINE = 'Médecine esthétique';
export const FUNCTIONAL_MEDICINE = 'Médecine fonctionnelle';
export const NATUROPATH = 'Naturopathe';
export const NUTRITIONIST = 'Nutritionniste';
export const ENT = 'ORL';
export const PSYCHOTHERAPIST = 'Psychothérapeute';
export const SEXOLOGIST = 'Sexologue';