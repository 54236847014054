import {createSelector} from 'reselect';

import {
  ACCOUNTANT_AUTH,
  ADMIN_ROLE, CUSTOMER_ROLE,
  DELEGATE_ROLE, DOCTOR_ROLE,
  EDITOR_AUTH, EMPLOYEE_ROLE, LOGISTIC_AUTH, PRO_ROLE, PROSPECT_ROLE, STOCK_NRD_AUTH, SUPER_ADMIN_AUTH, THERAPIST_ROLE
} from "./constants";

export const userSelector = (state) => state.authentication.user;
export const contactSelector = (state) => state.authentication.contact;

export const getUserShippingAddressSelector = createSelector(
  [userSelector],
  (user) => user && user.shippingAddress ? user.shippingAddress : null);

export const getUserRoleSelector = createSelector(
  [userSelector],
  (user) => user && user.role ? user.role : null);

export const getUserAuthSelector = createSelector(
  [userSelector],
  (user) => (user && user.authorizations) || []);

export const isSessionControl = createSelector(
  [userSelector],
  (user) => user && !!user.isSessionControl);

export const isSuperAdmin = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE
  ));
export const isMegaAdmin = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE
  ));
export const isGod = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE
  ));
export const isAdmin = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE
  ));
export const isEmployee = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE ||
            role === EMPLOYEE_ROLE
  ));
export const isPro = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE ||
            role === THERAPIST_ROLE ||
            role === DOCTOR_ROLE ||
            role === DELEGATE_ROLE
  ));
export const hasRoleAdmin = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE
  ));
export const hasRoleEmployee = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === EMPLOYEE_ROLE
  ));
export const isLogistic = createSelector(
  [hasRoleAdmin, hasRoleEmployee, getUserAuthSelector],
  (admin, employee, authorizations) => admin || (employee && authorizations.includes(LOGISTIC_AUTH)));

export const isEditor = createSelector(
  [hasRoleAdmin, hasRoleEmployee, getUserAuthSelector],
  (admin, employee, authorizations) => admin || (employee && authorizations.includes(EDITOR_AUTH)));

export const isAccountant = createSelector(
  [hasRoleAdmin, hasRoleEmployee, getUserAuthSelector],
  (admin, employee, authorizations) => admin || (employee && authorizations.includes(ACCOUNTANT_AUTH)));
export const hasAuthSuperAdmin = createSelector(
  [getUserAuthSelector],
  (auth) => auth && (auth.includes(SUPER_ADMIN_AUTH)));
export const hasAuthStockNRD = createSelector(
  [getUserAuthSelector],
  (auth) => auth && (auth.includes(STOCK_NRD_AUTH)));
export const hasAuthCoach = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE ||
            role === PRO_ROLE ||
            role === EMPLOYEE_ROLE ||
            role === DELEGATE_ROLE
  ));
export const hasAuthConsultant = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE ||
            role === PRO_ROLE ||
            role === EMPLOYEE_ROLE ||
            role === DELEGATE_ROLE
  ));
export const isCustomer = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE ||
            role === PRO_ROLE ||
            role === DELEGATE_ROLE ||
            role === EMPLOYEE_ROLE ||
            role === CUSTOMER_ROLE
  ));
export const isRegistered = createSelector(
  [getUserRoleSelector],
  (role) => role && (
    role === ADMIN_ROLE ||
            role === PRO_ROLE ||
            role === DELEGATE_ROLE ||
            role === EMPLOYEE_ROLE ||
            role === CUSTOMER_ROLE ||
            role === PROSPECT_ROLE
  ));
export const isProspect = createSelector(
  [getUserRoleSelector],
  (role) => role && role === PROSPECT_ROLE);
export const structureMembershipModalOpenSelector = (state) => state.authentication.structureMembershipModalOpen;
export const partnerMembershipModalOpenSelector = (state) => state.authentication.partnerMembershipModalOpen;
export const membershipAlertedSelector = (state) => state.authentication.membershipAlerted;
export const membershipDaysRemainingSelector = createSelector(
  [userSelector],
  (user) => Math.round((new Date(user.membershipEndAt) - Date.now()) / (1000 * 3600 * 24)));
export const hasMembershipSelector = createSelector(
  [membershipDaysRemainingSelector],
  (daysRemaining) => (daysRemaining > 0));

export const walletAllowedSelector = createSelector(
  [getUserRoleSelector],
  (role) => role === THERAPIST_ROLE || role === DOCTOR_ROLE || role === ADMIN_ROLE);

export const updateUserLoading = (state) => state.authentication.updateUserRequested;
export const reloadUserRequested = (state) => state.authentication.reloadUserRequested;
