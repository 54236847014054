import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import {getLangPath} from "../../../../../helpers/multilingual";
import {getFormalizedName} from "../../helpers/helper";
import { shopActions } from "../../store/actions";
import {sessionSelector} from "../../store/selectors";

import ProductsAutocomplete from "./ProductsAutocomplete";

const SearchBarHeaderButtons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector(sessionSelector);
  const [search, setSearch] = useState('');

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectProduct = (product) => {

    dispatch(shopActions.searchDrawerOpen(false));
    dispatch(shopActions.setFromInternalNavigation(true));
    history.push(`${getLangPath()}/shop/product/${getFormalizedName(product.reference)}/${product.id}`);
  };

  return (
    <ProductsAutocomplete
      variant={'outlined'}
      country={session?.country || 'LU'}
      onSelect={handleSelectProduct}
      onChange={handleChange}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          borderRadius: '30px',
          height: '52px',
        }
      }}
    />
  );
};

export default SearchBarHeaderButtons;
