export default  {
  "You have successfully subscribed to our newsletter": "U heeft zich succesvol geabonneerd op onze nieuwsbrief",
  "errorMessageRequiredBirthDate": "La date de naissance est requise",
  "emailLabel": "E-mail",
  "errorMessageOrderPriceMin": "Le montant minimum d'une commande (hors commandes gratuites) est de 0,50€",
  "mister": "Dhr.",
  "miss": "Mevr",
  "noGender": "genderneutraal",
  "genderLabel": "Geslacht",
  "therapNameLabel": "Achternaam en voornaam van uw therapeut, gezondheidsprofessional die u onze producten heeft aanbevolen *",
  "codeLabel1": "Ik heb een code ?",
  "codeLabel2": "Je code vind je rechtsboven op je bestelformulier",
  "codeLabel3": "Ik heb geen code ?",
  "codeLabel4": "Voer de tijdelijke",
  "codeLabel5": "-code in om uw account aan te maken.",
  "codeLabel6": "Een adviseur neemt binnenkort contact met u op om uw dossier bij te werken.",
  "unitLabel": "CAPSULES",
  "weightLabel": "NETTO GEWICHT",
  "fullBrandNameNutri-logics": "Nutri-logics - Wereldwijde ondersteuning voor gezondheid",
  "homeMetaDescription": "Nutri-logics is een bedrijf dat gespecialiseerd is in de productie en distributie van voedingssupplementen en voedingsadvies.",
  "homeTitle": "Wereldwijde ondersteuning voor gezondheid",
  "shopLinkTitle": "Ga naar de winkel",
  "shopLinkDescription": "Bestel uw voedingssupplementen in onze online winkel. Bestellingen worden dezelfde dag klaargemaakt.",
  "becomePartner": "Word partner",
  "becomePartnerLinkDescription": "Wilt u een Nutri-logics-partner worden? Hier vindt u alle details.",
  "aboutLinkTitle": "Nutri-logics, de MAG",
  "aboutLinkDescription": "Ontdek Nutri-logics via haar community, haar goede deals en haar voedingssupplementendiensten.",
  "consultation": "consultatie",
  "nutritionConsultantP1": "Nutri-logics is een bedrijf gevestigd in Luxemburg, gespecialiseerd in de productie van hoogwaardige voedingssupplementen, in samenwerking met de ESE-groep, die zich toelegt op wetenschappelijk onderzoek op het gebied van voeding.",
  "nutritionConsultantP2": "Dankzij het efficiënte netwerk van nutritherapeuten, vertegenwoordigers en consultants die gespecialiseerd zijn in de distributie van deze supplementen, wisselt Nutri-logics uit en werkt het samen met artsen, osteopaten, fysiotherapeuten en hypnotherapeuten in een wereldwijde benadering van gezondheid.",
  "nutritionConsultantSearchTitle": "Wilt u een voedingsdeskundige bij u in de buurt raadplegen?",
  "globalObservationTitle": "Onze observaties",
  "globalObservationP1": "Tegenwoordig sterven we meer door te veel te eten of ongezond te eten dan door honger!",
  "globalObservationP2": "Volgens de WHO (Wereldgezondheidsorganisatie) is ondervoeding verantwoordelijk voor 9,1 miljoen sterfgevallen per jaar, terwijl junkfood 11 miljoen sterfgevallen veroorzaakt!",
  "globalObservationP3": "Een op de twintig mensen lijdt aan diabetes, dat wil zeggen 350 miljoen mensen ter wereld.",
  "globalObservationP4": "En van de wereldbevolking is ongeveer 3 miljard mensen te zwaar, terwijl 42 miljoen kinderen lijden aan obesitas...",
  "globalObservationLink": "Meer zien...",
  "globalObservationConclusion": "Via het netwerk van Nutri-logics willen we het bewustzijn vergroten over het belang van de kwaliteit van ons voedsel. <br/>We zijn ervan overtuigd dat iedereen de kans kan grijpen om positieve keuzes te maken voor een bord dat zowel lekker als rijk is aan voedingsstoffen die essentieel zijn voor onze fysieke en mentale ontwikkeling. <br/>Het is tijd om een ein",
  "foodSupplementsTitle": "Nutri-logics voedingssupplementen",
  "foodSupplementsP1": "Onze klanten genieten van het beste van de natuur! <br/> De kwaliteit die wordt geleverd door onze partners (producenten en laboratoria) stelt ons in staat om voorop te lopen in onze productkennis.",
  "foodSupplementsP3": "Onze samenwerking met de wetenschappelijke onderzoeksafdeling van ESE GROUP stelt ons in staat om zorgvuldig de componenten van de supplementen te selecteren die worden ontwikkeld in ons laboratorium. <br/>ESE Group innoveert voortdurend op het gebied van voeding en voedingsonderzoek.",
  "foodSupplementsP6": "Onze capsules zijn ontworpen zonder hulpstof, een garantie voor kwaliteit. Deze producten komen uit verschillende laboratoria die internationaal bekend staan om hun kwaliteit en innovatie.",
  "foodSupplementsP7": "Onze producten ondergaan voortdurende verbetering om gelijke tred te houden met de meest recente relevante ontdekkingen op medisch en gezondheidsgebied.",
  "philosophyTitle": "Onze verbintenissen",
  "philosophyP1": "Onberispelijke productkwaliteit!",
  "philosophyP2": "Onverslaanbare prijs-kwaliteitverhouding",
  "philosophyP3": "Een sterke betrokkenheid bij een meer hulpbronvriendelijke wereld",
  "philosophyP3Li1": "100% biologisch afbreekbare verpakkingen.",
  "philosophyP3Li2": "Productie gericht op zero waste.",
  "philosophyP3Li3": "Beheersing van de koolstofvoetafdruk van onze productieketen dankzij boomplanting (elke geplante boom vertegenwoordigt een absorptie van +/- 22kg CO2 / jaar).",
  "philosophyP4": "Een visie van solidariteit in de wereld",
  "philosophyP4Li1": "Deelname aan de actie tegen kinderondervoeding in onderontwikkelde landen door het planten van Moringa-bomen die bladeren bevatten die rijk zijn aan plantaardige eiwitten.",
  "shop": "Winkel",
  "addressSearch": "Adres",
  "addressAlias": "Adresalias (optioneel)",
  "addressAliasRequired": "Adresalias",
  "postalBox": "Postbus",
  "addressSearchPlaceholder": "Voeg een stad, een plaats, een adres toe",
  "profilingTitle": "Profilering",
  "proSpace": "Pro-sectie",
  "charityInfo": "Nutri-logics ondersteunt de vereniging {{name}}",
  "charityInfoAmount": "{{bedrag}} € al ingezameld",
  "environmentalFriendly": "Milieuvriendelijk",
  "treesCounterDescription": "Elke bestelling draagt bij aan het planten van Moringa-bomen in Senegal.",
  "treesCounterInfo": "Thanks to your orders, Nutri-logics has planted {{amount}} trees.",
  "treesContributionInfo": "Each order contributes to plant Moringa trees in Senegal to participate in the reduction of the carbon footprint. <1/>(One tree = Reduction of 22kilos of CO2 / year).",
  "treeProperties": "Moringa is also an intelligent tree that has nutritional and therapeutic properties.",
  "loading": "Loading",
  "learnMore": "Learn more",

  "followUsInstagram": "To not miss anything from Nutri-logics news, follow us on",

  //CAREER OPPORTUNITY
  "networkOpportunityMetaDescription": "Become an Nutri-logics partner, certification as a nutrition advisor, professional reorientation",
  "founderCEONutri-logics": "Founder and CEO of Nutri-logics",
  "partnerUpYoYourAmbitions": "A partner up to your ambitions.",
  "networkOpportunityH1": "A career for professionals",
  "networkOpportunityP1": "The Nutri-logics remuneration system differs from other network marketers in that the delegate's income will depend more on the efficient management of his portfolio than on the acquisition of new clients.",
  "networkOpportunityP2": "The healthcare sector is a sector that requires solid knowledge in physiology and anatomy. It is therefore fundamental to have people in the field who are extremely well trained in the subject.",
  "networkOpportunityH2": "Activate your desire to redirect your career by choosing a job that makes sense!",
  "networkOpportunityP3": "The Nutri-logics partnership offers 4 ways of collaboration:",
  "networkOpportunityP4": "The first is oriented towards advice and support by becoming a nutritherapist certified by the European Academy of Nutrition. This path is an opportunity towards a real profession.",
  "networkOpportunityP5": "The second offers prospects for managerial development such as managing a team of delegates specialised in food supplements. This path will require you to develop coaching and management skills, with the possibility of opening, in collaboration with the parent company, an Nutri-logics center.",
  "networkOpportunityP51": "The third path is oriented towards specializing in sales with a profile, called \"Direct Sales\". The objective of this path is to create partnerships with institutions of health, well-being, sport, ...",
  "networkOpportunityP52": "The fourth and last way concerns institutions and distributors of Nutri-logics supplements.",
  "networkOpportunityP6": "Four \"win-win\" ways, in a positive working atmosphere, where the human element is an essential.",
  "networkOpportunityH3": "Support for delegated candidates.",
  "networkOpportunityP7": "The quality of Nutri-logics's professional support is essential in order to provide the best possible advice to our clients. Each delegate is carefully selected and continuously trained until he or she obtains certification as a Nutrition Consultant or Nutritherapist, which guarantees a high level of competence, irreproachable service and optimal advice.",
  "networkOpportunityH4": "How do I become an Nutri-logics delegate?",
  "networkOpportunityP8": "The first step in becoming an Nutri-logics delegate is to complete the profiling test, which can be found on our website. Next, the future delegate must be supervised by one of our consultants. It is not possible to have access to the network without going through this step, which guarantees the quality of the training.",
  "networkOpportunityP9": "The European Academy of Nutrition is the only school recognised by Nutri-logics to train staff. Thanks to this partnership, our delegates and consultants receive in-depth training that leads to a real job and recognised certification.",
  "networkOpportunityP10": "The competence of the staff trained in this way is a fundamental element in Nutri-logics's growth.",
  "profilingGDPR": "We are subject to rgpd rules and aim to respect them through this test.",
  "dataWarning": "We are committed to treating your information with the utmost discretion.",
  "strengthsTitle": "The advantages and opportunities of being a nutritherapist at Nutri-logics",
  "strengthsP1": "A training course certified by the European Academy of Nutrition",
  "strengthsP2": "Continued education with renowned doctors, scientists and nutritionists.",
  "strengthsP3": "Work in and under supervision of an international structure.",
  "strengthsP4": "A client base supplied by Nutri-logics.",
  "strengthsP5": "Access and work with \"Top of the range\" products",
  "strengthsP6": "Team-work with the team consultants and delegates.",
  "strengthsP7": "Enjoy a working - space in an Nutri-logics centre.",
  "strengthsP8": "Collaboration with physiotherapists, osteopaths, hypnotherapists.",
  "strengthsP9": "Being part of the nutritherapists’ network in our specialised application.",
  "strengthsP10": "Access to new health technologies.",
  "strengthsP11": "Innovative tool.",
  "perspectivesTitle": "Network perspectives",
  "perspectivesP1": "Obtain an education certificate accredited in Europe",
  "perspectivesP2": "Secure a regular income",
  "perspectivesP3": "Meaningful work based on human and ecological values. ",
  "perspectivesP4": "Benefit from training in nutratherapy, anatomy, biology but also in new technologies and techniques for sales and personal development (prospecting, coaching, recruitment, network development, management, communication, management, motivation, leadership, strategy...)",
  "perspectivesP5": "Trips, seminars, conferences.",
  "perspectivesP6": "Participate in the development of an Nutri-logics centre.",
  "perspectivesP7": "Grow as an nutrition consultant and benefit from a management structure.",
  "perspectivesP8": "Participate in humanitarian activities related to well-being.",
  "perspectivesP9": "Become competent in nutrition education and information.",
  "perspectivesP10": "Contribute to the reduction of carbon footprint.",
  "perspectivesP11": "Work organisation, common sense, passion, strength of the group.",
  "perspectivesP12": "Courteous client assistance.",
  "perspectivesP13": "Remuneration that values personal investment in building our network.",
  "profilingTestButton": "Go to the profiling test",
  "profilingTestButtonMin": "Profiling test",
  "academyTitle": "Our training school",
  "academyP1": "<0>The European Academy of Nutrition</0>,  is a brand new entity of the European coaching academy which with its status and competence, allows us to offer you an education certification recognised in Europe.",
  "academyP2": "Given the quality of the lecturers, the total cost of the training program (1.300€) is particularly attractive.",
  "academyP3": "All the trainers are professionals (medical pharmacist, biochemist) qualified and experienced in their field. Our delegates benefit from quality training that is regularly updated with the latest findings and practices in the field.",
  "academyP4": "Nutri-logics advisers must be accurate and efficient with the information they provide to clients.",
  "academyP5": "Something magical about training and education is - once you learn something, no one can take it away from you anymore.",
  "certificationDetails": "Get the certification details",
  "noConsultantFound": "",

  //SHOP
  "missingShippingAddress": "Je moet een verzendadres kiezen",
  "delivery": "Levering",
  "addAddress": "Voeg een adres toe",
  "click&collect": "Click & collect",
  "atHome": "Thuis",
  "to": "Naar",
  "changeAddress": "Adres wijzigen",
  "shopMetaDescription": "Ontdek hoogwaardige voedingssupplementen van Nutri-logics. Vind het Nutri-logics pakket dat bij je past: Nutri-logics detox pakket, immuniteit pakket, gewichtsbeheer pakket, slaapbeheer pakket, spijsvertering pakket, zakelijk pakket, darm pakket, pijn pakket ... Onze capsules worden zonder hulpstof gemaakt, een garantie voor kwaliteit.",
  "shopTitle": "Nutri-logics Winkel",
  "shopProTitle": "Nutri-logics PRO Winkel",
  "backToShop": "Terug naar de winkel",
  "allProducts": "Alle producten",
  "deliveryCountry": "Leveringsland",
  "productSearchByText": "Zoek op product...",
  "productSearchByTextMin": "Zoeken...",
  "moreCategories": "Meer categorieën",
  "addToCart": "Toevoegen aan winkelwagen",
  "packetDelivery": "Pakket locker",
  "removeItemFromCart": "Verwijder item uit winkelwagen",
  "productNoLongerInShop": "Dit product is niet langer in de winkel",
  "searchConsultantToAccessShop": "Om toegang te krijgen tot de winkel moet je je voedingsconsulent selecteren. Als je een bestaande Nutri-logics klant bent, log dan eenvoudigweg in.",
  "searchTitle": "Zoekopdracht {{search}}",
  "newSearch": "Nieuwe zoekopdracht",
  "moreProductsButton": "Toon meer producten",
  "proShop": "PRO winkel",
  "proPrices": "PRO prijzen",
  "invalidLink": "Ongeldige link",
  "invalidDirectLinkDescription": "De link die je hebt gevolgd is momenteel ongeldig of inactief. Je kunt later in de winkel je voedingsconsulent vinden of kiezen.",
  "Nutri-logicsWishesPleasantVisit": "Het Nutri-logics team wenst je een aangenaam bezoek aan onze site.",
  "continueToTheSiteButton": "Ga door naar de site",
  "catalog": "Producten",
  "catalogMetaDescription": "Ontdek hoogwaardige voedingssupplementen van Nutri-logics. Vind het Nutri-logics pakket dat bij je past: Nutri-logics detox pakket, immuniteit pakket, gewichtsbeheer pakket, slaapbeheer pakket, spijsvertering pakket, zakelijk pakket, darm pakket, pijn pakket ... Onze capsules worden zonder hulpstof gemaakt, een garantie voor kwaliteit.",
  "yourNLAccount": "Je Nutri-logics account",
  "useFromWallet": "Gebruik {{amount}}{{currency}} van je Nutri-logics account",
  "agsCredit": "Nutri-logics credit",
  "Nutri-logicsGiftCard": "Nutri-logics cadeaubon",
  "selectTheGiftCard": "Selecteer de cadeaubon",
  "enterTheGiftCard": "Voer uw cadeauboncode in",
  "giftCardSent": "Cadeaubon succesvol verzonden naar de klant",
  "giftCardAddedDescription": "De cadeaubon is geregistreerd",
  "buyGiftCardForCustomerDescription": "Koop een cadeaubon? Nadat de betaling is voltooid, zijn de cadeaubonnen beschikbaar in uw account en kunt u ze naar uw klanten sturen.",
  "leftToPay": "Te betalen bedrag",
  "buyInShop": "Koop in de winkel",
  "shareThisOrder": "Deel deze bestelling",
  "outOfStockWarning": "Dit product is momenteel uitverkocht en zal zeer binnenkort beschikbaar zijn. U ontvangt het apart van uw andere aankopen.",
  "reviews": "Beoordelingen",
  "review": "beoordeling",
  "review_plural": "beoordelingen",
  "calculatedFromReview": "Berekend uit {{count}} klantbeoordeling",
  "calculatedFromReview_plural": "Berekend uit {{count}} klantbeoordelingen",
  "noReviewsYet": "Er zijn nog geen beoordelingen voor dit product",
  "youNeedToRateTheProduct": "U moet het product beoordelen",
  "reviewSaved": "Beoordeling opgeslagen",
  "writeReviewAbout": "(Optioneel) Schrijf een beoordeling over dit product ...",
  "howWasTheProduct": "Hoe was het product {{name}}?",
  "verySatisfied": "Zeer tevreden",
  "satisfied": "Tevreden",
  "neutral": "Neutraal",
  "dissatisfied": "Ontevreden",
  "veryDissatisfied": "Zeer ontevreden",
  "showOriginal": "Toon origineel",
  "translate": "Vertalen",
  "searchByNameOrComposition": "Zoeken op naam of samenstelling",

  //SHOP ITEM
  "themes": "Thema's",
  "labos": "Laboratoria",
  "categories": "Categorieën",

  "limited": "Beperkt",
  "limitedCommissions": "Beperkte commissies",
  "discount": "Korting",
  "unsupportedDefaultShippingAddress": "Uw standaard verzendadres bevindt zich in een niet-ondersteund land.",
  "card": "Kaart",
  "definition": "Definitie",
  "usage": "Gebruik",
  "recommendations": "Aanbevelingen",
  "indications": "Indicaties",
  "conservation": "Conservering",
  "composition": "Samenstelling",
  "video": "Video",
  "boxContains": "Box contains",
  "proInfo": "Pro info",
  "recommendedDosage": "Recommended dosage",
  "contraindications": "Contraindications",
  "guaranteedWithout": "Guaranteed without",
  "precautionsForUse": "Precautions for use",
  "excipients": "Excipients",
  "ingredients": "Ingredients",
  "usageTips": "Usage tips",
  "ml": '{{count}} ml',
  "mlMin": '{{count}} ml',
  "g": '{{count}} g',
  "gMin": '{{count}} g',
  "capsule": "{{count}} capsule",
  "capsuleMin": "{{count}} Caps",
  "capsule_plural": "{{count}} capsules",
  "pill": "{{count}} compressed",
  "pillMin": "{{count}} Comp",
  "pill_plural": "{{count}} tablets",
  "stick": "{{count}} stick",
  "stickMin": "{{count}} stic",
  "sticks_plural": "{{count}} sticks",

  //SHOPPING CART
  "shoppingCart": "Shopping cart",
  "qtyCart": "Qty",
  "proFee": "Pro fee",
  "subTotal": "Sub total",
  "taxes": "Taxes",
  "totalPrice": "Total price",
  "cartContinueShoppingButton": "Continue shopping",
  "cartOrderButton": "Order",
  "shippingCalculationOnCheckout": "Shipping price will be calculated on checkout",

  //SHOP CHECKOUT
  "checkoutTitle": "Checkout",
  "billedTo": "Billed to",
  "coupons": "Coupons",
  "coupon": "Coupon",
  "NLGiftCardsAndCoupons": "Nutri-Logics gift cards and coupons",
  "updateMyInformation": "Update my information",
  "shippedTo": "Shipped to",
  "addNewPaymentMethod": "Add a new payment method",
  "useKnownPaymentMethod": "Use known payment method",
  "SEPA_DEBIT": "SEPA DEBIT",
  "CARD": "CARD",
  "addNewShipping": "Add new address",
  "deliveryMethod": "Delivery method",
  "notSupportedShippingAddress": "This shipping address is located in a country that is not supported. Please chose a shipping address in a supported country.",
  "paymentInformation": "Payment information",
  "otherPaymentMethods": "Other payment methods",
  "availablePaymentMethods": "Available payment method",
  "payment": "Payment",
  "paymentBy": "Payment by {{paymentMethod}}",
  "creditcard": "Credit card",
  "ideal": "Ideal",
  "idealBank": "Ideal Bank",
  "bancontact": "Bancontact",
  "applePay": "Apple Pay",
  "googlePay": "Google Pay",
  "yourBrowserDoesNotSupportThisPayment": "Your browser does not support this payment",
  "processing": "Processing ...",
  "sofort": "Sofort",
  "deliveryMethodRequired": "You need to select a delivery method to access the payment form",
  "home": "Home",
  "shippingAddressName": "Shipping address name",
  "giveNameToYourAddress": "Give your address a name to find it easily",
  "shippingAddress": "Shipping address",
  "order": "Order",
  "yourOrder": "Your order",
  "workingDays": "2 - 3 working days",
  "bpost": "Bpost",
  "withoutDelivery": "Without shipping",
  "collectTheOrderAtTheWarehouse": "Collect the order at the warehouse",
  "cardNumber": "Card number",
  "expirationDate": "Expiration date",
  "cvc": "CVC",
  "payButton": "Pay {{amount}}",
  "pay": "Pay",
  "validateOrder": "Validate order",
  "shippingPrice": "Shipping price",
  "shipping": "Shipping",
  "pickupPoint": "Relay points",
  "freeShippingInfo": "Again {{amount}} for free shipping",
  "freeDeliveryBenefit": "You benefit from free delivery",
  "free": "Free",
  "selectedShippingAddress": "Select a delivery address",
  "yourCartIsEmpty": "Your cart is empty",
  "goToTheShop": "Go to the store",
  "shippingAddressRequired": "You must add a delivery address and select the delivery method to access the payment form",
  "search": "Search",
  "shippingInformation": "Shipping information",
  "waitingDeliveryStatus": "Order received",
  "processingDeliveryStatus": "Prepared awaiting delivery",
  "deliveredDeliveryStatus": "Delivered",
  "inTransitDeliveryStatus": "In transit",
  "inPickupPointDeliveryStatus": "Waiting in the pickup-point",
  "backToSenderDeliveryStatus": "Back to sender",
  "selectShippingMethod": "Select shipping method",
  "congratulationNewOrder": "Congratulations, your order has been confirmed",
  "youWillBeRedirectedInFewSeconds": "You will be redirected automatically in a few seconds.",
  "paymentInProgress": "Your payment is being processed",
  "paymentProcessing": "Your payment is being processed, you will receive an email as soon as your order has been confirmed.",
  "yourPaymentIsBeingValidated": "Your payment is being validated",
  "paymentOperationMayTakeAWhile": "This operation may take a while. You can close your browser. An email will be sent to you as soon as your payment is finalized.",
  "paymentMethodNeedAction": "Your payment method need another action to be complete.",
  "paymentMethodNeedActionDescription": "Your bank needs additional action to process the payment such as 3D Secure. After validation click on the button below.",
  "verifyAgain": "Verify again",
  "chooseAnotherPaymentMethod": "Choose another payment method",
  "unableToVerifyYourPayment": "We are unable to verify your payment. If the operation persists for more than one minute. Please contact us to let us know your error.",
  "accountHolderName": "Account holder name",
  "paymentError": "Payment was not processed, please try again or choose another payment method",
  "paymentCanceled": "Payment was not processed, please try again or choose another payment method",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "sunday": "Sunday",
  "select": "Select",
  "closingFromTo": "Closed from {{closedFrom} to {{closedTo}}",
  "choosePickupPoint": "Choose your pickup point",
  "viewInList": "View in list",
  "viewOnMap": "View on map",

  //PROFILING
  "consultantMet": "I know an Nutri-logics consultant",
  "firstName": "First name",
  "lastName": "Last name",
  "profilingDescription": "Your application will be examined by the recruiting manager who will contact you as soon as possible.",
  "profilingApplicationAlreadyAccepted": "Your application has already been accepted.",
  "goToProSection": "Go to the pro section.",
  "temporaryLunchMessage": "The official start of the Nutri-logics career is scheduled for the end of December.",
  "nutritionConsultantMet": "I met an Nutri-logics nutrition consultant",
  "nutritionConsultantCode": "Nutri-logics nutrition consultant identifier",
  "birthday": "Date of birth",
  "locality": "Locality",
  "levelOfStudy": "Education level",
  "additionalCertification": "Additional certification",
  "currentOccupation": "Current occupation",
  "currentStatus": "Current status",
  "unemployed": "Unemployed",
  "employee": "Employee",
  "pro": "Therapist",
  "contractor": "Company manager",
  "familySituation": "Family situation",
  "inARelationshipWith": "As a couple",
  "alone": "Alone",
  "couplesWithChildren": "In couple with child",
  "aloneWithChild": "Alone with child",
  "children": "How many child (ren) do you have?",
  "child": "Child",
  "child1": "Child age 1",
  "child2": "Child age 2",
  "child3": "Child age 3",
  "child4": "Child age 4",
  "child5": "Child age 5",
  "child6": "Child age 6",
  "yearOld": "year",
  "yearOld_plural": "years",
  "moreThan20Year": "more than 20 years old",
  "ageOfChildren": "Age of the child(ren)",
  "driverLicense": "Driver's license",
  "vehicle": "Vehicle",
  "certifyingTraining": "I have already completed certification training in the following areas",
  "noTraining": "No training taken",
  "commercialField": "The commercial",
  "generalWellBeing": "General well-being",
  "nutrition": "Nutrition",
  "coaching": "Coaching",
  "management": "The management",
  "groupStrategy": "Group or company strategy",
  "medicalDelegation": "The medical or pharmaceutical delegation",
  "nameOfInstitution": "Name of the certifying institution (s)",
  "howMuchTime": "How much time per week can I devote to my Nutri-logics activity?",
  "lessThan5Hours": "less than 5 hours",
  "between8and10": "between 8am and 10am",
  "moreThan10": "more than 10h",
  "timeToTraining": "Can I devote time to training?",
  "abilityAndPatienceToBeTeachable": "I have the capacity and the patience to be teachable?",
  "speakFollowingLanguages": "I speak the following languages ​​correctly",
  "dutch": "Dutch",
  "german": "German",
  "other": "Other",
  "motivationsOrientedOn": "My motivations are oriented on",
  "possibilityOfDevelopingTeam": "The possibility of developing a team",
  "developmentStaff": "Personal development",
  "wellBeing": "Well-being",
  "money": "The money",
  "nutritionConsultantCertification": "The certification of nutrition consultant",
  "openingNutri-logicsCenter": "The possibility of opening an Nutri-logics center",
  "statusOfIndependent": "The status of independent entrepreneur",
  "doesNotMatchMe": "Doesn't match me",
  "noReviews": "No reviews",
  "whatResourcesCanIBring": "What resources can I bring to Nutri-logics?",
  "passions": "My passions",
  "yourLifeSentenceSayingSlogan": "Your life sentence, saying or slogan",
  "priorityChoiceForChildren": "What is your priority choice for your children?",
  "cleanerLand": "Leave them a cleaner land",
  "someCapital": "Leave them some capital",
  "conceptOfRespect": "The notion of respect",
  "valueOfMoney": "The value for money",
  "devotingBudgetOf1300": "Spending a budget of 1300 € for nutrition training is:",
  "excessive": "Excessive",
  "reasonable": "Reasonable",
  "opportunity": "An opportunity",
  "useless": "Useless",
  "difficult": "Difficult",
  "impossible": "Impossible",
  "describePersonality": "Describe your personality in a few sentences",
  "whatTemper": "What is my temperament?",
  "othersWouldSay": "Others would say of me, that I am someone",
  "magicWand": "If you had a magic wand to change or bring something to this world, what would it be?",
  "motivations": "My motivations are oriented on",
  "consultantId": "Consultant ID",
  "interestedMe": "Interested",
  "preferateStatus": "My preferred status",
  "independent": "Independent",
  "official": "Official",
  "annuitant": "Annuitant",
  "childrenTransmission": "What I want to send to my children (Only one answer possible)",
  "leaveThemACleanerLand": "Leave them a cleaner land",
  "notionOfCapitalism": "The notion of capitalism (advantages and disadvantages)",
  "notionOfRespect": "The notion of respect",
  "selfAwareness": "Self-knowledge",
  "selfEsteem": "Self-esteem",
  "selfAffirmation": "Self-affirmation",
  "senseOfEffortAndEesilience": "The sense of effort and resilience",
  "indulgenceTowardsOneselfAndOthers": "Indulgence towards oneself and others",
  "seeLifeOnTheBrightSide": "See life on the bright side and have fun",
  "calm": "Calm",
  "dynamic": "Dynamic",
  "extrovert": "Extrovert",
  "introverted": "Introverted",
  "others": "Others",
  "changeTheWorld": "If I had to change or bring something to this world, it would be",
  "negativeMindAboutActivity": "What could scare me or displease me in this kind of activity",
  "switchIfNoConsultant": "Switch this button if you have not met an Nutri-logics consultant",
  "no": "No",
  "yes": "Yes",
  "profilingSubmit": "Send profiling",
  "profilingTestSent": "The profiling test has been sent",
  "searchConsultant": "Search a consultant",
  "searchNutritionConsultant": "Search a nutrition consultant",
  "searchByNameOrCity": "Search by name, city ...",
  "searchByNameCityOrPostalCode": "Name, city or postal code",
  "searchByIdentifierCityOrPostalCode": "Identifier, city or postal code",
  "SearchYourConsultantByIdentifierCityOrPostalCode": "Search your consultant by identifier, city or postal code",
  "noNutritionConsultantFound": "No consultant found",
  "findNutritionConsultantNearYou": "Find a consultant near you",

  //THE MAG
  "theMAG": "The mag",
  "magMetaDescription": "",
  "linksAboveAreProLinks": "PRO links: Autologin to your shop",
  "linksAreNotEnabled": "Pro links are not enabled",
  "shareLink": "Share link",
  "customizeYourLink": "You can customize your link by clicking here",
  "proLinksDisabled": "You have not activated your pro sharing link. You can configure it by clicking on the button below. The pro link allows you to immediately connect people who will follow it to your store.",
  "configureYourProLink": "Configure your pro link",
  "beContactedButtonDescription": "If you would like to be contacted by one of our experts, please click here :",
  "beContactedByExpertButton": "Be contacted by one of our experts",
  "beContactedButton": "Be contacted",

  //AUTHENTICATION
  "welcome": "Welcome {{name}}",
  "welcomeToNutri-logics": "Welcome to Nutri-logics",
  "createPassword": "Create password",
  "cookieConsentMessage": "This website uses cookies to enhance the user experience.",
  "iUnderstand": "I understand",
  "signInMetaDescription": "SignInMetaDescription",
  "signOutAllDevices": "Sign out from all devices",
  "signOut": "Sign out",
  "proCodeTooltip": "Enter your therapists or doctor code, if you don't know it, please enter NUTRILOGICS",
  "proCodeFound": "Enter your therapists or doctor code, if you don't know it, please enter NUTRILOGICS",
  "forgotPasswordMetaDescription": "forgotPasswordMetaDescription",
  "youAreNoLongerConnected": "You are no longer connected",
  "signIn": "Sign in",
  "signUp": "Sign up",
  "submitForgotPassword": "Send recovery email",
  "rememberMe": "Remember me",
  "phone": "Phone",
  "password": "Password",
  "confirmPassword": "Confirm your password",
  "forgotPassword": "Forgot password",
  "email": "Email",
  "streetNumber": "Street number",
  "streetName": "Street name",
  "additionalInfo": "Additional information (optional)",
  "zip": "Code postal",
  "city": "Ville",
  "country": "Country",
  "countryFieldInfo": "2 letters, ex: US",
  "useCustomFields": "Can't find your address? Use the custom form",
  "useAutocompleteField": "Use autocomplete field",
  "role": "Role",
  "askForEmailPassword": "Request a password change email",
  "shippingCountry": "Shipping country",
  "newInTheWebsite": "New to the website",
  "shopEnterButton": "Enter in the shop",
  "iKnowAConsultant": "I already know an Nutri-logics consultant",
  "iSearchAConsultant": "I don't know an Nutri-logics consultant and I search one on the map",
  "iDontWantToHaveAConsultant": "I know about food supplements and i continue without consultant",
  "findANutritionConsultant": "Find a nutrition consultant",
  "findYourNutritionConsultant": "Find your nutrition consultant",
  "findYourNutritionConsultantOrNutri-logicsPartner": "Find your nutrition consultant or Nutri-logics partner",
  "iHaveACode": "I have an identifier",
  "iDoNotHaveCode": "I do not have an identifier",
  "enterNutritionConsultantCode": "Enter the identifier of the nutrition consultant",
  "codeNotLinkedError": "This identifier does not exists",
  "youKnowNutri-logicsConsultant": "You know a nutrition consultant ?",
  "youDontKnowNutri-logicsConsultant": "You don't know of an consultant ?",
  "findConsultantNearYou": "Find a consultant near you",
  "searchOnTheMap": "Search on the map",
  "continueToTheStore": "Continue to the store",
  "continueToTheStoreWithoutConsultant": "Continue to the store without consultant",
  "comebackToPreviousChoices": "Comeback to previous choices",
  "alreadyClient": "Already a client",
  "back": "Back",
  "backToTheSiteButton": "Back to the site",
  "updatePassword": "Modify your password",
  "passwordUpdated": "The password has been updated",
  "errorMessagePasswordUpdateLinkDisabled": "The change password link has been disabled",
  "updatePasswordEmailSent": "A password change email has been sent to you at {{email}}",
  "sameAsBillingAddress": "Same delivery address as the billing address",
  "createMyAccount": "Create my account",
  "requiredFieldInAddressSnackbar": "You must enter the {{field}}",
  "streetNumberField": "street number",
  "streetNameField": "street",
  "zipField": "postal code",
  "cityField": "city",
  "countryField": "country",
  "registrationInformation": "Registration",
  "howDidYouKnowNutri-logics": "How did you hear about Nutri-logics?",
  "doctor": "Doctor",
  "therapist": "Therapist",
  "socialNetworks": "Social networks",
  "googleSearch": "Google search",
  "nutritionConsultantNameRegistration": "Nutrition consultant name",
  "doctorNameRegistration": "Doctor name",
  "socialNetworkNameRegistration": "Social network name",
  "otherNameRegistration": "Please specify",
  "youAreAlreadyConnectedWithSignOutConfirmation": "You are already logged in with {{name}}'s account, continue logging in with the new account?",
  "helpBoxTitle": "Besoin d'aide ?",
  "helpBoxText": "N'hésitez pas à nous contacter pour une assistance :",

  //ACCOUNT
  "accountMetaDescription": "accountMetaDescription",
  "myAccount": "My account",
  "birthDate": "Birth date",
  "account": "Account",
  "addresses": "Addresses",
  "myShippingAddresses": "My shipping addresses",
  "orders": "Orders",
  "myOrders": "My orders",
  "healthCheck": "Health check",
  "healthCheckMin": "Check up",
  "settings": "Settings",
  "mailingAndDataLanguage": "Communication language (mailing, etc ...)",
  "french": "French",
  "english": "English",
  "billingAddress": "Billing address",
  "company": "Company (optional)",
  "taxNumber": "Tax number (optional)",
  "vat": "VAT",
  "updateMyAccount": "Update my account",
  "newAddress": "New address",
  "addNewShippingAddress": "Add new shipping address",
  "removeShippingAddress": "Remove shipping address",
  "address": "Address",
  "defaultShippingAddress": "Default shipping address",
  "defaultBillingAddress": "Default billing address",
  "selectDefaultShippingAddressButton": "Use as default shipping address",
  "selectDefaultBillingAddressButton": "Use as default billing address",
  "addShippingAddressButton": "Add shipping address",
  "updateShippingAddressButton": "Update shipping address",
  "addressSelectedAsDefault": "Address selected by default",
  "addressRemoved": "Address removed",
  "addressAdded": "Address added",
  "addressUpdated": "Address updated",
  "cancelButton": "Cancel",
  "orderRefunded": "Order refunded",
  "orderCanceled": "Order canceled",
  "refunded": "Refunded",
  "delayedSending": "Delayed sending",
  "gotBackToSender": "Got back to sender",
  "orderAwaitingPreparation": "Order awaiting preparation",
  "orderPreparedAwaitingDelivery": "Order prepared awaiting delivery",
  "orderInTransit": "Order in transit",
  "orderInPickupPoint": "Order in pickup point",
  "orderBackToSender": "Order back to sender",
  "orderDelivered": "Order delivered",
  "orderActions": "Order actions",
  "orderCompleted": "Order completed",
  "recommendationOrder": "Your nutrition consultant's recommendation",
  "orderHistory": "Order history",
  "recommendationOrderFromNutritionConsultant": "Recommendation order from nutrition consultant",
  "freeOrderFromNutri-logics": "Free order from Nutri-logics",
  "deferredOrderDueToOutOfStock": "Deferred order due to out of stocks on previous order",
  "orderCreated": "Order created",
  "invoiceCreated": "Invoice created",
  "thereIsNotShippingForThisOrder": "There is no shipping for this order",
  "orderStatus": "Order status",
  "orderAgain": "Order again",
  "orderTracking": "Order tracking",
  "deferredShipping": "Deferred shipping",
  "downloadInvoice": "Download the invoice",
  "mailingCampaignFromNL": "Mailing campaign from Nutri-logics",
  "mailingCampaignFromOurAgreedSeller": "Mailing campaign from our agreed seller",
  "emailNotifications": "Email notifications",
  "youCanChangeThisAtEveryMoment": "You can change your choice at any time from this page",
  "security": "Security",
  "orderThisRecommendation": "Order this recommendation",
  "shippingAddresses": "Shipping addresses",
  "updateShippingAddress": "Update shipping address",
  "removeAddress": "Remove address",
  "updateInformation": "Update information",
  "emailAddedToMailingList": "Your email has been added to our mailing list",
  "emailRemovedFromMailingList": "Your email has been removed from our mailing list",
  "emailUpdateRequested": "To update your email, please click on the validation link sent to the address: {{email}}",
  "emailUpdated": "Email correctly added to your account",
  "invalidUpdateEmailLinkDescription": "The link you followed is currently invalid or inactive. To validate this email, you must renew your request by updating your account.",
  "cannotAccessToYourAccount": "If you can't access to your account and your actual email service, please contact us.",

  //ANAMNESIS
  "anamnesis": "Anamnesis",
  "needPreviousPage": "You need to complete previous page to continue anamnesis.",
  "anamnesisInfo": "You can complete this anamnesis in several times. <1/> Each validated page is saved.",
  "anamnesisCompleted": "Congratulations, the anamnesis is completed!",
  "sendTheAnamnesisDescription": "To send your anamnesis, simply click on the button below. <1/> Your consultant will contact you shortly.",
  "sendTheAnamnesisAcceptationDescription": "I accept that the information entered is used for the purposes of support and advice for my health.",
  "sendAdditionalDocuments": "Upload documents",
  "uploadAnamnesisRelativesDocuments": "Optional: You can add additional documents (blood test, etc ...)",
  "dropzoneText": "Drag and drop files here or click",
  "preview": "Preview",
  "sendDocuments": "Send documents",
  "documentsSent": "Documents sent",
  "deleteDocument": "Delete document: {{name}}",
  "documentsUploaded": "Documents uploaded",
  "createdAt": "Created at {{date}}",
  "updatedAt": "Updated at {{date}}",
  "sendTheAnamnesis": "Send the anamnesis",
  "watchTheAnamnesis": "Watch the anamnesis",
  "anamnesisSent": "The anamnesis has been sent",
  "anamnesisSentDescription": "The anamnesis has been sent on {{date}}",
  "anamnesisContactDescription": "If you have any question, you can contact your nutrition consultant by following this link :",
  "gender": "Gender",
  "male": "Male",
  "female": "Female",
  "civilStatus": "Civil status",
  "whatDoYouWantByCompletingThisAnamnesis": "What do you want by completing this anamnesis?",
  "haveBreakfastWeek": "Do you eat breakfast during the week (except weekend)?",
  "haveBreakfastWeekend": "Do you eat breakfast during the weekend?",
  "whatBreakfast": "What do you eat for breakfast during the week (except weekend)?",
  "whatBreakfastWeekend": "What do you eat for breakfast during the weekend?",
  "haveLunchWeek": "Do you eat lunch during the week (except weekend)?",
  "haveLunchWeekend": "Do you eat lunch during the weekend?",
  "whatLunchWeek": "What do you eat for lunch during the week (except weekend)?",
  "whatLunchWeekend": "What do you eat for lunch during the weekend?",
  "haveDinnerWeek": "Do you eat dinner during the week (except weekend)?",
  "haveDinnerWeekend": "Do you eat dinner during the weekend?",
  "whatDinnerWeek": "What do you eat for dinner during the week (except weekend)?",
  "whatDinnerWeekend": "What do you eat for dinner during the weekend?",
  "otherTimeYouEat": "Are there other times of the day when you eat?",
  "food": "Food",
  "quantity": "Quantity",
  "whatTime": "At what time?",
  "whatPosition": "Standing or seated?",
  "standing": "Standing",
  "seated": "Seated",
  "frontOfTV": "Front of the TV?",
  "sameEveryday": "Is it the same everyday?",
  "otherFood": "What other types of foods do you eat?",
  "frequency": "Frequency",
  "time": "Time",
  "onceAWeek": "Once a week",
  "severalTimesAWeek": "Several times a week",
  "everyDays": "Every days",
  "haveASnack": "Do you have a snack?",
  "snackWhatTime": "At what time?",
  "validateAndContinue": "Validate and continue",
  "previousStep": "Go back",
  "addRow": "Add a row",
  "whatAreYouDrinking": "What are you drinking? What quantities and frequencies?",
  "howMany": "How many?",

  "water": "Water",
  "wine": "Wine",
  "beer": "Beer",
  "cider": "Cider",
  "aperitifs": "Aperitifs",
  "juice": "Juice",
  "lightDrink": "Light drink",
  "sweetDrink": "Sweet drink (fruit juice, syrup, soda, etc.)",
  "infusionOrTea": "Infusion, tea",
  "coffee": "Coffee",
  "sugarPerCup": "How many lumps of sugar do you use per cup?",
  "aspartameOrSweetenerPerCup": "How many lumps of sugar (aspartame, sweetener) do you use per cup?",
  "milk": "Milk",
  "milkType": "Milk type",
  "skimmed": "Skimmed",
  "semiSkimmed": "Semi-skimmed",
  "wholeMilk": "Whole milk",
  "vegetable": "Vegetable",

  "iDontDrinkIt": "I don't drink it",
  "lessThanHalfALiterPerDay": "Less than half a liter per day",
  "betweenHalfALiterAndALiterPerDay": "Between half a liter and a liter per day",
  "moreThanALiterPerDay": "More than a liter per day",
  "1GlassPerDay": "One glass per day",
  "lessThan1PerDay": "Less than one per day",
  "lessThan1GlassPerDay": "Less than one glass per day",
  "1To3GlassesPerDay": "1 to 3 glasses per day",
  "2To3GlassesPerDay": "2 to 3 glasses per day",
  "4To7GlassesPerDay": "4 to 7 glasses per day",
  "moreThan1GlassPerDay": "More than one glass per day",
  "moreThan3GlassesPerDay": "More than 3 glasses per day",
  "moreThan7GlassesPerDay": "More than 7 glasses per day",
  "1GlassPerWeek": "1 glass per week",
  "1PerDay": "1 per day",
  "moreThan1PerDay": "More than 1 per day",
  "lessThan1CupPerDay": "Less than one cup per day",
  "1CupPerDay": "One cup per day",
  "moreThan1CupPerDay": "More than one cup per day",
  "2To3CupsPerDay": "2 to 3 cups per day",
  "moreThan3CupsPerDay": "More than 3 cups",
  "noSugar": "No sugar",
  "noAspartameOrSweetener": "No aspartame or sweetener",
  "1PerCup": "1 per cup",
  "2PerCup": "2 per cup",
  "moreThan2PerCup": "More than 2 per cup",

  "whatAreYouEating": "What do you eat? What quantities and frequencies",
  "rawVegetables": "Raw vegetables",
  "cookedVegetables": "Cooked vegetables",
  "driedVegetables": "Dried vegetables (lentils, beans, white, chickpeas, etc.)",
  "rawFruits": "Raw fruits",
  "cookedFruit": "Cooked fruit",
  "meat": "Meat",
  "reconstitutedProducts": "Reconstituted products such as cordon bleu nuggets",
  "coldCuts": "Cold cuts",
  "fish": "Fish",
  "eggs": "Eggs",
  "starterPuffPastry": "Starter based on puff pastry or pies?",
  "cheeses": "Cheeses",
  "starchyFoods": "Starchy foods (rice, pasta, potatoes)",
  "friedFoods": "Fried foods (fries, crisps, donuts, etc.)",
  "portionOfMilk": "How many times a day, including breakfast, do you have a portion of milk (yogurt, cottage cheese) or a glass of milk?",
  "sweetDesserts": "Do you eat sweet desserts (pastries, ice creams, desserts)?",
  "pastries": "Do you eat pastries, croissant?",
  "sweetProducts": "How much do you consume sweet products (candy, chocolate, candy etc.)?",
  "bread": "How much bread do you consume / day? (One baguette = 200g)?",
  "breadSlices": "How many slices of bread do you eat / day? (One slice = +/- 35g)?",
  "breadType": "Type of bread",
  "white": "White",
  "grey": "Grey",
  "wholeWheatBread": "Whole wheat bread",
  "spelled": "Spelled",
  "otherBreadType": "Other bread type",
  "lowFatProducts": "Do you use low-fat products?",
  "everyMeal": "Every meal",
  "1TimePerDay": "1 time per day",
  "1TimePerWeek": "1 time per week",
  "1To3TimePerWeek": "1 to 3 times per week",
  "3To6TimePerWeek": "3 to 6 times per week",
  "1TimePerMonth": "1 time per month",
  "2To4TimesPerWeek": "2 to 4 times per week",
  "moreThan1TimePerWeek": "More than 1 time per week",
  "reducedInSugarOrFat": "Reduced in sugar or fat?",
  "reducedInSugar": "Reduced in sugar",
  "reducedInFat": "Reduced in fat",
  "moreThan1": "More than 1",

  "1To2TimesPerMonth": "1 to 2 times per month",
  "never": "Never",
  "always": "Always",
  "lessThan1TimePerWeek": "Less than 1 time per week",
  "everyday": "Everyday",
  "4TimesPerWeek": "4 times per week",
  "moreThan4TimesPerWeek": "More than 4 times per week",
  "2PortionsPerMeal": "2 portions per meal",
  "1PortionPerMeal": "1 portion per meal",
  "1PortionPerDay": "1 portion per day",
  "lessThan1PortionPerDay": "Less than 1 portion per day",
  "3TimePerDay": "3 time per day",
  "moreThan3TimePerDay": "More than 3 time per day",
  "2TimesPerWeek": "2 times per week",
  "moreThan2TimesPerWeek": "More than 2 times per week",
  "lessThan100g": "Less than 100g",
  "100gTo200g": "100g to 200g",
  "moreThan200g": "More than 200g",

  "cookingOrSeasoningMode": "The most regularly used cooking or seasoning mode",
  "cookingMeat": "Cooking meat",
  "blue": "blue",
  "rareMeat": "Rare meat",
  "wellCooked": "Well cooked",
  "seasoningMeat": "Seasoning meat",
  "seasoningVegetables": "Seasoning vegetables",
  "seasoningSalads": "Seasoning salads",
  "butter": "Butter",
  "lessThanAPhalanx": "Less than a phalanx",
  "equivalentOfAPhalanx": "Equivalent of a phalanx",
  "moreThanAPhalanx": "More than a phalanx",
  "doYouEatButterEveryDay": "Do you eat butter every day?",
  "cream": "Cream",
  "margarine": "Margarine",
  "oils": "Oils",
  "aTablespoon": "A tablespoon",
  "lessThanATablespoon": "Less than a tablespoon",
  "moreThanATablespoon": "More than a tablespoon",
  "doYouEatOilEveryDay": "Do you eat oil every day?",
  "1TablespoonOfOilPerPerson": "One tablespoon of oil per person",
  "moreThan1TablespoonOfOilPerPerson": "More than one tablespoon of oil per person",
  "amountOfButterConsumedPerDay": "Amount of butter consumed per day",
  "amountOfButterConsumedPerWeek": "Amount of butter consumed per week",
  "amountOfButterConsumedPerMonth": "Quantity of butter consumed per month",
  "amountOfOilConsumedPerDay": "Amount of oil consumed per day",
  "amountOfOilConsumedPerWeek": "Amount of oil consumed per week",
  "amountOfOilConsumedPerMonth": "Quantity of oil consumed per month",
  "foodsThatYouShouldNotEat": "Are there any foods that you cannot stand, or that you should not eat?",
  "intolerantOrAllergicFood": "Are you intolerant or allergic to any food?",
  "which": "Which",

  "lifestyle": "Lifestyle",
  "areYouWorking": "Are you working right now?",
  "notWorkingStatus": "Status",
  "retired": "Retired",
  "invalid": "Invalid",
  "noProfession": "No profession",
  "schedule": "schedule",
  "officeSchedule": "officeSchedule",
  "alternateDayNightSchedule": "alternateDayNightSchedule",
  "whatProfession": "What is your profession?",
  "youLive": "You live",
  "withFamily": "with family",
  "inCohabitation": "In cohabitation",
  "withRoommates": "With roommate(s)",
  "howManyPeople": "How many people?",
  "howManyAdults": "How many adults?",
  "howManyChildren": "How many children?",

  "religionInfluenceYourDiet": "Does your culture, religion influence your diet?",
  "whoMakesFoodPurchases": "Who makes the food purchases? (Where ?, When ?, With whom?)",
  "isIt": "Is it?",
  "aPleasure": "A pleasure",
  "aConstraint": "A constraint",
  "whoPreparesTheMealsAtHome": "Who prepares the meals at home?",
  "doYouLikeToCook": "Do you like to cook?",
  "typeOfPreparations": "What type of preparations?",
  "doYouTasteTheDishes": "Do you taste the dishes?",
  "timeToPrepareMeals": "How much time do you have to prepare meals?",
  "equipmentForCooking": "What equipment do you have for cooking, storing your food?",
  "fridge": "Fridge",
  "freezer": "Freezer",
  "oven": "Oven",
  "microwaveOven": "Microwave oven",
  "cooker": "Cooker",
  "howLongIsBreakfast": "How long is breakfast?",
  "howLongIsLunch": "How long is lunch?",
  "howLongIsDinner": "How long is dinner?",
  "howAreYouEatingMidday": "Are you eating? Alone or in company at midday",
  "howAreYouEatingEvening": "Are you eating? Alone or in company at evening",
  "inCompany": "In company",
  "doYouEatBy": "Do you eat by",
  "necessity": "Necessity",
  "hunger": "Hunger",
  "gluttony": "Gluttony",
  "howFastDoYouEat": "How fast do you eat",
  "slowly": "Slowly",
  "quickly": "Quickly",
  "difficultiesInObtainingSupplies": "Do you have difficulties to stock up, prepare meals, eat?",
  "followADiet": "Do you usually follow a diet?",
  "followedOtherDiets": "Have you ever followed other diets?",
  "when": "When?",
  "why": "Why?",
  "prescribedByWhom": "Prescribed by whom?",
  "doYouEverEatAtARestaurant": "Do you ever eat at a restaurant?",
  "doYouEverHaveMealsWithFamily": "Do you ever have meals with family or friends?",
  "areYouHungry": "Are you hungry?",
  "whenStartingTheMeal": "When starting the meal",
  "byFinishingTheMeal": "By finishing the meal",
  "betweenMeals": "Between meals",
  "doYouConsiderYourself": "Do you consider yourself a",
  "bigEater": "Big eater",
  "middleEater": "Middle eater",
  "littleEater": "Little eater",
  "whatFoodsDoYouPrefer": "What foods do you prefer?",
  "sweety": "Sweety",
  "salty": "Salty",
  "refillingYourself": "Do you have the habit of refilling yourself?",
  "often": "Often",
  "sometimes": "Sometimes",
  "rarely": "Rarely",
  "toEveryMeal": "To every meal",
  "whatTypesOfDishes": "More particularly on what types of dishes?",
  "doYou": "Do you?",
  "doYouEverSkipABreakfast": "Are you skipping breakfast?",
  "doYouEverSkipALunch": "Are you skipping lunch?",
  "doYouEverSkipADinner": "Are you skipping dinner?",
  "2To3TimesPerWeek": "2 to 3 times per week",
  "moreThan3TimesPerWeek": "more than 3 times per week",
  "breakfast": "Breakfast",
  "atNoon": "At noon",
  "theEvening": "The evening",
  "DoYouHaveAnySnacks": "Do you have any snacks?",
  "inTheMorning": "In the morning",
  "inTheAfternoon": "In the afternoon",
  "afterWork": "After work",
  "inTheEvening": "In the evening",
  "inTheNight": "In the night",

  "weightHistory": "Weight history",
  "weighAt18": "How much did you weigh at 18?",
  "weighAt20": "How much did you weigh at 20?",
  "weighAt25": "How much did you weigh at 25?",
  "weighAt30": "How much did you weigh at 30?",
  "weighAt40": "How much did you weigh at 40?",
  "weighAt50": "How much did you weigh at 50?",
  "weighAt60": "How much did you weigh at 60?",
  "haveYouEverBeenPregnant": "Have you ever been pregnant?",
  "weighAtPostPregnancy": "How much did you weigh at the post pregnancy?",
  "areYouMenopausal": "Are you menopausal?",
  "weighAtPremenopause": "How much did you weigh at the premenopause?",
  "weighAtMenopause": "How much did you weigh at the menopause?",

  "physicalActivity": "Physical activity",
  "atYourWorkOrYourMainOccupation": "At your place of work or during your main occupation",
  "doYouWalk": "Do you walk?",
  "howManyStepsPerDay": "How many steps per day",
  "lessThan7000": "Less than 7000",
  "moreThan7000": "More than 7000",
  "areYouSeated": "Are you seated?",
  "duringFreeTime": "During your free time",
  "doYouWalkInFreeTime": "Do you walk? (Do you garden? Do you actively DIY?)",
  "doYouPracticeSport": "Do you practice a sport?",
  "areYouAdjustingDiet": "Are you adjusting your diet for your sport?",
  "doYouWatchTV": "Do you watch television? (same on pc)",
  "howManyTime": "How many time",
  "howManyTimesPerWeek": "How many times per week",
  "howManyHours": "How many hours",
  "getAroundByWalk": "To get around, do you prefer walking, cycling, rollerblading (others)?",

  "tobacco": "Tobacco",
  "doYouSmokeCigarettes": "Do you smoke cigarettes?",
  "howManyCigarettesPerDay": "How many cigarettes per day?",
  "sinceHowManyYears": "Since how many years?",
  "wouldYouLikeToQuitSmoking": "Would you like to quit smoking?",
  "doYouSmokeElectronicCigarette": "Do you smoke electronic cigarette?",
  "howManyElectronicCigarettePerDay": "How many per day?",
  "nicotineIntensity": "Nicotine intensity (mg / ml)",
  "howManyMLPerDayPerDay": "How many milliliters per day?",
  "wouldYouLikeToQuitSmokingElectronicCigarette": "Would you like to quit smoking?",

  "duringTheMeal": "During the meal",
  "chewingFrequency": "Your chewing rhythm",
  "fast": "Fast",
  "medium": "Medium",
  "slow": "Slow",
  "wearingDenturesToEat": "Do you wear dentures ?",
  "isItWellSupported": "is it well supported?",

  "medicalHistory": "Medical history",
  "whatIsYourMedicalHistory": "What is your medical history?",
  "whatIsYourSurgicalHistory": "What is your surgical history?",
  "whatIsYourFatherMedicalHistory": "What is your father medical history",
  "whatIsYourMotherMedicalHistory": "What is your mother medical history",
  "diabetes": "Diabetes",
  "cholesterol": "Cholesterol",
  "cancer": "Cancer",
  "areYourTakingDrugTreatment": "If you are taking any drug treatment, please indicate them",
  "areYouTakingAnyDietarySupplements": "If you are taking any dietary supplements, please indicate them",

  "physicalState": "Physical state",
  "describeYourPhysicalState": "In general, how would you describe your state of Form?",
  "whichOfTheseWordsDescribesYouBest": "Which of these words describes you best?",

  "active": "Active",
  "sportsman": "Sportsman",
  "stressed": "Stressed",
  "depressed": "Depressed",
  "allergies": "Allergies?",
  "conjunctivitis": "Eyes (conjunctivitis, styes)",
  "mouthHerpes": "Mouth (herpes, impetigo, cold sores)",
  "mucousMembranesMouthUlcers": "Mucous membranes mouth ulcers",
  "heartProblems": "Heart problems",
  "tension": "Tension (Hypo / hyper)",
  "waterRetention": "Water retention",
  "location": "Location",
  "liverProblems": "Liver problems",
  "kidneyProblems": "Kidney problems",
  "pancreaProblems": "Pancrea problems",
  "problemsAboutSpleen": "Problems about Spleen?",
  "difficultyFallingAsleep": "Difficulty falling asleep?",
  "sleepApnea": "Sleep apnea?",
  "nightAwakening": "Night awakening?",
  "stressAnxiety": "Stress / Anxiety?",
  "seasonalDepression": "Depression, Seasonal depression?",
  "muscleAchesCrampsTension": "Muscle aches, cramps and / or tension?",
  "tendinitis": "Tendinitis",
  "artrosis": "Artrosis",
  "thyroidProblem": "Thyroid problem?",
  "menstrualCycle": "Menstrual cycle ?",
  "regular": "Regular",
  "irregular": "Irregular",
  "pain": "Pain",
  "majorDiscomfort": "Major discomfort",
  "whitishTongue": "Whitish tongue?",
  "badBreath": "Bad Breath",
  "esophagealProblem": "Esophageal problem",
  "stomach": "Stomach (swollen / pain)",
  "intestines": "Intestines-Colôn (pain L / R)?",
  "right": "Right",
  "left": "Left",
  "bothSides": "Both sides",
  "flatulence": "Flatulence",
  "noisy": "Noisy",
  "odorous": "Odorous",
  "noisyOdorous": "Noisy and Odorous",
  "faeces": "Faeces",
  "faecesFrequency": "Faeces (frequency)",
  "lessThanOncePerDay": "Less than once / day",
  "onceADay": "Once a day",
  "moreThanOnceADay": "More than once a day",
  "faecesConsistency": "Consistency",
  "soft": "Soft",
  "longCigar": "Long cigar",
  "rabbitPoop": "Rabbit poop",
  "inRosary": "In rosary",
  "smellsOfBotherMe": "The smells of ... bother me",
  "smellsOfPerfumes": "Perfumes",
  "smellsInShops": "In shops",
  "smellsOfCooking": "Cooking",
  "afterAHeartyMealAndADrunkenEvening": "After a hearty meal and a drunken evening:",
  "feelTiredAfterEvening": "You feel tired",
  "severalDays": "Several days",
  "smearedAfterEvening": "Smeared",
  "nauseousAfterEvening": "Nauseous",
  "energyDrops": "Energy Drops?",
  "tired": "Tired",
  "atWhatTime": "If so at what time?",
  "brittleNails": "Brittle nails?",
  "hairLoss": "Hair loss?",
  "skinProblems": "Skin problems?",
  "teethingCaries": "Teething? Caries, braces, toothache",
  "urineIsUsually": "Urine is usually",
  "light": "Light",
  "dark": "Dark",
  "migraines": "Migraines?",
  "foodAllergy": "Food allergy ?",
  "beingAnInfant": "Being an infant",
  "birthByWay": "Birth by Way",
  "cesarean": "Cesarean",
  "vaginalBirth": "Vaginal birth",
  "birthWeight": "Birth weight",
  "birthAntibiotic": "Antibiotic",
  "breastfed": "Breastfed",
  "babyBottles": "Baby bottles",
  "howManyMonths": "How many months?",
  "birthGeneralStateOfHealth": "General state of health?",
  "childhood": "Childhood",
  "slenderChild": "Slender child",
  "corpulentChild": "Corpulent child",
  "childhoodGeneralStateOfHealth": "General state of health?",
  "adolescence": "Adolescence",
  "slenderTeen": "Slender teen",
  "corpulentTeen": "Corpulent teen",
  "adolescenceGeneralStateOfHealth": "General state of health?",
  "excellent": "Excellent",
  "good": "Good",
  "weak": "Weak",
  "mediocre": "Mediocre",
  "poor": "Poor",

  "centralNervousSystem": "Central Nervous System",
  "involuntaryMovement": "Involuntary movement of the muscle (arms, abdominals, quadriceps)",
  "muscleCramps": "Muscle cramps (calves, toes, hands, etc.) worsening during pregnancy",
  "muscleTension": "Muscle tension, stiffness in the neck, shoulders and lower back",
  "arnoldNeuralgia": "Arnold’s neuralgia, temporal headache with the impression of having the head in a vice",
  "intercostalPain": "Intercostal pain (like tip of the chest, \"point to heart\")",
  "HypersensitivityToNoiseAndLight": "Hypersensitivity to noise and light",
  "respiratoryTightnessPalpitations": "Respiratory tightness, palpitations",
  "paresthesiaOfExtremities": "Paresthesia of the extremities (hands, feet tingling), cold and sweaty extremities",
  "hyperexcitabilityEmotivityAnxiety": "Hyperexcitability - Emotivity - Anxiety",
  "waterRetentionPremenstrualPeriod": "Water retention, especially during the premenstrual period and during pregnancy",
  "irritable": "Irritable",
  "dissatisfiedImpatient": "Dissatisfied, Impatient, Feeling of being misunderstood",
  "intoleranceOfConstraintsFrustration": "Intolerance of constraints / frustration",
  "cannotStandStress": "Cannot stand Stress",
  "moodSwings": "Mood Swings (charming individual who explodes for a yes or a no)",
  "aggressiveness": "Aggressiveness",
  "foodCravings": "Food cravings including craving for sweets, snacking between meals",
  "tendencyToAddiction": "Tendency to addiction: tobacco, alcohol, coffee, etc.",
  "seasonalDepressionLackOfLightAffectsMorale": "Seasonal depression (fall, late winter) / lack of light affects morale",
  "alwaysTired": "Always tired",
  "sleepingTroubles": "Sleeping troubles",
  "difficultyStartingABusiness, difficulty making decisions": "Difficulty starting a business, difficulty making decisions",
  "difficultyInContinuingAnAction": "Difficulty in continuing an action, idling",
  "decreasedInterestInWork": "Decreased interest in work, lack of motivation, difficulty in making plans",
  "difficultiesInConcentration": "Difficulties in concentration, memorization, learning",
  "withdrawal": "Withdrawal (no longer wanting to see friends ...), withdrawal into oneself, loss of pleasure in doing things",
  "feelingOfBeingDepressed": "Feeling of being depressed, moral pain",
  "feelingOfWorthlessness": "Feeling of worthlessness, lack of self-confidence, low libido",
  "impatienceInTheLegs": "Impatience in the legs",
  "absent": "Absent",
  "moderate": "Moderate",
  "embarrassing": "Embarrassing",
  "veryEmbarrassing": "Very embarrassing",

  //SIMPLIFIED ANAMNESIS
  "years": "years",
  "sentOn": "Sent on {{date}}",
  "anamnesisTransmittedOn": "Thank you for completing this anamnesis. We forwarded it to your nutrition consultant on {{date}}",
  "form": "Form",
  "results": "Results",
  "chartDetailed": "Detailed chart",
  "anamneses": "Anamneses",
  "noAnamnesis": "No history",
  "newAnamnesis": "New anamnesis",
  "requestGraphAnamnesis": "Request a history",
  "restartNewAnamnesis": "Start a new anamnesis",
  "weight": "Weight",
  "yourWeight": "Your weight (kg)",
  "height": "Height",
  "yourHeight": "Your height (cm)",
  "bmi": "BMI",
  "underweight": "Underweight",
  "normal": "Normal",
  "overweight": "Overweight",
  "obese": "Obese",
  "extremelyObese": "Extremely obese",
  "youNeedToAcceptMention": "You must accept the mention to continue",
  "uploading": "Uploading",
  "inProgress": "In progress",
  "deleteAnamnesis": "Delete the anamnesis",
  "continue": "Continue",
  "emptyIfNotConcerned": "Please leave blank if you are not concerned",
  "sa_anamnesisAcumptionMention": "I accept that the information entered in this anamnesis will be used for the purposes of support and advice for my health",
  "sa_youNeedToAcceptMention": "You must accept the mention before continuing",
  "sa_alimentaryConsommation": "Food consumption",
  "sa_digestiveSystem": "Digestive system",
  "sa_exposureToPollutants": "Exposure to pollutants",
  "sa_sleep": "Sleep",
  "sa_psychoEmotional": "Psycho-emotional",
  "sa_skinDisorders": "Skin disorders",
  "sa_osteoarticularDisorders": "Osteo-articular disorders",
  "sa_otherDisorders": "Other troubles",
  "sa_immunity": "Immunity",
  "sa_cardiovascularDisorders": "Cardiovascular disorders",
  "sa_hormonalDisturbances": "Hormonal disturbances",
  "sa_carbohydrateMetabolism": "Carbohydrate metabolism",
  "sa_mealDuringTheWeek": "Meal during the week",
  "sa_mealDuringTheWeekend": "Meal during the weekend",
  "sa_yes": "Yes",
  "sa_true": "Yes",
  "sa_no": "No",
  "sa_false": "No",
  "sa_x1": "Once",
  "sa_x2": "Twice",
  "sa_more": "More than twice",
  "sa_immune": "Immune",
  "sa_essentialFattyAcids": "Essential fatty acids",
  "sa_omega3": "Omega3",
  "sa_omega6": "Omega6",
  "sa_omega9": "Omega9",
  "sa_antioxidants": "Antioxidants",
  "sa_circulation": "Circulation",
  "sa_stomach": "Stomach",
  "sa_pancreas": "Pancreas",
  "sa_intestine": "Intestine",
  "sa_detoxification": "Detoxification",
  "sa_jointBonesMuscles": "Join / Bones / Muscles",
  "sa_neuromediators": "Neuromediators",
  "sa_cathecolamine": "Cathecolamine",
  "sa_indolamine": "Indolamine",
  "sa_acidBaseBalance": "Acid/Base Balance",
  "sa_hormonalBalance": "Hormonal balance",
  "sa_aesthetic": "Aesthetic",
  "sa_thinning": "Thinning",
  "sa_aging": "Aging",
  "sa_mgEnergy": "Energy MG",
  "sa_vitaminMinerals": "Vit./Miner",
  "sa_inflammation": "Inflammation",
  "sa_meatAndColdCuts": "Do you eat animal products (meat and / or cold cuts) more than 5 times a week?",
  "sa_industrialDishes": "Do you eat industrial foods (frozen, canned or prepared meals) more than once a day?",
  "sa_cowMilk": "In general, do you consume cow's milk and / or derivatives (yogurt, cheese, ice cream, dessert cream ...) twice a day or more?",
  "sa_oilyFish": "In general, do you eat oily fish (salmon, mackerel, anchovies, sardines ...) fresh or canned, twice a week?",
  "sa_oliveOil": "Do you consume virgin and organic olive oil every day?",
  "sa_otherOils": "Daily, do you use other virgin and organic oils (rapeseed, walnuts, flax, camelina ...)?",
  "sa_pastries": "Do you eat pastries, pastries, savory pies, puff pastry, [...], more than 3 times a week?",
  "sa_fastFoods": "Do you eat out (fast food, canteens, restaurants) more than 3 times a week?",
  "sa_sweets": "Do you eat more than 2 cookies, cakes, granola bars, confectionery, chocolate, sugary drinks, [...] per day?",
  "sa_fruitsAndVegetables": "Do you eat several seasonal raw fruits and vegetables every day?",
  "sa_seasonalVegetables": "Do you eat steamed or low-temperature cooked seasonal vegetables every day (excluding microwaves)?",
  "sa_organicProducts": "Do you preferably eat organic products?",
  "sa_largerMeal": "Do you eat more in the evening than at noon?",
  "sa_eatRegularly": "Do you eat peacefully (without TV), at regular times?",
  "sa_skipMainMeals": "Do you regularly skip any of the 3 meals (breakfast, lunch, dinner)?",
  "sa_lowCalorieDiet": "Do you follow a low calorie diet, with food restriction?",
  "sa_drinkWater": "Do you drink more than 1 liter of spring water per day?",
  "sa_intestinal Transit": "Do you have intestinal transit problems (diarrhea, constipation, bloating)?",
  "sa_bigMealAndHeadache": "Do you suffer from headaches after a heavy meal?",
  "sa_whiteTongue": "Is your tongue often white and / or heavy breath?",
  "sa_slowDigestion": "Do you have slow, difficult or bloating digestion?",
  "sa_gumsSensitive": "Do you regularly bleed from your gums, are they sensitive?",
  "sa_chilly": "Are you cautious?",
  "sa_sweetProducts": "Do you want to consume sweet products (several times a day)?",
  "sa_irritableOrTiredSkippingMeal": "Does skipping a meal make you more tired or irritable?",
  "sa_decreasedAttentionBeforeMeal": "Before meals, do you notice a drop in concentration or a change in mood?",
  "sa_dizziness": "Do you have regular dizziness, nausea or headaches?",
  "sa_significantFatMassInAbdominalArea": "Do you notice a localization of significant fat mass in the abdominal area?",
  "sa_intestinalPain": "Do you frequently complain of intestinal pain (colitis), bloating and gas?",
  "sa_drowsiness": "Do you suffer from drowsiness or discomfort after meals on a daily basis?",
  "sa_cravings": "Do you regularly experience \"cravings\" before meals?",
  "sa_drySkin": "Is your skin dry (including eczema, frostbite, cracks ...), rough or acne-prone?",
  "sa_entDisorders": "Do you regularly suffer from ENT disorders (colds, sinusitis, ear infections) or various allergies (respiratory or food)?",
  "sa_loseHair": "Do you notice significant hair loss or is it devitalized?",
  "sa_cigarettesAndAlcohol": "Do you smoke more than 5 cigarettes per day or do you drink more than one glass of hard alcohol or more than 2 glasses of wine each day?",
  "sa_skinDiscomfort": "Do you experience skin discomfort: tingling, tightness, itching, stretch marks?",
  "sa_nocturnalAwakening": "Do you wake up regularly between 1 and 3 a.m.?",
  "sa_lackOfRestfulSleep": "Do you experience a lack of restful sleep (difficulty falling asleep, restless sleep, nocturnal awakenings)?",
  "sa_stress": "Do you feel stressed by your daily life or overworked by personal or professional concerns?",
  "sa_nervous": "Are you currently particularly nervous, anxious or psychologically tired?",
  "sa_depressed": "Do you feel like you have less interest or less fun than before?",
  "sa_stimulants": "Do you need stimulants such as coffee, alcohol, tobacco?",
  "sa_memorizationDifficulties": "Do you suffer from memorization difficulties or concentration problems?",
  "sa_energyLoss": "Do you regularly experience loss of energy or nervous exhaustion?",
  "sa_cholesterol": "Do you have excess cholesterol, triglycerides, uric acid or stones? ",
  "sa_skinSpots": "Do you feel that you are aging faster than your age: wrinkles, lack of elasticity of the skin, dark spots ...?",
  "sa_whiteSpotsOnTheNails": "Do you have white spots on your nails or are they streaky, brittle, soft?",
  "sa_chronicMusclePain": "Do you have muscle pain regularly?",
  "sa_decalcification": "Do you suffer from decalcification (osteoporosis)?",
  "sa_backAche": "Do you regularly suffer from back pain?",
  "sa_decreasedJointMobility": "Are you experiencing reduced muscle mobility or pain due to osteoarthritis?",
  "sa_cramps": "Do you regularly suffer from cramps, aches or tendonitis?",
  "sa_stiffMusclesAndJoints": "Are your muscles and joints stiff in the morning when you wake up?",
  "sa_premenstrualDisorders": "Are you prone to premenstrual disorders (water retention, weight gain, headaches, breast pain, irritability ...)?",
  "sa_hormonalContraceptive": "Do you use a hormonal contraceptive (pill, IUD)?",
  "sa_irregularPeriods": "Is your period irregular?",
  "sa_menopause": "Do you have hot flashes, weight gain related to peri-menopause or menopause?",
  "sa_whiteDischarge": "Do you have white discharge, burning, itching of the vagina?",
  "sa_needToUrinate": "Are you urinating more often?",
  "sa_hyperactiveTendency": "Are you hyperactive, prone to stress?",
  "sa_adenoids": "Are you prone to adenoids (if you have not had an operation)?",
  "sa_periodOfIntenseGrowth": "Are you in a period of intense growth?",
  "sa_breathless": "Are you short of breath walking up the stairs? Are you being treated for high blood pressure or cardiovascular problems?",
  "sa_dentalFillings": "Do you currently have any fillings or metal crowns in your mouth?",
  "sa_pollutedUrbanEnvironment": "Do you live in a polluted urban environment or are you in daily contact with chemicals (eg: painter, hairdresser, bleacher ...)?",
  "sa_viewHasChanged": "Have you noticed eye fatigue in recent months or have you been diagnosed with eye pathologies: AMD, cataracts, etc.)?",
  "sa_prematurelyAging": "Do you feel like you're aging prematurely?",
  "sa_asthma": "Do you frequently suffer from asthma or skin disorders such as eczema, psoriasis, atopic dermatitis?",
  "sa_noPhysicalActivity": "Do you do little or no physical activity or brisk walking (less than 1.5 hours per week)?",
  "sa_antibiotic": "Have you had prolonged (more than a month) or repeated antibiotic treatment in the past 6 months?",
  "sa_analItching": "Do you regularly suffer from anal or genital itching?",
  "sa_cavities": "Do you regularly have cavities, gingivitis, dental loosening or canker sores?",
  "sa_urinaryTractInfections": "Do you experience burning urination or do you have recurrent urinary tract infections?",
  "sa_dullHair": "Do you have dull hair and / or complexion?",
  "sa_spasmophilia": "Do you have signs of spasmophilia or tetany?",
  "sa_medicalTreatment": "Are you taking long-term drug treatment (other than homeopathy or nutritherapy)?",
  "sa_excessiveExposureToTheSun": "How often do you expose yourself to the sun? Did you get sunburned a lot in your childhood?",
  "sa_breakfast": "Breakfast",
  "sa_breakfastWhatTime": "What time do you eat breakfast?",
  "sa_breakfastDescription": "What are the foods and drinks that make up breakfast? (be specific)",
  "sa_lunch": "Lunch",
  "sa_lunchWhatTime": "What time do you eat lunch?",
  "sa_lunchDescription": "What are the foods and drinks that make up breakfast? (be specific)",
  "sa_dinner": "Dinner",
  "sa_dinnerWhatTime": "What time do you eat dinner?",
  "sa_dinnerDescription": "What are the foods and drinks that make up dinner? (be specific)",
  "sa_snack": "Collation",
  "sa_snackDescription": "What are the foods and drinks that make up your snack (Specify the times)?",
  "sa_betweenMeals": "Between meals? (snacking)",
  "sa_betweenMealsDescription": "If you eat between meals, please specify times, foods and drinks",
  "sam_ifDiarrhea": "If diarrhea",
  "sam_ifConstipation": "If constipation",
  "sam_ifAcidifiedGround": "If acidified ground",
  "sam_elementsDealtWithInASecondStep": "The elements below will be treated in a second step (another anamnesis may be requested again)",
  "sam_ifRelatedToWeightLoss": "If related to a weight loss",
  "sam_addAcerolaIfJoinProblem": "If joint problem, back pain, osteoarthritis: add Acerola",
  "sam_ifYouSuspectAThyroidProblem": "If a thyroid problem is suspected",
  "sam_shouldNotBeLinkedWithAnticoagulants": "Caution: should not be linked with anticoagulants",
  "sam_ifCholesterol": "If cholesterol",
  "sam_noFishConsumption": "Because no fish consumption",
  "sam_okIfIronDeficiencyExPeriodOfMenstruation": "Ok if iron deficiency (ex: period of menstruation)",
  "sai_charcoal": "Cleanse the intestines",
  "sai_aloe": "Gastrointestinal health",
  "sai_lTyro": "Dopamine production",
  "sai_mind": "Anti-stress / Anti-depressive / Toners",
  "sai_noctivance": "Melatonin",
  "sai_packGestionDuSommeil": "Serotonin and Melatonin",
  "sai_packImmunite": "D3 / ZINC / NAC / ORIGOLIO",
  "sai_cardiomega": "Omega 3",
  "sac_changeFeed": "Change feed",
  "sai_phenol": "Antioxidants",
  "sai_alca": "Rebalance the acid/base balance",
  "sai_packGestionDuPoids": "Omega calanus / Verda T Vita",
  "sai_coq10": "Coenzyme Q10",
  "sai_d3": "Vitamin D3",
  "sai_mgTop": "Magnesium",
  "sac_bloodTest": "Request a blood test",
  "sac_reduceConsumptionOfAnimalProducts": "Reduce consumption of animal products",
  "sac_reduceMilkConsumption": "Réduire la consommation de lait",
  "sac_consumeMoreOilUnheated": "Consume more oil (unheated)",
  "agsReport": "Nutri-logics report",
  "displayAgsReport": "View Nutri-logics Report",

  //PRO
  "of": "of",
  "Jan": "Jan",
  "Feb": "Feb",
  "Mar": "Mar",
  "Apr": "Apr",
  "May": "May",
  "Jun": "Jun",
  "Jul": "Jul",
  "Aug": "Aug",
  "Sep": "Sep",
  "Oct": "Oct",
  "Nov": "Nov",
  "Dec": "Dec",
  "admin": "Admin",
  "logistic": "Logistic",
  "director": "Director",
  "director_plural": "Directors",
  "manager": "Manager",
  "manager_plural": "Managers",
  "coach": "Coach",
  "coach_plural": "Coaches",
  "consultant": "Consultant",
  "consultants": "Consultants",
  "consultant_plural": "Consultants",
  "delegate": "Delegate",
  "delegate_plural": "Delegates",
  "customer": "Customer",
  "customer_plural": "Customers",
  "prospect": "Prospect",
  "prospect_plural": "Prospects",
  "dashboard": "Dashboard",
  "commissions": "Commissions",
  "wallet": "Wallet",
  "sales": "Sales",
  "network": "Network",
  "clients": "Clients",
  "thisYear": "This year",
  "thisMonth": "This month",
  "byYear": "By year",
  "moreSalesButton": "Show more sales",
  "directSales": "Direct sales",
  "directSalesMin": "DS",
  "directRecurringSales": "Direct sales (recurrences)",
  "networkSales": "Network sales",
  "networkSalesMin": "NS",
  "networkRecurringSales": "Network sales (recurrences)",
  "turnoverByCareer": "Turnover by career",
  "averageTurnoverByActivePro": "Average turnover per active pros",
  "directRecruitments": "Direct recruitments",
  "directRecruitmentsMin": "DR",
  "networkRecruitments": "Network recruitments",
  "networkRecruitmentsMin": "NR",
  "directBestSellers": "Direct best sellers",
  "directBestRecruiters": "Direct best recruiters",
  "networkBestSellers": "Network best sellers",
  "networkBestRecruiters": "Network best recruiters",
  "directProductsSold": "Direct products sold",
  "directPacksSold": "Direct packs sold",
  "networkProductsSold": "Network products sold",
  "networkPacksSold": "Network packs sold",
  "selectOtherMonth" : "Click on the date to see other sales history",
  "downloadCreditNote" : "Download the credit note",
  "DCE": "DCE",
  "bankAndLogisticCharges": "Bank and logistic charges",
  "DCEExplanation": "Amount direct commission excluded",
  "newSaleMessage": "Sale from your {{role}} {{name}}'s shop : {{amount}}",
  "newDirectSaleMessage": "Sale from your shop : {{amount}}",
  "refundedSaleMessage": "Refunded sale : {{amount}}",
  "newProPurchaseMessage": "Pro purchase : {{amount}}",
  "newProPurchaseMessageFromNetwork": "Pro purchase from your {{role}} {{name}} : {{amount}}",
  "saleDetails": "Sale details",
  "notCommissioned": "Not commissioned",
  "bank": "Bank",
  "makeTransfer": "Make a transfer",
  "makeTransferDescription": "The total amount of your commissions will be transferred to your bank account.",
  "notEnoughTransferError": "You need to have at least 100 {{currency}} on your account to make a transfer.",
  "maxAmount": "max: {{amount}}",
  "transferRegistered": "Your transfer request has been registered. It may take a few days to display on your account.",
  "transferAmount": "Transfer {{amount}} on your bank account ?",
  "transfers": "Transfers",
  "downloadCreditNotesDescription": "Select the credit note to download.",
  "number": "Number",
  "commissionRefund": "Commission refund",
  "purchaseRefund": "Purchase refund",
  "commission": "Commission",
  "purchase": "Purchase",
  "transfer": "Transfer",
  "networkAdmin": "Network admin",
  "statistics": "Statistics",
  "customersAdmin": "Customers admin",
  "structure": "Structure",
  "seller": "Sales manager",
  "requests": "Requests",
  "amount": "Amount",
  "currency": "Currency",
  "evolution": "Evolution",
  "selectedRole": "Selected role",
  "networkEvolution": "Network evolution",
  "networkStructure": "Network structure",
  "downloadImage": "Télécharger en image",
  "downloadPDF": "Télécharger en PDF",
  "customers": "Customers",
  "addCustomer": "Add new customer",
  "createCustomer": "Create customer",
  "updateCustomer": "Update customer",
  "updateCustomerShippingAddress": "Update customer shipping address",
  "deleteTheProspect": "Delete the prospect",
  "prospectDeleted": "Prospect deleted",
  "importExistingCustomer": "This customer already exists on the site, do you want to import his file? Please note that existing data may not be up to date.",
  "customerImportation": "Customer account importation",
  "importation": "Importation",
  "name": "Name",
  "date": "Date",
  "removedAt": "Removed at",
  "generalInformation": "General information",
  "applicationDescription": "Application new delegate",
  "buttonWillAppearAfterOneDay": "The accept or decline buttons will appear after one day if the consultant in charge does not have any activity",
  "buttonOnlyForCoach": "The acceptance of the request is reserved for the position of coach or higher.",
  "buttonCoachAlertedByTheRequest": "A little more patience, your coach has been alerted and will study this request.",
  "accept": "Accept",
  "decline": "Decline",
  "confirmProfilingRequest": "If the user seems to be eligible to become an Nutri-logics delegate, click Ok to accept the request. A welcome email will be sent to the new delegate.",
  "declineProfilingRequest": "If the user does not appear to be eligible to become an Nutri-logics delegate, click OK to decline the request.",
  "requestApproved": "The request has been approved",
  "requestDeclined": "The request has been declined, you have 7 days to change the decision. After that date, the request will be removed.",
  "update": "Update",
  "createOrder": "Create an order",
  "pricesExclTaxes": "The prices displayed are taxes excluded",
  "exclTaxes": "Excl. taxes",
  "addProductToOrder": "Add a product to the order",
  "noOrder": "No order",
  "sendOrderToClient": "Send the order to client",
  "searchProduct": "Search a product",
  "orderSent": "Order sent",
  "requestSent": "Request sent",
  "requestRemoved": "Request removed",
  "searchCustomer": "Search a customer",
  "noCustomerFound": "No customer found",
  "customerRedirectionRequestError": "We cant find the request, please contact the delegate in charge.",
  "entrustCustomerToNutritionConsultant": "Entrust to a nutrition consultant",
  "entrustCustomerToNutritionConsultantDescription1": "You are about to entrust your client to a nutrition advisor. This requires your client to have professional support adapted to his needs.",
  "entrustCustomerToNutritionConsultantDescription2": "This help is free as long as you are not certified. <1/> After certification, you will have the choice between recovering your customer or paying for the service up to 5% of your commissions.",
  "entrustCustomerTo": "Entrust the client to {{name}}.",
  "waitingRequest": "Waiting request",
  "validated": "Validated",
  "customerRedirectionDefaultMessage": "My client wants a consultation, can you make an appointment?",
  "confirmCustomerRequest": "Accept customer request",
  "declineCustomerRequest": "Decline customer request",
  "customerRedirectionRequestDeclined": "The request was declined.",
  "customerRedirectionRequestDeclinedDescription": "You declined the request from {{consultant}} to take in charge the customer {{customer}}.",
  "customerRedirectionRequestAccepted": "The request was accepted.",
  "customerRedirectionRequestAcceptedDescription": "You accepted the request from {{consultant}} to take in charge the customer {{customer}}. We ask you to contact him within 72 hours maximum to schedule a consultation appointment. In addition to the price of your consultation, you will receive 5% commissions on the entirety of its future orders until the consultant obtains his certificate.",
  "customerAddedToYourClientList": "The customer has been added to the list of your existing customers.",
  "sendBackTheCustomerToTheDelegate": "Send back the customer to the delegate",
  "sendBackTheCustomerToTheDelegateDescription": "You are about to send back the customer to the delegate. This operation is irreversible.",
  "takeTheCustomerBack": "Take the customer back",
  "youNeedToHaveCertificationToAccessAnamnesis": "You need to be a nutrition consultant to access to the anamnesis section.",
  "ifYouAreAlreadyANutritionConsultant": "If you are already a nutrition consultant",
  "ifYouAreNotAlreadyANutritionConsultant": "If you are not already a nutrition consultant",
  "redirectYourCustomerToANutritionConsultant": "Redirect your customer to a nutrition consultant from your choice by clicking here. The customer will stay into your network (your commissions remain unchanged) but the nutrition consultant will take him into charge.",
  "redirectToANutritionConsultant": "Redirect to a nutrition consultant",
  "getCustomerBackDescription": "Get the customer back ?",
  "customerIsNotLinkedAnymore": "The customer is not linked anymore",
  "thankYouForAnswering": "Thank you for responding to this request.",
  "goToClientAccount": "Go to the client account",
  "requestAnamnesis": "Request anamnesis",
  "needToHaveCertificationAnamnesis": "Certification is mandatory to perform the analysis.",
  "writeNote": "Write a private note",
  "notesArePrivate": "Notes are confidential",
  "showOlderNotes": "Show older notes",
  "noteAdded": "Note added",
  "noteRemoved": "Note removed",
  "sendEmail": "Send email",
  "sendEmailNutritionConsultant": "Send email to nutrition consultant",
  "sendEmailDelegate": "Send email to delegate",
  "call": "Call",
  "callNutritionConsultant": "Call nutrition consultant",
  "callDelegate": "Call delegate",
  "notInChargeShowButtonsAnyway": "You are not in charge to the client, show actions anyway?",
  "watchProfilingTitle": "Watch the profiling",
  "watchAnamnesis": "Watch the anamnesis",
  "notes": "Notes",
  "writeYourNoteHere": "Write your note here",
  "delete": "Delete",
  "needSupportForAnamnesis": "Need support to read this anamnesis?",
  "askAgsAnamnesis": "Ask Nutri-logics for help reading this anamnesis. You will find support messages via the \"notes\" tab.",
  "anamnesisAgsSupportRequested": "Support requested: Nutri-logics will write to you very soon via the \"notes\" tab.",
  "supportRequested": "Support requested!",
  "resendIdentifiers": "Resend identifiers by email",
  "payTheOrder": "Pay the order",
  "sentPaymentEmail": "Sent again the payment email",
  "customerPaymentEmailSent": "Order payment email sent",
  "emailSent": "Email sent",
  "orderRecommendationDeleteDescription": "Delete order recommendation",
  "deleteRecommendation": "Delete order recommendation",
  "deletedRecommendationDescription": "The recommendation was successfully deleted.",
  "belongsToNetwork": "Belongs to the network",
  "shippingFees": "Shipping fees",
  "allowFreeShipping": "Shipping fees are offered from 60 € and paid by the network",
  "disallowFreeShipping": "Shipping costs are not offered and will be paid by the customer",
  "allowFreeShippingDescription": "If you decide to offer delivery, the price will be divided among the entire network concerned.",
  "clientsCanSearchYouBy": "Clients can search for you via:",
  "yourNameAndFirstName": "Your first name and last name.",
  "yourCompanyName": "Your company name.",
  "companyNeeded": "You do not have a registered company on the website.",
  "yourCityOrPostalCode": "Your city or postal code.",
  "cannotBeChanged": "This option cannot be changed",
  "youDoNotHaveYourIdentifier": "You do not yet have an identifier, please configure it by clicking here",
  "searchSettingsDescription": "These settings allow you to configure how customers will find you on the site.<1/>If you decide not to allow searching through your first and last name or through your company, these will not appear in the store or on the contact page.",
  "directLink": "Identifier",
  "yourDirectLink": "Your link",
  "identifier": "Identifier",
  "yourIdentifier": "Your identifier",
  "todoList": "TODO List",
  "createIdentifier": "Create an identifier",
  "youDoNotHaveProIdentifierYet": "You do not yet have a pro identifier. You can create one by clicking here.<1/>This identifier is used by customers to access your store and can also give you the option to share with pro links on social media or by email.",
  "identifierDescription": "Create and personalize your identifier. This is <1>a single word (may contain numbers) that you will give to your customers to access your store.</1><2/> This identifier will also give you access to your professional links that you can share on social networks or by email.",
  "directLinkDescription": "Customize your direct link to the shop for sharing easily. Anyone with this link can go directly to your store without having to find your name.",
  "directLinkActive": "Link enabled. Your store is accessible from your customized link",
  "directLinkInactive": "Link disabled. Your store is not currently accessible via a link.",
  "copyInClipboard": "Copy in clipboard",
  "linkCopiedInClipboard": "Link copied in clipboard",
  "specialCharactersDirectLinkError": "Only alphanumeric characters without accents and the dashes are allowed",
  "yourActiveLinks": "Your active links",
  "general": "General",
  "displayWallet": "Display wallet",
  "displayWalletOnTopBar": "Display wallet amount on the website top bar",
  "displayWalletOnTopBarDescription": "The wallet amount will always be displayed on \"Commissions > Bank\"",
  "meetings": "Meetings",
  "documentsAndProtocols": "Documents & Protocols",
  "protocols": "Protocols",
  "moreProtocolsButton": "Show more protocols",
  "searchProtocol": "Search a protocol",
  "searchProtocolsDescription": "Search by keywords or write a sentence to improve results",
  "cantFindProtocol": "Can't find the protocol associated with your request ?",
  "askProtocolToAgs": "Ask a protocol to Nutri-logics",
  "businessCard": "Business card",
  "beSpecific": "Please be specific so that we can answer you precisely",
  "faqSpecificToNutrition": "This FAQ is specific to nutrition, for all other question, please write to info@ags-lab.com",
  "request": "Request",
  "protocolSentToNutri-logics": "The request has been sent to Nutri-logics. You will be notified by email when a response will be added.",
  "office": "Office",
  "displayYourOffice": "Display your office",
  "officeUpdateText": "This information is public and will be displayed on the site and on search engines.",
  "specialities": "Specialities",
  "description": "Description",
  "openingTime": "Opening time",
  "services": "Services",
  "serviceName": "Service name",
  "priceByCurrency": "Price in {{currency}}",
  "addMoreService": "Add more service",
  "saveOfficeInfo": "Save office information",
  "coverPicture": "Cover picture",
  "profilePicture": "Profile picture",
  "updateCoverPictureButton": "Update cover picture",
  "updateProfilePictureButton": "update profile picture",
  "updateInfoButton": "Update info",
  "dropImageInfo": "Drag an image into the frame or click here",
  "cancel": "Cancel",
  "validate": "Validate",
  "fileRemoved": "{{fileName}} removed",
  "fileAdded": "{{fileName}} added",
  "fileCannotBeenImported": "{{fileName}} cannot been imported",
  "officeNotDisplayedDescription": "The office is not displayed on the map and search engines.",
  "partnerOfficeNotDisplayedDescription": "The office is not displayed online.",
  "officeDisplayedDescription": "The office is displayed on the map and search engines.",
  "partnerOfficeDisplayedDescription": "The office is displayed online and visible to search engines.",
  "officeDisplayedQualificationDescription": "You must have a nutrition consultant certification to be displayed on the map.",
  "changeVisibility": "Change the visibility",
  "chooseAsANutritionConsultant": "Choose as a nutrition consultant",
  "website": "Website",
  "saved": "Saved",
  "proAccount": "Pro account",
  "generalInfo": "General information",
  "bankAccountHolder": "Bank account holder",
  "iban": "IBAN",
  "bic": "BIC",
  "membership": "Membership / Annual fee",
  "payMembershipWithWalletButton": "Renew membership with the Nutri-logics credit",
  "payMembershipWithAnotherPaymentMethodButton": "Renew membership with another payment method",
  "payMembershipByWalletDescription": "Renew membership ? {{amount}} will be deducted from your commissions.",
  "membershipDaysRemaining": "You have {{days}} days left before your services stop.",
  "servicesInactive": "Your services are inactive.",
  "membershipAccessProvidesDescription": "Membership provides access to the following services :",
  "accessibilityOfYourStore": "The accessibility of your store.",
  "accessibilityOfYourStoreDescription": "Your store is accessible via the consultants search module and via your pro link.",
  "professionalPurchasing": "Professional purchasing.",
  "professionalPrices": "Buy in your store with your professional prices.",
  "accessTrainingDocumentsAndProtocols": "Access to trainings, documents and protocols.",
  "accessNutri-logicsTraining": "Full access to Nutri-logics training, certifications, documents and protocols.",
  "accessTrainingAndConferences" : "Trainings and conferences",
  "accessNutri-logicsTrainingAndConferences" : "Full and unlimited access to training and conferences from your professional space.",
  "displayOnTheMap": "The publication on the map of nutrition advisors.",
  "eachEstablishmentIsReferenced": "Each establishment is referenced on search engines and visible to all users.",
  "payMembership": "Pay the membership / annual fee",
  "membershipEndAt": "Active membership until",
  "membershipExpired": "Membership expired",
  "remindMeLater": "Remind me later",
  "faqNutrition": "FAQ Nutrition",
  "moreQuestionsButton": "Show more questions",
  "searchQuestion": "Search a question",
  "searchQuestionsDescription": "Search by keywords or write a sentence to improve results",
  "cantFindQuestion": "Can't find the question associated with your request ?",
  "askQuestionToAgs": "Ask a question to Nutri-logics",
  "privateRequest": "Is private request",
  "myQuestions": "My questions",
  "confirm": "Confirm",
  "answerAdded": "Answer added",
  "sending": "Sending",
  "questionSentToNutri-logics": "Question sent to Nutri-logics",
  "provideAnswer": "Provide answer",
  "noQuestionsFound": "No questions found",
  "submit": "Submit",
  "status": "Status",
  "partner": "Partner",
  'level': 'Level {{lvl}}',
  "partners": "Partners",
  "addPartner": "Add a new partner",
  "partnerOf": "Partner of",
  "updatePartner": "Update partner",
  "createPartner": "Create partner",
  "partnerDescription": "The career partner refers to all institutions such as: pharmacies, beauty centers, specialized organic stores, wellness centers, sports centers and all non-nutritionist health professionals. The partner does not fit into the conventional career. <1/><2>If you need help, do not hesitate to contact your manager or director before any new partnership.</2>",
  "partnerFromAnotherDelegateDescription": "If you created partner to another network delegate, the request will appear into the delegate account.",
  "partnerDelegateDescription": "To create a partnership, you must contact your manager.",
  "partnershipRequest": "Partnership request",
  "removeRequest": "Delete the request",
  "resendRequest": "Resend the request",
  "partnershipTitle": "The advantages of the Nutri-logics partnership :",
  "partnershipP1": "The Nutri-logics complementary range at professional prices.",
  "partnershipP2": "A dedicated store for your customers.",
  "partnershipP3": "An online anamnesis.",
  "partnershipP4": "Documents and materials.",
  "partnershipP5": "Training and conferences (subject to a subscription of 80€).",
  "partnershipP6": "A professional space (logistics, invoices, customer management, etc.).",
  "partnershipConclusion1": "Once the partnership has been validated, you will be redirected to a page for creating your password. You can then connect with your email and the password you have chosen.",
  "partnershipConclusion2": "Do not hesitate to contact your referent for a detailed explanation of the tools linked to your professional account.",
  "validatePartnership": "Validate the partnership",
  "partnershipValidated": "Partnership validated",
  "challenge": "Challenge",
  "current": "Current",
  "objective": "Objective",
  "objectiveMin": "Obj",
  "done": "Completed",
  "todo": "To do",
  "giftCards": "Gift cards",
  "giftCard": "Gift card",
  "code": "Code",
  "sentAt": "Sent at",
  "usedAt": "Used at",
  "convertGiftCardDescription": "Buy a {{amount}} gift card? The amount will be deducted from your commissions.",
  "giftCardRecoveryDescription": "If the card is not used before {{date}}, it will be usable again.",
  "cardWasNotGivenToACustomer": "The card was not given to a customer",
  "cardHasNotBeenSent": "The card has not been sent",
  "cardHasNotBeenUsed": "The card has not been used",
  "sendTheGiftCard": "Send the gift card",
  "resendTheGiftCard": "Resend the gift card",
  "offerGiftCard": "Offer a gift card",
  "giftCardAvailable": "{{count}} available",
  "giftCardAvailable_plural": "{{count}} available",
  "noGiftCard": "There is no gift card",
  "noGiftCardAvailable": "There is no gift card available",
  "convertAndSendGiftCardDescription": "Buy a {{amount}} gift card and send it? The amount will be deducted from your commissions.",
  'seeUsedGiftCards': "See used gift cards",
  'seeUnusedGiftCards': "See unused gift cards",
  "amountGiftCard": "{{count}} {{currency}} gift card",
  "availableForSending": "Available for sending",
  "myTreeChallenge": "Challenge : My tree in Senegal",
  "recruitmentsChallengeTitle": "Recruit for the months of July, August and September at least 3 direct. These must achieve a minimum of 500 EUR in personal turnover to be validated and the total sum of 2250 EUR in turnover by October 31, 2022.",
  "recruitmentsChallengeDescription": "From 2022-07-01 to 2022-10-01, ",
  "congratulationsObjectivesCompleted": "Congratulations, you have successfully completed the objective !",
  "treeCountChallengeDescription": "Achieve 250 € of turnover during the month of February",
  "starterBonus1Title": "Starter bonus: Business Pack",
  "starterBonus1Description": "Get a business pack by achieving 150 € of turnover before {{date}}",
  "starterBonus2Title": "Starter bonus: Membership",
  "starterBonus2Description": "Get one year membership pack by achieving 500 € of turnover before {{date}}",
  "starterBonus3Title": "Starter Bonus: First Order",
  "starterBonus3Description1": "Get a free pack of your choice, every two packs purchased. (maximum 5)",
  "starterBonus3Description2": "Every two packs purchased during your first order, an Nutri-logics pack of your choice is offered. Once your order has been placed, come back here to choose your packs.",
  "claimYourPrize": "Claim your prize",
  "congratulationsBonusClaimed": "Congratulations !!! You bonus prize is validated.",
  "bonusEnded": "Bonus ended",
  "makeYourFirstOrder": "Make your first order",
  "packsBought": "Packs achetés",
  "chooseYourFreePack": "Choose your free pack",
  "claimYourPack": "Claim your pack",
  "claimYourPacks": "Claim your packs",
  "bonusEndAt": "The bonus ends at",
  "demoSession": "Demo session",
  "restoreSessionControl": "End the demo ?",

  //CAREER
  "career": "Career",
  "networkPerformances": "Network performances",
  "parent": "Parent",
  "depth": "Depth",
  "treeStructure": "Structure -Tree",
  "listStructure": "Structure -List",
  "mapStructure": "Structure -Map",
  "turnover": "Turnover",
  "recruitment": "Recruitment",
  "videoPlayerError": "Sorry, your browser does not support embedded videos. Get the video on this URL : {{url}}",
  "needPreviousSteps": "You need to finish previous chapters to access this content",
  "previousChapter": "Previous chapter",
  "nextChapter": "Next chapter",
  "downloadLesson": "Download ppt lesson",
  "videoSeenAndNextChapter": "I have seen the video and go to the next chapter",
  "videoSeenAndSelectCareer": "I have seen the video and select my career",
  "validateTest": "Validate the test",
  "theNutri-logicsCareer": "The Nutri-logics career",
  "theObjectives": "The objectives",
  "theObjectivesDescription": "Visualize the achievement of your objectives in real time",
  "editObjectives": "Edit objectives",
  "commitChanges": "Commit changes",
  "realized": "Realized",
  "realizedMin": "Rea",
  "total": "Total",
  "theStarterKit": "The starter kit",
  "theDelegatePosition": "The delegate position",
  "theConsultantPosition": "The consultant position",
  "nutritionConsultant": "Nutrition consultant",
  "nutritionistCoach": "Nutrition consultant / Coach",
  "nutritionistManager": "Nutrition consultant Nutri-logics",
  "structureCoach": "Structure / Coach",
  "directSellerCoach": "Seller / Coach",
  "theNutritionConsultantPosition": "The nutrition consultant position",
  "theCoachPosition": "The coach position",
  "theManagerPosition": "The manager position",
  "theDirectorPosition": "The director position",
  "nutritionist": "Nutrition consultant",
  "theCertification": "Certification {{id}}",
  "test": "Test",
  "careerTestLocked": "You can go to the documents again until you can try the test again.",
  "comebackAt": "The test come back {{date}}",
  "youPassedTheTest": "Congratulations, you passed the test.",
  "youFailedTheTest": "You did not pass the test. You have validated {{ratio}} question (s)",
  "countCorrectAnswers": "You have {{ratio}} of success on this question.",
  "correctAndRetry": "Last attempt.",
  "correctAndRetry_plural": "Still {{count}} attempts",
  "objectivesToReach": "To achieve the position of {{position}}, you must achieve the following objectives :",
  "positionComingSoon": "Just a little more patience, the position of {{position}} is coming soon ...",
  "loseDelegatesByChoosingThisPositionInfo": "By choosing this position, your delegates will be entrusted to your coach.",
  "consultantsList": "Consultants list :",
  "directSalesToReach": "To reach {{amount}} {{currency}} of direct sales turnover",
  "directOrNetworkSalesToReach": "To reach {{amount}} {{currency}} of direct + network sales turnover",
  "directSalesRecurrenceToReach": "Reach {{amount}} {{currency}} monthly recurrence in direct sales. (Average calculated over 6 months)",
  "networkSalesToReach": "To reach {{amount}} {{currency}} of network sales turnover (max {{amountMax}} {{currency}} per branch)",
  "alreadyCounted": "{{amount}} {{currency}} already counted",
  "haveStructure": "To have {{amount}} {{role}} on the first level",
  "alreadyHave": "{{amount}} {{role}} already in your first level",
  "membershipAndCertification": "Membership and certification",
  "youAreInThePositionAndCareer": "You are in the position of {{position}} in the career of {{career}}",
  "youAreInThePosition": "You are in the position of {{position}}",

  "intestineAndConstipationPack": "The transit plus and constipation pack",

  "intestineAndConstipationPackQ1": "What is the transit plus pack made of ?",
  "intestineAndConstipationPackQ1R1": "Transit + Vita & Meli Vita",
  "intestineAndConstipationPackQ1R2": "Curcuma vita & Save renal",
  "intestineAndConstipationPackQ1R3": "MG Top & Meli Vita",
  "intestineAndConstipationPackQ2": "What is vita + transit ?",
  "intestineAndConstipationPackQ2R1": "A pro biotic",
  "intestineAndConstipationPackQ2R2": "A natural anti-biotic",
  "intestineAndConstipationPackQ2R3": "An intestinal coenzyme",
  "intestineAndConstipationPackQ3": "What role does transit + Vita play ?",
  "intestineAndConstipationPackQ3R1": "It helps improve peristaltic movement of the intestine",
  "intestineAndConstipationPackQ3R2": "It contributes to the digestion process, it normalizes intestinal mobility. It helps fight against dysbiosis and regulate intestinal transit, it improves gastrointestinal health by reducing inflammation, oxidative stress, l 'intoxication and it improves the status of certain vitamins produced by bacteria in the intestine ",
  "intestineAndConstipationPackQ3R3": "Transit + vita contributes to the process of degradation of the food bolus and to regulate bile, it improves digestion and appetite",
  "intestineAndConstipationPackQ4": "What role does Méli Vita play ?",
  "intestineAndConstipationPackQ4R1": "Méli Vita, participates in the proper functioning of the nervous system, antispasmodics (calms or neutralizes the involuntary contractions of the muscles)",
  "intestineAndConstipationPackQ4R2": "Méli Vita allows the food bolus to have a perfect intestinal pH",
  "intestineAndConstipationPackQ4R3": "Méli Vita participates in a rapid evacuation of stools",
  "intestineAndConstipationPackQ5": "The Nutri-logics headquarters are installed ?",
  "intestineAndConstipationPackQ5R1": "Luxembourg",
  "intestineAndConstipationPackQ5R2": "Belgium",
  "intestineAndConstipationPackQ5R3": "France",

  "immunityPack": "The immunity pack",

  "immunityPackQ1": "What is the immunity pack made of ?",
  "immunityPackQ1R1": "NAC Vita - Zinc Vita - Vitamin D3 forte Vita - L’Origoglio Vita",
  "immunityPackQ1R2": "NAC Vita - FER Vita - Vitamin B - Spirulina Vita",
  "immunityPackQ1R3": "Nac Vita - MG TOP - Alca Vita - Cuculong’arti",
  "immunityPackQ2": "What is the immune system ?",
  "immunityPackQ2R1": "First line of defense against infection",
  "immunityPackQ2R2": "All the body's defense organs",
  "immunityPackQ2R3": "It is the body's ability to cope with any ordeal",
  "immunityPackQ3": "What role does the NAC Vita play ?",
  "immunityPackQ3R1": "N-acetyl-L-cysteine ​​(NAC) is a protein molecule that comes from phosphoric cysteine. It is incredibly effective in detoxifying and freeing foreign bodies",
  "immunityPackQ3R2": "N-acetyl-L-cysteine ​​(NAC) is an acetylated form of an amino acid - cysteine. It has been shown to be very effective as an antioxidant, detoxifier, antimicrobial, mucolytic and anti- inflammatory",
  "immunityPackQ3R3": "NAC is a natural particle that comes straight from NESTLé laboratories, better known in the form of NIC-NA",
  "immunityPackQ4": "What role does Zinc play ?",
  "immunityPackQ4R1": "This aminochelated zinc formula in the form of bisglycinate is the most bioavailable and the best absorbed by the body. Zinc helps to boost cell regeneration",
  "immunityPackQ4R2": "Zinc creates a physiological acceleration which increases the performance of the person",
  "immunityPackQ4R3": "The proposed pack allows optimal absorption in the 3rd part of the intestine. The body can thus redistribute the deficient parts of the body. It increases immune vigilance",
  "immunityPackQ5": "What role does vitamin D play ?",
  "immunityPackQ5R1": "Vitamin D strengthens and capitalizes your nervous system",
  "immunityPackQ5R2": "Vitamin D strengthens and capitalizes your respiratory system",
  "immunityPackQ5R3": "Vitamin D strengthens and capitalizes your immune system",
  "immunityPackQ6": "What role does Origoglio play ?",
  "immunityPackQ6R1": "Origoglio, oregano oil, natural antibiotic",
  "immunityPackQ6R2": "Origoglio oil of oregano, exceptional seasoning",
  "immunityPackQ6R3": "L’Origoglio, oregano oil, natural disinfectant",
  "immunityPackQ7": "Nutri-logics is subject to standards ?",
  "immunityPackQ7R1": "European only",
  "immunityPackQ7R2": "Europeans and those of each country in which it operates",
  "immunityPackQ7R3": "No specific standard requirement",

  "painPack": "The ArtiZen pack",

  "painPackQ1": "What is the ArtiZen pack made of ?",
  "painPackQ1R1": "MG Top & Curculong’arti",
  "painPackQ1R2": "MG top & Ashwaganda",
  "painPackQ1R3": "MG top & Meli Vita",
  "painPackQ2": "What are the main reasons for everyday ailments ?",
  "painPackQ2R1": "Failure to respect the physiology / nutrition principle, poor diet, aging of the body",
  "painPackQ2R2": "The climate, mainly the humidity",
  "painPackQ2R3": "Organic oxidation",
  "painPackQ3": "What role does MG Top play ?",
  "painPackQ3R1": "The MG Top will stimulate your brain and provide the 1st energy needed by the body. It will boost you in the morning, create the energy necessary for a good start",
  "painPackQ3R2": "The top natural doping MG, it stimulates neurotransmitters by electromagnetic induction",
  "painPackQ3R3": "MG Top is a supplement that provides a natural tranquilizer and relaxant",
  "painPackQ4": "What role does curculong’arti play ?",
  "painPackQ4R1": "Curculong'arti is based on Biocurcumax® which is a patented extract of turmeric titrated at 95% curcumin, 100% natural. Its particularity is to also contain the essential oils of concentrated turmeric, including Turmerone, which plays a cofactor role which improves the digestive assimilation of curcuminoids. Boswellic acid and harpagophytum for their part, play an anti-inflammatory role in the joints ",
  "painPackQ4R2": "Curculong'arti is a cartilage repairer",
  "painPackQ4R3": "Curculong'arti is based on 100% natural curcumin. It has the particularity of containing concentrated essential oil of turmeric, including Violamone, which plays a major role in relaxation. Illuoritic and hydrogenetic acid, have a fundamental role in the absorption of turmeric ",
  "painPackQ5": "What does Nutri-logics mean ?",
  "painPackQ5R1": "General health support",
  "painPackQ5R2": "Global health support",
  "painPackQ5R3": "Global Health Activity",

  "bellaVitaPackPack": "The bella vita pack",

  "bellaVitaPackQ1": "What is the bella vita pack made of ?",
  "bellaVitaPackQ1R1": "Epura Vita & Detox Vita",
  "bellaVitaPackQ1R2": "MG Top and Lipzen Vita",
  "bellaVitaPackQ1R3": "Epura Vita & Alca Vita",
  "bellaVitaPackQ2": "What are the main reasons for difficulty recovering after a drunken night ?",
  "bellaVitaPackQ2R1": "An overload of the liver",
  "bellaVitaPackQ2R2": "Lack of sleep",
  "bellaVitaPackQ2R3": "The poor quality of the power supply",
  "bellaVitaPackQ3": "What role does Save Epura Vita ?",
  "bellaVitaPackQ3R1": "Epura Vita is a formulation based on 5 plants and superfoods extremely important for the detoxification process and for the proper functioning of the elimination organs.",
  "bellaVitaPackQ3R2": "Epura vita is a formation of 3 black dandelion and artichoke radi plants which aims to help the organs eliminate toxins.",
  "bellaVitaPackQ3R3": "Epura vita is a set that helps to physically and psychologically purify the body and mind.",
  "bellaVitaPackQ4": "What role does the Alca Vita play ?",
  "bellaVitaPackQ4R1": "Alca Vita is an alkaline form of vinegar which helps restore an acid / basic balance in the body.",
  "bellaVitaPackQ4R2": "Alca Vita is the perfect food supplement in the fight against acidosis and the restoration of the acid-base balance.",
  "bellaVitaPackQ4R3": "Alca Vita is the ultimate supplement in the fight against alkalanization and muscle recovery after exercise.",
  "bellaVitaPackQ5": "What are the most Nutri-logics regarding supplements ?",
  "bellaVitaPackQ5R1": "No specific differences compared to the competition",
  "bellaVitaPackQ5R2": "90% of the production is vegan, without excipients, without preservatives, without lactose and gluten",
  "bellaVitaPackQ5R3": "They have the particularity of being better packed",

  "sportPack": "The sport pack",

  "sportPackQ1": "What is the Sport pack ?",
  "sportPackQ1R1": "L-Tyro Vita, Alca Vita, B-Alanine, Spirulina Vita",
  "sportPackQ1R2": "L-Tyro Vita, Alca Vita, B-Alanine, Ashwaganda",
  "sportPackQ1R3": "MG Top, Alca Vita, B-Alanine, Spirulina Vita",
  "sportPackQ2": "From how many steps per day are you no longer considered sedentary ?",
  "sportPackQ2R1": "3500",
  "sportPackQ2R2": "7000",
  "sportPackQ2R3": "10000",
  "sportPackQ3": "What role does the L-Tyro Vita play ?",
  "sportPackQ3R1": "L-Tyro Vita significantly improves memory, concentration and alertness while reducing fatigue",
  "sportPackQ3R2": "L-Tyro Vita regulates body temperature and helps the brain manage neurotransmitters",
  "sportPackQ3R3": "L-Tyro Vita improves physical performance",
  "sportPackQ4": "What role does Alca Vita play ?",
  "sportPackQ4R1": "Alca Vita is the perfect food supplement for the fight against acidosis and the restoration of the acid-base balance",
  "sportPackQ4R2": "Alca Vial is the dietary supplement par excellence to increase the acidity necessary for elimination",
  "sportPackQ4R3": "Alca Vita is the most suitable form of absorption for muscles in acid-basic rebalancing",
  "sportPackQ5": "What role does B-Alanine play ?",
  "sportPackQ5R1": "B-Alanine amino acid, in the liver, muscle cells absorb beta-alanine and combine it with histidine to produce carnosine hence its scientific name, β-alanyl-L-histidine. It is from this synthesis that the multiple benefits of beta-alanine derive ",
  "sportPackQ5R2": "B-Alanine, like baking soda, reduces body acidity",
  "sportPackQ5R3": "B-Alanine, plays a very important role in detoxification, it allows the liver to synthesize phosphoric analyl",
  "sportPackQ6": "What role does Vita Spirulina play ?",
  "sportPackQ6R1": "Spirulina is a microalgae or plankton which grows on the alkaline surface of lakes. It has a protein concentration (70%). It is rich in beta-carotene, bioavailable iron, essential fatty acids, vitamins of the group B (including a high concentration of vitamin B12), vitamin E, calcium, zinc, selenium, phosphorus, magnesium and chlorophyll ",
  "sportPackQ6R2": "Spirulina plays a fundamental role in the elimination of lactic acid produced by the body",
  "sportPackQ6R3": "Spirulina is a microalgae which acts as a natural painkiller for the body. Its composition rich in Vit B, allows muscle regeneration and rapid healing",

  "weightManagementPack": "The weight management pack",

  "weightManagementPackQ1": "What is the pack made of ?",
  "weightManagementPackQ1R1": "Omega Calanus & Verda-T-Vita",
  "weightManagementPackQ1R2": "Epura Vita & Detox Vita",
  "weightManagementPackQ1R3": "Renal Vita & Save Epath",
  "weightManagementPackQ2": "What are the factors of weight gain ?",
  "weightManagementPackQ2R1": "The non-assimilation of sugar by the cell",
  "weightManagementPackQ2R2": "The inability of the cell to eliminate carbohydrates",
  "weightManagementPackQ2R3": "Multi factorial",
  "weightManagementPackQ3": "What role does Omega Calanus play ?",
  "weightManagementPackQ3R1": "Omega Calanus is a natural lipid extract from Calanus finmarchicus, a herbivorous copepod commonly found in the North Atlantic Ocean. They are prone to slower digestion, allowing lipids to reach an area farther from the intestine ",
  "weightManagementPackQ3R2": "Omega Calanus is a natural lipid extract from Calanus finmarchicus, a herbivorous copepod commonly found in the South Pacific Ocean. They are prone to slower digestion, which allows lipids to reach an area farther from the intestine ",
  "weightManagementPackQ3R3": "Omega Calanus is a natural lipid extract from Calanus finmarchicus, a carnivorous copepod commonly found in the South Atlantic Ocean. They are prone to faster digestion, allowing carbohydrates to reach an area farther from the intestine ",
  "weightManagementPackQ4": "What role does Verda-T-Vita play ?",
  "weightManagementPackQ4R1": "Verda T vita, its richness in active ingredients makes green tea one of the most powerful antioxidants, which stimulates fat-burning action and actively participates in liver detoxification!",
  "weightManagementPackQ4R2": "Verda T vita, its richness in active ingredients makes green tea one of the most powerful anticoagulants which stimulates fat-burning action and actively participates in liver detoxification!",
  "weightManagementPackQ4R3": "Verda T vita, its richness in inactive ingredients makes green tea one of the most powerful vectors which stimulates fat-burning action and actively participates in kidney elimination!",
  "weightManagementPackQ5": "The particularity of Nutri-logics containers are ?",
  "weightManagementPackQ5R1": "100% Biotechnological",
  "weightManagementPackQ5R2": "100% Ecological",
  "weightManagementPackQ5R3": "100% Biodegradable",

  "businessPack": "The business pack",

  "businessPackQ1": "What is the pack made of ?",
  "businessPackQ1R1": "L-Tyro Vita, MG Top, Mind Vita",
  "businessPackQ1R2": "MG Top, Omega Boost Vita, Vitamin D",
  "businessPackQ1R3": "Mind Vita, MG Malate, Zinc",
  "businessPackQ2": "The business pack is more for ?",
  "businessPackQ2R1": "The office worker",
  "businessPackQ2R2": "The company manager",
  "businessPackQ2R3": "The unemployed",
  "businessPackQ3": "What role does L-Tyro vita play ?",
  "businessPackQ3R1": "L-Tyro Vita significantly improves memory, concentration and alertness while reducing fatigue",
  "businessPackQ3R2": "L-Tyro Vita regulates body temperature and helps the brain manage neurotransmitters",
  "businessPackQ3R3": "L-Tyro Vita improves physical performance",
  "businessPackQ4": "What role does the MG Top play ?",
  "businessPackQ4R1": "The MG Top will stimulate your brain and provide the 1st energy the body needs. It will boost you in the morning, create the energy you need for a good start",
  "businessPackQ4R2": "The top natural doping MG, it stimulates neurotransmitters by electromagnetic induction",
  "businessPackQ4R3": "The MG Top is a supplement that offers a natural tranquilizer and relaxant",
  "businessPackQ5": "What role does Mind Vita play ?",
  "businessPackQ5R1": "The Vita Mind will strengthen your concentration and motivation (Rhodiola) natural anti-stress",
  "businessPackQ5R2": "The Vita Mind will strengthen your confidence and natural anti-biotic (Rhodiola) immune system",
  "businessPackQ5R3": "The vita Mind will strengthen your self-control and your natural anti-negative wave neurotransmitters (Rhadiola)",
  "businessPackQ6": "The global food supplement market represented in 2020 ?",
  "businessPackQ6R1": "12,600,000,000 €",
  "businessPackQ6R2": "250,000,000,000 €",
  "businessPackQ6R3": "130,000,000,000 €",

  "wellnessPack": "The wellness pack",

  "wellnessPackQ1": "What is the pack made of ?",
  "wellnessPackQ1R1": "L-Tyro Vita 125, MG Top, Epura Vita",
  "wellnessPackQ1R2": "L-Tyro Vita, MG Top, Epura Vita",
  "wellnessPackQ1R3": "L-Tyro, Ashwaganda, Cassis Vita",
  "wellnessPackQ2": "Who is the Wellness package for ?",
  "wellnessPackQ2R1": "People who are well and want to maintain this state",
  "wellnessPackQ2R2": "People who are doing badly and want to get better",
  "wellnessPackQ2R3": "People with burn-out",
  "wellnessPackQ3": "What role does the L-Tyro Vita 125 play ?",
  "wellnessPackQ3R1": "L-Tyro vita 125 Ideal for soothing mental fatigue, promoting concentration and memory and improving the feeling of well-being!",
  "wellnessPackQ3R2": "L-Tyro vita 125 Ideal for soothing physical fatigue, promoting concentration and memory and improving the feeling of well-being!",
  "wellnessPackQ3R3": "L-Tyro vita 125 Ideal for soothing emotional fatigue, promoting concentration and memory and improving the feeling of well-being!",
  "wellnessPackQ4": "What role does the MG Top play ?",
  "wellnessPackQ4R1": "The MG Top will stimulate your brain and provide the 1st energy the body needs. It will boost you in the morning, create the energy you need for a good start",
  "wellnessPackQ4R2": "The top natural doping MG, it stimulates neurotransmitters by electromagnetic induction",
  "wellnessPackQ4R3": "The MG Top is a supplement that offers a natural tranquilizer and relaxant",
  "wellnessPackQ5": "What role does Epura Vita play ?",
  "wellnessPackQ5R1": "Epura Vita is a formulation based on 5 plants and superfoods extremely important for the detoxification process and for the proper functioning of the elimination organs",
  "wellnessPackQ5R2": "Epura Vita is a formulation based on 5 plants and superfoods extremely important for the process of tissue renewal",
  "wellnessPackQ5R3": "Epura Vita is a formulation based on 5 plants and superfoods extremely important for the cortisol management process",
  "wellnessPackQ6": "The global food supplement market estimate in 2024 is ?",
  "wellnessPackQ6R1": "200,000,000,000 €",
  "wellnessPackQ6R2": "225,000,000,000 €",
  "wellnessPackQ6R3": "250,000,000,000 €",

  "detoxPack": "The detox pack",

  "detoxPackQ1": "What is the pack made of ?",
  "detoxPackQ1R1": "NAC Vita, Epura Vita, Broco Vita",
  "detoxPackQ1R2": "Epura Vita, Renal Vita, Save Hepat",
  "detoxPackQ1R3": "NAC Vita, Epura Vita, Detox Vita",
  "detoxPackQ2": "The liver detoxifies and the kidneys eliminate ?",
  "detoxPackQ2R1": "True",
  "detoxPackQ2R2": "False",
  "detoxPackQ3": "What role does the Nac Vita play ?",
  "detoxPackQ3R1": "N-acetyl-L-cysteine ​​(NAC) is an acetylated form of an amino acid - cysteine. It has been shown to be very effective as an antioxidant, detoxifier, antimicrobial, mucolytic and anti- inflammatory",
  "detoxPackQ3R2": "N-acetyl-L-cysteine ​​(NAC) is a protein molecule that comes from phosphoric cysteine. It is incredibly effective in detoxifying and fluidity of foreign bodies",
  "detoxPackQ3R3": "NAC is a natural particle which comes straight from NESTLé laboratories better known in the form of NIC-NAC",
  "detoxPackQ4": "What role does L’Epura Vita play ?",
  "detoxPackQ4R1": "Epura Vita is a formulation based on 5 plants and superfoods extremely important for the detoxification process and for the proper functioning of the elimination organs",
  "detoxPackQ4R2": "Epura Vita is a formulation based on 5 plants and superfoods extremely important for the process of tissue renewal",
  "detoxPackQ4R3": "Epura Vita is a formulation of 5 herbs and superfoods extremely important for the cortisol management process",
  "detoxPackQ5": "What role does Broco Vita play ?",
  "detoxPackQ5R1": "The beneficial properties of broccoli include a positive effect on digestion, cardiovascular and immune systems, as well as anti-inflammatory and anti-carcinogenic effects",
  "detoxPackQ5R2": "The beneficial properties of broccoli include a boosting effect on the intestine, the digestive system",
  "detoxPackQ5R3": "The beneficial properties of broccoli include anti-inflammatory and antidiuretic effect",
  "detoxPackQ6": "How many apples would you need to eat these days to have the same nutritional value in vitamin C as a 1950 apple ?",
  "detoxPackQ6R1": "20",
  "detoxPackQ6R2": "50",
  "detoxPackQ6R3": "100",

  "sleepManagementPack": "The sleep management pack",

  "sleepManagementPackQ1": "What is the pack made of ?",
  "sleepManagementPackQ1R1": "Aloé Vita & Cardioméga 700+",
  "sleepManagementPackQ1R2": "Berbé Vita & CoQ10",
  "sleepManagementPackQ1R3": "Mind Vita & Noctivance",
  "sleepManagementPackQ2": "The percentage of the population with a sleep problem is approaching ?",
  "sleepManagementPackQ2R1": "15%",
  "sleepManagementPackQ2R2": "55%",
  "sleepManagementPackQ2R3": "73.5%",
  "sleepManagementPackQ3": "What role does the Mind Vita play ?",
  "sleepManagementPackQ3R1": "Mind Vita is a phytotherapeutic food supplement combining: The toning and adaptogenic effects of Rhodiola. The anti-stress virtues of Griffonia. The antidepressant action of saffron.",
  "sleepManagementPackQ3R2": "Mind Vita allows the food bolus to have a perfect intestinal pH",
  "sleepManagementPackQ3R3": "Mind Vita participates in a rapid evacuation of stools",
  "sleepManagementPackQ4": "What role does Noctivance play ?",
  "sleepManagementPackQ4R1": "Melatonin and lemon balm in oral spray. Help reduce the time it takes to fall asleep, reduce fatigue. Help reduce the effects of jet lag",
  "sleepManagementPackQ4R2": "Melatonin and lemon balm in mouth spray. Help reduce stress level, eliminate antispasmodic problems",
  "sleepManagementPackQ4R3": "Melatonin and lemon balm oral spray. Help achieve a deep sleep zone, allows alpha wave production, conducive to relaxation",
  "sleepManagementPackQ5": "One of the observations that can be drawn from the observations of the WHO concerning the number of deaths following «junk food» ?",
  "sleepManagementPackQ5R1": "Too much meat, too much plastic, too much food diversity",
  "sleepManagementPackQ5R2": "Too much sugar, too much pesticide, too much junk food",
  "sleepManagementPackQ5R3": "Too much stress, too much pollution, too much dye",

  "stressManagementPack": "The Relax pack",

  "stressManagementPackQ1": "What is the pack made of ?",
  "stressManagementPackQ1R1": "L-Tyro Vita, Ashwaganda, Mind Vita",
  "stressManagementPackQ1R2": "MG Top, Skin Vita, Curcu Vita",
  "stressManagementPackQ1R3": "Curlong’arti Vita, MG Top, Arterio Vita",
  "stressManagementPackQ2": "Do the adrenals play a role in the stress process ?",
  "stressManagementPackQ2R1": "Yes",
  "stressManagementPackQ2R2": "No",
  "stressManagementPackQ3": "What role does vita L-Tyro play ?",
  "stressManagementPackQ3R1": "L-Tyro Vita significantly improves memory, concentration and alertness while reducing fatigue",
  "stressManagementPackQ3R2": "L-Tyro Vita regulates body temperature and helps the brain manage neurotransmitters",
  "stressManagementPackQ3R3": "L-Tyro Vita improves physical performance",
  "stressManagementPackQ4": "What role does Ashwaganda play ?",
  "stressManagementPackQ4R1": "Ashwaganda reduces stress and anxiety, increases cortisol levels, improves memory and cognitive performance, decreases sugar surges",
  "stressManagementPackQ4R2": "Ashwaganda calms the pituitary gland, which triggers flight or fight mode",
  "stressManagementPackQ4R3": "Ashwaganda regulates the level of cortisol produced in the kidneys and stimulates the pituitary gland, so it instructs neurotransmitters to put the body on alert",
  "stressManagementPackQ5": "What role does Mind Vita play ?",
  "stressManagementPackQ5R1": "The Vita Mind will strengthen your concentration and motivation (Rhodiola) natural anti-stress",
  "stressManagementPackQ5R2": "The Vita Mind will strengthen your confidence and immune system (Rhodiola) natural antibiotic",
  "stressManagementPackQ5R3": "The vita Mind will strengthen your self-control and your natural anti-negative wave neurotransmitters (Rhadiola)",
  "stressManagementPackQ6": "If we compare the man of yesteryear and the man of today in terms of physical activity and nutrition, what can we say ?",
  "stressManagementPackQ6R1": "He was spending more than he consumed in calories ingested",
  "stressManagementPackQ6R2": "He was spending as many calories as he was consuming",
  "stressManagementPackQ6R3": "He was spending less calories than he was consuming",

  "beautyPack": "The beauty pack",

  "beautyPackQ1": "What is the pack made of ?",
  "beautyPackQ1R1": "Skin Vita & Phenol Vita",
  "beautyPackQ1R2": "Curcu Vita & D3 Vita",
  "beautyPackQ1R3": "Cérébroméga Boost & Aloè Vita",
  "beautyPackQ2": "The nightly fast is minimum equivalent to at least ?",
  "beautyPackQ2R1": "8h",
  "beautyPackQ2R2": "14h",
  "beautyPackQ2R3": "18h",
  "beautyPackQ3": "What role does Skin Vita play ?",
  "beautyPackQ3R1": "The SKIN Vita Significant anti-wrinkle effect with smooth skin after 6 weeks and more sustained after 12 weeks of supplementation",
  "beautyPackQ3R2": "The SKIN Vita Significant anti-wrinkle effect with smooth skin after 6 days and more sustained after 12 days of supplementation",
  "beautyPackQ3R3": "The SKIN Vita Significant anti-wrinkle effect with smooth skin after 6 hours and more sustained after 12 hours of supplementation",
  "beautyPackQ4": "What role does vita Phenol play ?",
  "beautyPackQ4R1": "Polyphenols are extremely potent antioxidants that can help prevent serious disease and protect human health. Phenol Vita is a true wonder for the entire protection of the body",
  "beautyPackQ4R2": "Polyphenols are extremely effective anti-inflammatory drugs that can help prevent serious illnesses and protect human health. Phenol Vita is a true prodigy for the entire protection of the body",
  "beautyPackQ4R3": "Polyphenols are extremely effective hormones that can help prevent serious illnesses and protect human health. Phenol Vita is a true prodigy for the entire protection of the body",
  "beautyPackQ5": "Over a day, what should we promote, physiologically and chronologically ?",
  "beautyPackQ5R1": "In the morning, dopamine and nourish the brain (protein), at noon, dopamine (protein), in the afternoon, stimulate the brain (fast sugar), in the evening promote the state of relaxation ( slow carbohydrate) ",
  "beautyPackQ5R2": "In the morning, create a spike in insulin to stimulate refined sugar at noon, ditto at 4:00 p.m., protein in the evening",
  "beautyPackQ5R3": "In the morning, fast sugars, at noon, fast sugars, at 4 p.m., fast sugars, in the evening fast sugars and proteins",

  "certificationIntro": "Certification introduction",

  "cellularPhysiology": "Cellular physiology",

  "cellularPhysiologyQ1": "What are the components that define the cell ?",
  "cellularPhysiologyQ1R1": "The nucleus",
  "cellularPhysiologyQ1R2": "The intrinsic factor",
  "cellularPhysiologyQ1R3": "The mitochondria",
  "cellularPhysiologyQ1R4": "The membrane",
  "cellularPhysiologyQ2": "The function of the mitochondria is ?",
  "cellularPhysiologyQ2R1": "Protein production",
  "cellularPhysiologyQ2R2": "The survival of the cell",
  "cellularPhysiologyQ2R3": "Energy production",
  "cellularPhysiologyQ3": "What are the elements necessary to produce energy in a cell ?",
  "cellularPhysiologyQ3R1": "Oxygen",
  "cellularPhysiologyQ3R2": "Energy substrate",
  "cellularPhysiologyQ3R3": "Glycogen",
  "cellularPhysiologyQ3R4": "Glucose",
  "cellularPhysiologyQ4": "What is the particularity of the cell membrane ?",
  "cellularPhysiologyQ4R1": "Defines the cell",
  "cellularPhysiologyQ4R2": "Cellular porosity",
  "cellularPhysiologyQ4R3": "Nutrient transfer and waste disposal",
  "cellularPhysiologyQ4R4": "Contains phospholipids",
  "cellularPhysiologyQ4R5": "Allows the protection of DNA",
  "cellularPhysiologyQ5": "What is DNA ?",
  "cellularPhysiologyQ5R1": "Protein producer",
  "cellularPhysiologyQ5R2": "Energy producer",
  "cellularPhysiologyQ5R3": "RNA producer",
  "cellularPhysiologyQ5R4": "Command center of the cell",
  "cellularPhysiologyQ5R5": "Genetic code",

  "immunity": "Immunity",

  "immunityQ1": "What is the immune system ?",
  "immunityQ1R1": "Body defense system",
  "immunityQ1R2": "Brain protection system",
  "immunityQ1R3": "Cell defense system (natural barrier, skin, microbiota)",
  "immunityQ2": "What is the immune system made of ?",
  "immunityQ2R1": "From immune cells",
  "immunityQ2R2": "White blood cells",
  "immunityQ2R3": "From lymphocytes",
  "immunityQ2R4": "From Pteriocytes",
  "immunityQ2R5": "From macrophages",
  "immunityQ2R6": "Antigens",
  "immunityQ3": "What are the functions of white blood cells ?",
  "immunityQ3R1": "Produce energy",
  "immunityQ3R2": "Transport oxygen",
  "immunityQ3R3": "Blood coagulation",
  "immunityQ3R4": "Defense of the organism",
  "immunityQ4": "How important is inflammation physiologically ?",
  "immunityQ4R1": "Destroys tissue",
  "immunityQ4R2": "Consumes energy",
  "immunityQ4R3": "Protects against infection",
  "immunityQ4R4": "Protects against cancer",
  "immunityQ4R5": "Regenerates tissue",

  "fertility": "Fertility",

  "fertilityQ1": "What is the difference between male and female fertility ?",
  "fertilityQ1R1": "The fertility of women is based on the breasts while that of men is based on the testicles",
  "fertilityQ1R2": "A woman's fertility requires progesterone while that of a man requires estrogen",
  "fertilityQ1R3": "The woman uses the ovaries to produce estrogen while the man uses the testes to produce testosterone",
  "fertilityQ2": "What factors can affect fertility ?",
  "fertilityQ2R1": "Heavy metals",
  "fertilityQ2R2": "Deficiencies",
  "fertilityQ2R3": "Endocrine disruptors (ex: pesticides)",
  "fertilityQ2R4": "The sedentary lifestyle",
  "fertilityQ2R5": "Hormones",
  "fertilityQ3": "What is the role of the prostate in fertility ?",
  "fertilityQ3R1": "Produce spermatozoa",
  "fertilityQ3R2": "Produce prostatic fluid",
  "fertilityQ3R3": "Produce sperm",
  "fertilityQ4": "What is the role of hormonal balance in a woman's fertility ?",
  "fertilityQ4R1": "Allows better fertility",
  "fertilityQ4R2": "Allows better health",
  "fertilityQ4R3": "Allows better production of breast milk",
  "fertilityQ4R4": "Helps fight against genital infections",

  "digestionIntestineMicrobiota": "Digestion, intestine, microbiota",

  "digestionIntestineMicrobiotaQ1": "What is meant by digestion ?",
  "digestionIntestineMicrobiotaQ1R1": "Degradation process of amino acids, fatty acids and glucose",
  "digestionIntestineMicrobiotaQ1R2": "Energy production process",
  "digestionIntestineMicrobiotaQ1R3": "Food absorption process",
  "digestionIntestineMicrobiotaQ1R4": "Process of degradation of food into absorbable components",
  "digestionIntestineMicrobiotaQ2": "What are the specificities of the digestion of macro nutrients ?",
  "digestionIntestineMicrobiotaQ2R1": "Glucose, lipids, proteins",
  "digestionIntestineMicrobiotaQ2R2": "Fatty acids, amino acids, simple sugars",
  "digestionIntestineMicrobiotaQ2R3": "Fatty acid, proteins, glucose",
  "digestionIntestineMicrobiotaQ3": "What is the microbiota ?",
  "digestionIntestineMicrobiotaQ3R1": "Digestive organ",
  "digestionIntestineMicrobiotaQ3R2": "Cell organ",
  "digestionIntestineMicrobiotaQ3R3": "All microorganisms in the intestine",
  "digestionIntestineMicrobiotaQ3R4": "All bacteria in the body",
  "digestionIntestineMicrobiotaQ4": "What is the role of dietary fiber ?",
  "digestionIntestineMicrobiotaQ4R1": "Improve intestinal transit",
  "digestionIntestineMicrobiotaQ4R2": "Improve blood sugar",
  "digestionIntestineMicrobiotaQ4R3": "Feed the microbiota",
  "digestionIntestineMicrobiotaQ4R4": "Prevent fermentation of food",
  "digestionIntestineMicrobiotaQ4R5": "Promote digestion",
  "digestionIntestineMicrobiotaQ5": "What is the role of the intestinal barrier in absorption ?",
  "digestionIntestineMicrobiotaQ5R1": "Finalize digestion",
  "digestionIntestineMicrobiotaQ5R2": "Absorb and transport micronutrients",
  "digestionIntestineMicrobiotaQ5R3": "Fight inflammation",
  "digestionIntestineMicrobiotaQ5R4": "Defense system",
  "digestionIntestineMicrobiotaQ5R5": "Prevent the passage of fatty acids",

  "liverAndKidneys": "The liver and kidneys",

  "liverAndKidneysQ1": "What is the elimination process ?",
  "liverAndKidneysQ1R1": "Rid the body of waste",
  "liverAndKidneysQ1R2": "To rid the body of stool",
  "liverAndKidneysQ1R3": "To rid the body of excess protein",
  "liverAndKidneysQ2": "What is the role of the liver in digestion ?",
  "liverAndKidneysQ2R1": "The storage of nutrients",
  "liverAndKidneysQ2R2": "The production of hormones",
  "liverAndKidneysQ2R3": "Regulating appetite",
  "liverAndKidneysQ2R4": "The secretion of bile stool",
  "liverAndKidneysQ3": "What other role can the liver play ?",
  "liverAndKidneysQ3R1": "The storage",
  "liverAndKidneysQ3R2": "Sorting and cleaning nutrients",
  "liverAndKidneysQ3R3": "Tissue protection",
  "liverAndKidneysQ3R4": "Energy production",
  "liverAndKidneysQ3R5": "Detoxification",
  "liverAndKidneysQ4": "What is the role of the kidneys in elimination ?",
  "liverAndKidneysQ4R1": "Specialized in the elimination of fat-soluble toxins",
  "liverAndKidneysQ4R2": "Specialized in the elimination of water-soluble toxins",
  "liverAndKidneysQ4R3": "Detoxification",
  "liverAndKidneysQ5": "What other role can the kidneys have ?",
  "liverAndKidneysQ5R1": "The regulation of the amount of water in the body",
  "liverAndKidneysQ5R2": "Regulation of electrolytes or ions",
  "liverAndKidneysQ5R3": "Regulation of calcium levels",
  "liverAndKidneysQ5R4": "Detoxification",

  "beautySkinAntiAging": "Beauty, skin, anti-aging",

  "beautySkinAntiAgingQ1": "What are the physiological functions of the skin ?",
  "beautySkinAntiAgingQ1R1": "Protection of the body",
  "beautySkinAntiAgingQ1R2": "Cholesterol synthesis",
  "beautySkinAntiAgingQ1R3": "Synthesis of vitamin D",
  "beautySkinAntiAgingQ1R4": "Vitamin E production",
  "beautySkinAntiAgingQ2": "What are the tissues that make up the skin ?",
  "beautySkinAntiAgingQ2R1": "Epidermis",
  "beautySkinAntiAgingQ2R2": "Dermis",
  "beautySkinAntiAgingQ2R3": "Dermatological tissues",
  "beautySkinAntiAgingQ2R4": "Hypodermis",
  "beautySkinAntiAgingQ3": "In which case does the healing process occur in the skin ?",
  "beautySkinAntiAgingQ3R1": "Obesity",
  "beautySkinAntiAgingQ3R2": "Autoimmune disease",
  "beautySkinAntiAgingQ3R3": "Trauma (wounds)",
  "beautySkinAntiAgingQ3R4": "Burn",
  "beautySkinAntiAgingQ3R5": "Aging of the skin",
  "beautySkinAntiAgingQ4": "How to slow down aging ?",
  "beautySkinAntiAgingQ4R1": "With antioxidants",
  "beautySkinAntiAgingQ4R2": "With polyphenols",
  "beautySkinAntiAgingQ4R3": "With sunscreens",
  "beautySkinAntiAgingQ4R4": "By detoxifying the skin",

  "nervousSystemSleepStress": "The nervous system, sleep, stress",

  "nervousSystemSleepStressQ1": "What is the nervous system made of ?",
  "nervousSystemSleepStressQ1R1": "Central and peripheral nervous system",
  "nervousSystemSleepStressQ1R2": "Brain, nerves and bulb",
  "nervousSystemSleepStressQ1R3": "Nerve impulses, nerves and spinal cord",
  "nervousSystemSleepStressQ2": "What are the functions of the nervous system ?",
  "nervousSystemSleepStressQ2R1": "Production of nervous impulses",
  "nervousSystemSleepStressQ2R2": "Muscle contraction only",
  "nervousSystemSleepStressQ2R3": "Order all organs",
  "nervousSystemSleepStressQ2R4": "Manages circadian cycles",
  "nervousSystemSleepStressQ3": "What is the role of neurotransmitters ?",
  "nervousSystemSleepStressQ3R1": "Transmit neuronal molecules",
  "nervousSystemSleepStressQ3R2": "Relays between nerves and other organs or cells",
  "nervousSystemSleepStressQ3R3": "Protect synapses",
  "nervousSystemSleepStressQ3R4": "Produce synapes",
  "nervousSystemSleepStressQ4": "How to optimize sleep ?",
  "nervousSystemSleepStressQ4R1": "By optimizing the neurotransmitters of the sympathetic system",
  "nervousSystemSleepStressQ4R2": "By optimizing serotonin by tryptophan",
  "nervousSystemSleepStressQ4R3": "By optimizing the level of melatonin by 5HTP and vitamin B6",
  "nervousSystemSleepStressQ5": "How can I better manage stress ?",
  "nervousSystemSleepStressQ5R1": "By optimizing the neurotransmitters of the parasympathetic system",
  "nervousSystemSleepStressQ5R2": "By optimizing serotonin by dopamine",
  "nervousSystemSleepStressQ5R3": "By optimizing the level of melatonin by tyrosine and vitamin B6 and C",
  "nervousSystemSleepStressQ6": "How can the nervous system and the brain be protected against aging and toxicity ?",
  "nervousSystemSleepStressQ6R1": "By anti-inflammatories such as omega 3 or polyphenols",
  "nervousSystemSleepStressQ6R2": "By phospholipids like phosphatidylcholine (vitamin B12)",
  "nervousSystemSleepStressQ6R3": "By magnesium",
  "nervousSystemSleepStressQ6R4": "By antidepressants",

  "cardiovascularSystem": "The cardiovascular system",

  "cardiovascularSystemQ1": "what is the cardiovascular system made of ?",
  "cardiovascularSystemQ1R1": "The heart and blood vessels",
  "cardiovascularSystemQ1R2": "The veins and arteries",
  "cardiovascularSystemQ1R3": "Heart and blood",
  "cardiovascularSystemQ2": "How to reduce the cardiovascular risk ?",
  "cardiovascularSystemQ2R1": "By stimulating blood circulation",
  "cardiovascularSystemQ2R2": "Eat foods with a low glycemic index",
  "cardiovascularSystemQ2R3": "By optimizing calcium binding in bone by vitamin D, K2 and magnesium",
  "cardiovascularSystemQ2R4": "With omega 3",
  "cardiovascularSystemQ3": "How to improve heart function ?",
  "cardiovascularSystemQ3R1": "With coenzyme Q10 and magnesium",
  "cardiovascularSystemQ3R2": "With B2 and B3 vitamins",
  "cardiovascularSystemQ3R3": "With trans fatty acids",
  "cardiovascularSystemQ4": "What are the roles of the lymphatic network ?",
  "cardiovascularSystemQ4R1": "Oxygen transport",
  "cardiovascularSystemQ4R2": "Transport of minerals, lipids and trace elements",
  "cardiovascularSystemQ4R3": "Protein transport",
  "cardiovascularSystemQ4R4": "Transport of immune system cells",
  "cardiovascularSystemQ5": "How to improve blood and lymphatic circulation ?",
  "cardiovascularSystemQ5R1": "With plants that improve the elasticity of vessels such as ginseng",
  "cardiovascularSystemQ5R2": "With vasodilating foods",
  "cardiovascularSystemQ5R3": "With antioxidants",
  "cardiovascularSystemQ5R4": "With B vitamins",

  "bonesAndCartilage": "Bones and cartilage",

  "bonesAndCartilageQ1": "What is bone made of ?",
  "bonesAndCartilageQ1R1": "Of solid mass composed exclusively of calcium",
  "bonesAndCartilageQ1R2": "From osteoblast cells",
  "bonesAndCartilageQ1R3": "Of osteoclast cells",
  "bonesAndCartilageQ1R4": "From osteogenic cells",
  "bonesAndCartilageQ1R5": "Mineral reserves (calcium, phosphorus, magnesium, silicon)",
  "bonesAndCartilageQ2": "What are the functions of bone tissue ?",
  "bonesAndCartilageQ2R1": "Build up a mineral reserve to delay acidity",
  "bonesAndCartilageQ2R2": "Locomotion",
  "bonesAndCartilageQ2R3": "Participate in hematopoiesis",
  "bonesAndCartilageQ2R4": "Produce immune platelets",
  "bonesAndCartilageQ2R5": "Protect organs",
  "bonesAndCartilageQ2R6": "Mineral reserve",
  "bonesAndCartilageQ3": "What is the difference between bone and cartilage ?",
  "bonesAndCartilageQ3R1": "Cartilage is made up of chondrocytes and bone of osteocytes",
  "bonesAndCartilageQ3R2": "Cartilage is found only in the joints",
  "bonesAndCartilageQ3R3": "Bone is less rigid than cartilage",
  "bonesAndCartilageQ4": "How can I improve bone health ?",
  "bonesAndCartilageQ4R1": "Prevent demineralization with vitamin D and C",
  "bonesAndCartilageQ4R2": "Prevent demineralization with vitamin D and K2",
  "bonesAndCartilageQ4R3": "Decrease tissue acidity",
  "bonesAndCartilageQ5": "How can I decrease joint inflammation ?",
  "bonesAndCartilageQ5R1": "With anti-inflammatory drugs like curcumin",
  "bonesAndCartilageQ5R2": "With anti-inflammatory glycation products",
  "bonesAndCartilageQ5R3": "By stimulating detoxification and elimination",

  "thyroid": "The thyroid",

  "thyroidQ1": "What is the thyroid ?",
  "thyroidQ1R1": "Main organ of detoxification",
  "thyroidQ1R2": "Main organ of stress production",
  "thyroidQ1R3": "Exocrine gland",
  "thyroidQ1R4": "Endocrine gland",
  "thyroidQ2": "What are the functions of the thyroid ?",
  "thyroidQ2R1": "Produce the thyroid hormones T4 and T5",
  "thyroidQ2R2": "Regulates the rate of degradation of the energy substrate",
  "thyroidQ2R3": "Regulates energy metabolism",
  "thyroidQ2R4": "Determines energy catabolism",
  "thyroidQ3": "What components are necessary for thyroid function ?",
  "thyroidQ3R1": "Magnesium",
  "thyroidQ3R2": "Iodine",
  "thyroidQ3R3": "Vitamins E and B",
  "thyroidQ3R4": "Vitamin A and omega 3",
  "thyroidQ3R5": "Zinc",
  "thyroidQ3R6": "Tryptophan",
  "thyroidQ4": "What is the relationship between the thyroid and the adrenal glands ?",
  "thyroidQ4R1": "The activity of the thyroid increases that of the adrenals",
  "thyroidQ4R2": "The activity of the thyroid decreases that of the adrenals",
  "thyroidQ4R3": "The activity of the thyroid stimulates the stress",
  "thyroidQ5": "What is the relationship between the thyroid and body weight ?",
  "thyroidQ5R1": "Thyroid activity causes weight gain",
  "thyroidQ5R2": "Thyroid activity causes weight loss",
  "thyroidQ5R3": "Hyperthyroidism increases weight",

  "theSport": "The sport",

  "theSportQ1": "How to increase your energy level to be more efficient in sports ?",
  "theSportQ1R1": "With coenzyme Q10",
  "theSportQ1R2": "With magnesium",
  "theSportQ1R3": "By increasing its energy intake in the diet",
  "theSportQ1R4": "With vitamin C",
  "theSportQ1R5": "With vitamins B2 and B3",
  "theSportQ2": "How important is the brain in the practice of sport ?",
  "theSportQ2R1": "Muscle contraction control center",
  "theSportQ2R2": "Neuromuscular coordination",
  "theSportQ2R3": "Inhibits fatigue",
  "theSportQ3": "How to optimize cardiac function in athletes ?",
  "theSportQ3R1": "With coenzyme Q10",
  "theSportQ3R2": "With magnesium",
  "theSportQ3R3": "With vitamin B2 and B3",
  "theSportQ3R4": "With potassium",
  "theSportQ4": "What are the differences between body aches, cramps and injuries ?",
  "theSportQ4R1": "Aches are micro injuries, cramps are involuntary contractions, and injuries are from trauma",
  "theSportQ4R2": "Body aches are due to excess lactic acid, cramps are involuntary contractions and injuries are due to trauma",
  "theSportQ4R3": "Aches are pain in the muscles and joints, cramps are involuntary contractions, and injuries are from trauma",
  "theSportQ4R4": "None of the above",
  "theSportQ5": "How to decrease lactic acid levels in athletes to improve recovery ?",
  "theSportQ5R1": "Increase elimination by renal function",
  "theSportQ5R2": "By rest",
  "theSportQ5R3": "By stimulating the cori cycle with magnesium",
  "theSportQ5R4": "By increasing the training load",

  "weightLoss": "Weight loss",

  "weightLossQ1": "What are the factors that contribute to weight gain ?",
  "weightLossQ1R1": "Poor quality food",
  "weightLossQ1R2": "The sedentary lifestyle",
  "weightLossQ1R3": "An acceleration of the thyroid",
  "weightLossQ1R4": "An accumulation of toxins",
  "weightLossQ1R5": "A significant amount of sleep",
  "weightLossQ1R6": "High stress",
  "weightLossQ2": "What is the difference between white adipose tissue and brown adipose tissue ?",
  "weightLossQ2R1": "White adipose tissue stores toxins while brown stores fat",
  "weightLossQ2R2": "White adipose tissue stores fat while brown burns protein",
  "weightLossQ2R3": "White adipose tissue produces anti-inflammatory adipokines while brown produces pro-inflammatory adipokines",
  "weightLossQ2R4": "None of the previous answers",
  "weightLossQ3": "What is the role of the gut-brain axis in weight regulation ?",
  "weightLossQ3R1": "The gut-brain axis regulates appetite",
  "weightLossQ3R2": "The gut-brain axis regulates detoxification",
  "weightLossQ3R3": "The intestine-brain axis regulates the microbiota",
  "weightLossQ4": "What are the means that allow weight loss in general ?",
  "weightLossQ4R1": "Reasonable restriction of feed quantity",
  "weightLossQ4R2": "Reasonable physical activity",
  "weightLossQ4R3": "Decrease the duration of sleep",
  "weightLossQ4R4": "Decrease stress",
  "weightLossQ4R5": "Rebalance the microbiota",
  "weightLossQ4R6": "Decrease the brown adipose tissue responsible for inflammation",
  "weightLossQ5": "What are the micronutrients that may be of interest for weight loss ?",
  "weightLossQ5R1": "Vitamin K",
  "weightLossQ5R2": "Calcium",
  "weightLossQ5R3": "L-carnitine",
  "weightLossQ5R4": "Polyphenols (ex: curcumin)",
  "weightLossQ5R5": "Vitamin group B (B6)",
  "weightLossQ5R6": "Vitamin D",

  "watchVideo": "Watch the video",
  "goToTheDocument": "Go to the document",
  "qualification": "Qualification",
  "sendCertificate": "Send certificate",
  "validateBySendingTrainingCertification": "Please send your certificate by email by clicking this button. <1/> A certification of an equivalent or higher specialization also works.",
  "certificationSenderButtonNotWorking": "If the sender button does not work, please send your certificate by email to info@ags-lab.com",
  "validationButtonActivation": "The button will become active after all the objectives are done",
  "getTheCertification": "Get the certification \"{{name}}\"",
  "displayTheCertification": "Display the certification \"{{name}}\"",
  "thePacks": "The packs",
  "nutritionalPhysiology": "Nutritional physiology",
  "buyProduct": "Buy: {{name}}",
  "validateQualification": "Validate the qualification",
  "qualificationEndAt": "End of qualification at {{date}}",
  "directSeller": "Direct-Seller",
  "careerChoice": "Career choice",
  "chooseTheCareer": "Choose the career that's right for you.",
  "backToCareer": "Back to career",
  "notAFinalChoiceDescription": "This choice is not final. The career will only be validated after successful completion of the qualification.",

  //CONSULTANTS
  "mapDisplayError": "Oops, it looks like the card is not charging ... <1/> Try to reload your browser.",
  "seeTheNutritionConsultant": "See the nutrition consultant",
  "youNeedToUpdateYourProfile": "You need to update your office before display it online",
  "isCurrentlyYourNutritionConsultant": "{{name}} is currently your nutrition consultant",
  "visitTheShop": "Visit the shop",
  "selectLocation": "Select location",
  "useCurrentLocation": "Use current location",
  "currentLocation": "Current location",
  "geolocation": "Geolocation",

  //ERROR & VALIDATION MESSAGE
  "error": "Error",
  "errorMessageAccount": "There is an error with your account, please contact us",
  "pageNotFound": "Page not found",
  "validatorFieldRequired": "Field required",
  "backButtonText": "Back to the previous page",
  "permissionDenied": "You do not have permission to access this page",
  "errorMessageAnErrorOccurred": "An error has occurred. Please contact us to let us know about your error",
  "errorMessageAnotherExists": "{{field}}: ({{value}}) already exists on our site",
  "errorMessageFieldDoesNotMatch": "{{field}} is not the same",
  "errorMessageFieldNeedToBeLonger": "{{field}} is not long enough",
  "errorMessageFieldNeedToBeShorter": "{{field}} is too long",
  "errorMessageFieldNeedToBeLarger": "{{field}} need to be larger",
  "errorMessageFieldNeedToBeLess": "{{field}} need to be smaller",
  "errorMessageEmptyField": "{{field}} cannot be empty",
  "errorMessageProCode": "No pro has been found with this code",
  "errorMessageFieldNeedToBeANumber": "{{field}} must be of type numeric",
  "errorMessageMustBeAValidEmail": "{{value}} is not a valid email",
  "errorMessageMustBeAValidPhoneNumber": "{{value}} is not a valid phone number",
  "errorMessageAlreadyTaken": "{{value}} already exists in the website",
  "errorMessageCannotBeChanged": "{{field}} cannot be changed",
  "errorMessageIsRequired": "The field \"{{field}}\"is required",
  "errorMessageIncorrectEmailOrPassword": "The email or password is incorrect",
  "errorMessageInvalidConsultantCode": "The nutrition consultant identifier is not valid",
  "errorMessageInvalidConsultantLink": "The nutrition consultant link is not valid",
  "errorMessageRequiredFields": "One or more entries are empty or incorrect. Please check the required fields and start over",
  "errorMessageNutritionConsultantCodeDoesNotExist": "The identifier for the nutrition consultant does not exist",
  "errorMessageNetworkError": "The site is having trouble communicating with the server. Please check your internet connection and try again",
  "errorMessageFieldNotAlphaNum": "The field \"{{field}}\" is not of alpha-numeric",
  "errorMessageFieldNotMatchPattern": "The field \"{{field}}\" fails to match the required pattern",
  "errorMessageNotFound": "Item not found",
  "errorMessageArticleNotFound": "Article not found",
  "errorMessageArticleDoesNotExistInLanguage": "The article does not exists in this language",
  "validatorInvalidEmail": "Invalid email",
  "validatorInvalidDate": "Invalid date",
  "dataUpdated": "Data updated",
  "homepage": "Homepage",
  "pageNotFoundDescription1": "The page you are looking for is not available at this moment",
  "pageNotFoundDescription2": "You can return to the homepage or contact our team",
  "invalidAccessToken": "Your access token is no longer valid, please log in again.",
  "errorMessageUnauthorized": "You do not have the necessary permissions to perform this action",
  "errorVATNumber": "Invalid VAT number.",
  "errorMessageCompleteBankDetails": "You need to complete your bank details before making a transfer",
  "errorMessageNotEnoughMoneyIntoWallet": "You do not have enough money into wallet",
  "needMembership": "You need to have membership to access this page.",
  "errorMessageGiftCardAmount": "The gift card amount need to be bigger than the payment amount",
  "errorMessageGiftCardMalformed": "The code of the gift card must be of type: XXX-XXXXXX",
  "errorMessageGiftCardNotFound": "The gift card does not exist or has already been used",
  "errorMessageGiftCardAlreadyChosen": "The gift card has already been chosen",

  //SNACKBAR
  "open": "Open",
  "close": "Close",
  "thisActionIsIrreversible": "This action is irreversible",

  //CONTACT
  "contact": "Contact",
  "contactTitle": "Contact",
  "contactUsTitle": "Contact us",
  "contactUsFirstName": "First name",
  "contactUsLastName": "Name",
  "contactUsPhone": "Phone",
  "contactUsEmail": "Email",
  "contactUsMessage": "Message",
  "contactSendMessageButton": "Send message",
  "message": "Message",
  "messageSent": "Your message has been sent to our teams, we will get back to you as soon as possible",
  "subject": "Subject",
  "whoToContact": "Who to contact ?",
  "theNutri-logicsTeam": "The Nutri-logics team",
  "yourNutritionConsultant": "Your nutrition consultant",

  //FOOTER
  "homeLink": "Home",
  "followUs": "Follow us",
  "help": "Help",
  "termsOfSales": "Terms of sales",
  "networkTermsAndConditions": "Network terms and conditions",

  //BLACK FRIDAY
  "blackFridayMessage": "Green Friday: from 26 to 28, -20% on Nutri-logics packs",
  "blackFridayButton": "See the packs",

  //CHATGPT
  "Email": "Email",
  "proName": "Pro name",
  "Subscribe": "Subscribe",
  "Enter your email": "Enter your email",
  "SubscribeToOurNewsletter": "Subscribe to our newsletter",
  "We have high standards for email too": "Vous cherchez une source d’information fiable sur les dernières tendances en matière de santé de bien-être ?",
  "day": "dag",
  "year": "jaar",
  "month": "maand",
  "modify": "Modify",
  "mailingAnduserLanguage": "Mailing and user language",
  "alreadyRegistered": "Already registered",
  "proCode": "Therapist or doctor code",
  "metagenicsNotAllowed": "Your informations do not authorize you to add Metagenics products to your cart",
  "healthPro": "Health professional",
  "aboutUs": "About",
};
