import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import AccessibilityNewTwoToneIcon from '@mui/icons-material/AccessibilityNewTwoTone';
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FeaturedPlayListTwoToneIcon from '@mui/icons-material/FeaturedPlayListTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import PanToolTwoToneIcon from '@mui/icons-material/PanToolTwoTone';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import ShoppingBasketTwoToneIcon from '@mui/icons-material/ShoppingBasketTwoTone';
import {ListItemButton} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { red } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

import {getLangPath, Link} from "../../../../helpers/multilingual";
import { SnackbarConfirm, SnackbarError, SnackbarFormattedError, SnackbarSuccess } from "../../../../helpers/snackbar";
import {history} from "../../../../helpers/store";
import { PROSPECT_ROLE } from "../../../Authentication/store/constants";
import { userSelector } from "../../../Authentication/store/selectors";
import { authenticationService } from "../../../Authentication/store/services";
import {customersActions} from "../store/actions";
import { customersService } from "../store/services";

import CustomerConsultantRedirectionModal from "./CustomerConsultantRedirectionModal";

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'auto',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& .MuiCollapse-container': {

      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50
      }
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40
    },
    '& .MuiListItem-button': {
      height: 60,
      '& .MuiSvgIcon-root': {
        fontSize: '2em',
        fill: red[900]
      }
    },
  },
  cardProfile: {
    '& .MuiAvatar-colorDefault': {
      margin: 'auto',
      width: 150,
      height: 150,
      marginTop: 20,
      marginBottom: 20,
      position: 'relative',
      '& img': {
        width: 150,
        height: 150,
      },
      '& button': {
        position: 'absolute',
        top: 45,
        left: 0,
        display: 'none'
      },
      '& p': {
        position: 'absolute',
        top: 65,
        left: 65,
      },
      '&:hover': {
        '& button': {
          display: 'inline'
        }
      }
    }
  },
  red: {
    color: red[800],
    '& .MuiSvgIcon-root': {
      fill: red[800]
    }
  }
}));

const UserActionsCard = ({customer}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [showButtons, setShowButtons] = useState(false);
  const [isConsultantModalOpen, setConsultantModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {

    const match = location.pathname.replace(getLangPath(), '').split(`/${customer.id}`);
    setSelected(match[1] || '');
  }, [location.pathname]);

  const handleSendWelcomeEmail = () => {

    authenticationService.sendWelcomeEmail(customer.id)
      .then(() => {
        SnackbarSuccess(t('emailSent'));
      })
      .catch(() => {
        SnackbarError(t('errorMessageAnErrorOccurred'));
      });
  };

  const handleRemoveConsultantRedirection = () => {

    SnackbarConfirm(t("sendBackTheCustomerToTheDelegateDescription"), () => {
      customersService.customerRedirection(customer.id, 'canceled')
        .then(() => SnackbarSuccess(t('customerIsNotLinkedAnymore')))
        .catch(e => SnackbarFormattedError(e.error));
    });
  };

  const handleGetCustomerBack = () => {

    SnackbarConfirm(t("getCustomerBackDescription"), () => {
      customersService.customerRedirection(customer.id, 'revoked')
        .then(() => SnackbarSuccess(t('customerIsNotLinkedAnymore')))
        .catch(e => SnackbarFormattedError(e.error));
    });
  };

  const handleDeleteProspect = () => {

    SnackbarConfirm(<>{t("deleteTheProspect")} <br/> <b>{t("thisActionIsIrreversible")}</b></>, () => {
      customersService.updateCustomerArchived(customer.id, true)
        .then(() => {
          SnackbarSuccess(t('prospectDeleted'));
          dispatch(customersActions.getCustomersRequest());
          history.push(`${getLangPath()}/pro/customers`);
        })
        .catch(e => SnackbarFormattedError(e.error));
    });
  };

  const handleConsultantRedirectionModalOpen = () => {
    setConsultantModalOpen(true);
  };

  const handleConsultantRedirectionModalClose = () => {
    setConsultantModalOpen(false);
  };

  const handleShowButtons = () => {
    setShowButtons(true);
  };

  return (
    <Card className={classes.cardProfile}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Avatar>
              <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/profiles/empty-profile-500.jpg`} alt=""/>
            </Avatar>
            <Typography variant={"h5"} align={"center"} gutterBottom>{customer.firstName} {customer.lastName}</Typography>
            {!!customer.company && (
              <Typography variant={"h5"} align={"center"} gutterBottom>{customer.company}</Typography>
            )}
            <Typography variant={"h6"} align={"center"}>{t(customer.role)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h3" variant={"h6"}>
                            Actions :
            </Typography>
            <List>
              <ListItemButton component={Link} to={`/pro/customer/${customer.id}`} selected={selected === ""}>
                <ListItemIcon>
                  <AccountCircleTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={t("generalInfo")} />
              </ListItemButton>
              <Divider/>
              <ListItemButton component={"a"} href={`tel:${customer.phone}`}>
                <ListItemIcon>
                  <PhoneTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={t("call")} />
              </ListItemButton>
              <Divider/>
              <ListItemButton component={"a"} href={`mailto:${customer.email}`}>
                <ListItemIcon>
                  <SendTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={t("sendEmail")} />
              </ListItemButton>
              <Divider/>
              {customer.consultantId && customer.consultantId !== user.id && (
                <>
                  <ListItemButton component={"a"} href={`tel:${customer.consultant.phone}`}>
                    <ListItemIcon>
                      <PhoneTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("callNutritionConsultant")} />
                  </ListItemButton>
                  <Divider/>
                  <ListItemButton component={"a"} href={`mailto:${customer.consultant.email}`}>
                    <ListItemIcon>
                      <SendTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("sendEmailNutritionConsultant")} />
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              {(!showButtons && !!customer.consultantId && customer.consultantId !== user.id) && (
                <>
                  <ListItemButton onClick={handleShowButtons} className={classes.red}>
                    <ListItemIcon>
                      <PanToolTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("notInChargeShowButtonsAnyway")} />
                  </ListItemButton>
                  <Divider/>
                </>
              )}
              {(showButtons || !customer.consultantId || customer.consultantId === user.id) && (
                <>
                  {customer.consultantId && customer.consultantId === user.id && (
                    <>
                      <ListItemButton component={"a"} href={`tel:${customer.parent.phone}`}>
                        <ListItemIcon>
                          <PhoneTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("callDelegate")} />
                      </ListItemButton>
                      <Divider/>
                      <ListItemButton component={"a"} href={`mailto:${customer.parent.email}`}>
                        <ListItemIcon>
                          <SendTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("sendEmailDelegate")} />
                      </ListItemButton>
                      <Divider/>
                    </>
                  )}
                  <ListItemButton component={Link} to={`/pro/customer/${customer.id}/orders`} selected={selected === "/orders"}>
                    <ListItemIcon>
                      <ShoppingBasketTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("orders")} />
                  </ListItemButton>
                  <Divider/>
                  <ListItemButton component={Link} to={`/pro/customer/${customer.id}/anamneses`} selected={selected === "/anamneses"}>
                    <ListItemIcon>
                      <FeaturedPlayListTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("anamneses")}/>
                  </ListItemButton>
                  <Divider/>
                  <ListItemButton component={Link} to={`/pro/customer/${customer.id}/gifts`} selected={selected === "/gifts"}>
                    <ListItemIcon>
                      <CardGiftcardIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("giftCards")}/>
                  </ListItemButton>
                  <Divider/>
                  <ListItemButton component={Link} to={`/pro/customer/${customer.id}/update`} selected={selected === "/update"}>
                    <ListItemIcon>
                      <EditTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("updateInformation")} />
                  </ListItemButton>
                  <Divider/>
                  <ListItemButton component={Link} to={`/pro/customer/${customer.id}/update/shipping`} selected={selected === "/update/shipping"}>
                    <ListItemIcon>
                      <LocalShippingTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("shippingAddresses")} />
                  </ListItemButton>
                  <Divider/>
                  {/*!!customer.anamnesis && (
                                        <>
                                            <ListItemButton component={Link} to={`/pro/customer/${customer.id}/anamnesis`}>
                                                <ListItemIcon>
                                                    <FeaturedPlayListTwoToneIcon color={"error"} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("watchAnamnesis")} />
                                            </ListItemButton>
                                            <Divider/>
                                        </>
                                    )}
                                    */}
                  <ListItemButton onClick={handleSendWelcomeEmail}>
                    <ListItemIcon>
                      <SendTwoToneIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("resendIdentifiers")} />
                  </ListItemButton>
                  <Divider/>
                  {!customer.consultantId && (
                    <>
                      <ListItemButton onClick={handleConsultantRedirectionModalOpen}>
                        <ListItemIcon>
                          <AccessibilityNewTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("entrustCustomerToNutritionConsultant")}
                          secondary={
                            customer.redirectionRequest
                              ? `${t('waitingRequest')}: ${customer.redirectionRequest.consultant?.firstName} ${customer.redirectionRequest.consultant?.lastName}`
                              : null
                          }
                        />
                      </ListItemButton>
                      <Divider/>
                    </>
                  )}
                  {customer.consultantId === user.id && (
                    <>
                      <ListItemButton onClick={handleRemoveConsultantRedirection}>
                        <ListItemIcon>
                          <AccessibilityNewTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("sendBackTheCustomerToTheDelegate")} />
                      </ListItemButton>
                      <Divider/>
                    </>
                  )}
                  {!!customer.consultantId && customer.consultantId !== user.id && user.certified && (
                    <>
                      <ListItemButton onClick={handleGetCustomerBack}>
                        <ListItemIcon>
                          <AccessibilityNewTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("takeTheCustomerBack")} />
                      </ListItemButton>
                      <Divider/>
                    </>
                  )}
                  {customer.role === PROSPECT_ROLE && (
                    <>
                      <ListItemButton className={classes.red} onClick={handleDeleteProspect}>
                        <ListItemIcon>
                          <DeleteOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("deleteTheProspect")} />
                      </ListItemButton>
                      <Divider/>
                    </>
                  )}
                </>
              )}
            </List>
          </Grid>
        </Grid>
      </CardContent>
      <CustomerConsultantRedirectionModal handleClose={handleConsultantRedirectionModalClose} open={isConsultantModalOpen} customer={customer}/>
    </Card>
  );
};
export default UserActionsCard;
