import React, { useEffect } from "react";
import {Trans, useTranslation} from "react-i18next";
import { useDispatch } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import {getLangPath} from "../../../../helpers/multilingual";
import {SnackbarError, SnackbarSuccess} from "../../../../helpers/snackbar";
import {history} from "../../../../helpers/store";
import useMoment from "../../../../helpers/useMoment";
import { customersActions } from "../store/actions";
import {customersService as customerService} from "../store/services";

const CustomerAnamnesesCard = ({customer}) => {

  const {t} = useTranslation();
  const moment = useMoment();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!customer.anamneses) {
      dispatch(customersActions.getCustomerAnamnesesRequest(customer.id));
    }
  }, [customer.id]);

  const handleWatchAnamnesis = (anamnesis) => () => {
    history.push(`${getLangPath()}/pro/customer/${customer.id}/anamnesis/graph/${anamnesis.id}`);
  };

  const handleSendSimplifiedAnamnesisRequestEmail = () => {

    customerService.sendSimplifiedAnamnesisRequestEmail(customer.id)
      .then(() => {
        SnackbarSuccess(t('emailSent'));
      })
      .catch(() => {
        SnackbarError(t('errorMessageAnErrorOccurred'));
      });
  };

  return (
    <Card>
      <CardContent>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justify={"space-between"}>
              <Grid item xs={6}>
                <Typography component="h3" variant={"h6"}>
                  <Trans i18nKey={"anamneses"}>Anamneses</Trans> :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align={"right"}>
                  <Button variant="outlined" onClick={handleSendSimplifiedAnamnesisRequestEmail} size={"large"} color={"primary"} disableElevation>
                    <Trans i18nKey={"requestGraphAnamnesis"}>Request anamnesis</Trans>
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!customer.anamneses || customer.anamneses.length === 0 && (
              <Typography><Trans i18nKey={"noAnamnesis"}>No anamnesis</Trans></Typography>
            )}
            {customer.anamneses && (
              <List>
                {customer.anamneses.map(anamnesis => (
                  <ListItem key={anamnesis.id} button onClick={handleWatchAnamnesis(anamnesis)}>
                    <ListItemAvatar>
                      <Avatar>
                        <FavoriteBorderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${anamnesis.firstName} ${anamnesis.lastName}`} secondary={anamnesis.sent ? `${t("sentOn", {date:moment(anamnesis.sent).format('LLL')})}` : `${t("inProgress")} ...`} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default CustomerAnamnesesCard;
