import React  from "react";
import { useSelector } from "react-redux";

import CustomerAnamnesesCard from "./components/CustomerAnamnesesCard";
import { customerSelector } from "./store/selectors";

const CustomerAnamneses = () => {

  const customer = useSelector(customerSelector());

  return (
    <CustomerAnamnesesCard customer={customer} />
  );
};
export default CustomerAnamneses;
