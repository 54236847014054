import Cookie from "../../../../helpers/cookie";

const session = Cookie.get('session');
const shippingMethod = Cookie.get('shipping-method');
const shippingAddress = Cookie.get('shipping-address');
const shippingPickupPoint = Cookie.get('shipping-pickup-point');
const activeCampaign = Cookie.get('active-campaign');

let cartItems; try { cartItems = JSON.parse(localStorage.getItem('cart')) } catch (e) { cartItems = {} }

export const initialState = {
  shop: {
    categories: {},
    categoryId: null,
    themes: {},
    themeId: null,
    brands: [],
    brandId: null,
    productsRequested: false,
    products: {},
    topProducts: [],
    catalog: [],
    search: '',
    favorites: [],
    favoritesAdded: [],
    favoritesRemoved: [],
    searchProducts: [],
    promotionCode: "",
    isSearchDrawerOpen: false,
    product: null,
    session: session || null,
    sessionError: false,
    checkout: null,
    isCheckoutProcessing: false,
    paymentMethod: {id: "", type: ""},
    elementFormReady: false,
    shopProductRequestAnsweredEmpty: false,
    fromInternalNavigation: false,
    drawers: {checkout: false, readOnlyCheckout: false, response: false, account: false},
    orders: [],
    giftCards: [],
    giftCard: [],
    activeCampaign: activeCampaign || null,
    userShipping: {
      addresses: null,
      addressesRequested: false,
    },
    cart: {
      items: cartItems || {},
      error: null,
      isModalOpened: false,
      shipping: {
        methods: [],
        methodsRequested: false,
        method: shippingMethod || null,
        address: shippingAddress || null,
        pickupPoint: shippingPickupPoint || null
      }
    },
  }
};
