import React from "react";
import AllInclusiveTwoToneIcon from '@mui/icons-material/AllInclusiveTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import SpeedTwoToneIcon from "@mui/icons-material/SpeedTwoTone";
import {Box} from "@mui/material";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";

import theme from "../../../../assets/styles/theme/theme";
import {Link} from "../../../../helpers/multilingual";
import Menu from "../../Menu/Menu";
import MenuItem from "../../Menu/MenuItem";

const MenuDrawer = ({setMobileOpen}) => (
  <div>
    <Toolbar>
      <Link to="/">
        <Box component={'img'} width={50} src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.svg`} alt="logo" sx={{width: '100%'}}/>
      </Link>
    </Toolbar>
    <Divider />
    <Menu
      sx={{
        '& .MuiListItemText-primary': {
          fontFamily: 'Open sans',
          color: 'black'
        },
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main
        }
      }}>
      <MenuItem
        title={"Home"}
        to={"/"}
        icon={<SpeedTwoToneIcon/>}
        setMobileOpen={setMobileOpen}
      />
      <MenuItem
        title={"E-shop"}
        to={"/shop"}
        icon={<AssessmentTwoToneIcon/>}
        setMobileOpen={setMobileOpen}
      />
      <MenuItem
        title={"A propos"}
        to={"/about"}
        icon={<InfoTwoToneIcon/>}
        setMobileOpen={setMobileOpen}
      />
      <MenuItem
        title={"Professionnels de la santé"}
        to={"/health-professional"}
        icon={<AllInclusiveTwoToneIcon/>}
        setMobileOpen={setMobileOpen}
      />
      <MenuItem
        title={"Blog"}
        to={"/mag"}
        icon={<MenuBookTwoToneIcon/>}
        setMobileOpen={setMobileOpen}
      />
    </Menu>
  </div>
);

export default MenuDrawer;
