import {
  AESTHETIC_MEDICINE,
  CHIROPRACTIC,
  DENTIST,
  DERMATOLOGIST,
  DIETICIAN, ENT, FUNCTIONAL_MEDICINE,
  GENERALIST,
  GYNECOLOGIST, KINE, NATUROPATH,
  NURSE, NUTRITIONIST, PSYCHOTHERAPIST, SEXOLOGIST,
  SPORT_COACH
} from "../containers/Admin/Users/store/constants";
import {
  COACH_ROLE,
  CONSULTANT_ROLE,
  DELEGATE_ROLE,
  DIRECTOR_ROLE,
  MANAGER_ROLE,
} from "../containers/Authentication/store/constants";
import {
  BPOST, DHL, FEDEX_PRIORITY,
  FEDEX_STANDARD, TAXES_FULL, TAXES_REDUCED, TAXES_SUBJECT_TO} from "../containers/Customer/Shop/store/constants";

import currencies from "./currencies";

export const NRDID = '083632d8-fa53-4a21-8a95-c46b3a4c4e88';
export const METAGENICSID = "132cdc9d-c6b0-4c03-8eb6-906e9a9ed441";
export const getShippingMethodName = [BPOST, DHL, FEDEX_STANDARD, FEDEX_PRIORITY]; // ups

export const specialities = [
  CHIROPRACTIC,
  SPORT_COACH,
  DENTIST,
  DERMATOLOGIST,
  DIETICIAN,
  GENERALIST,
  GYNECOLOGIST,
  NURSE,
  KINE,
  AESTHETIC_MEDICINE,
  FUNCTIONAL_MEDICINE,
  NATUROPATH,
  NUTRITIONIST,
  ENT,
  PSYCHOTHERAPIST,
  SEXOLOGIST
];


export const getCurrency = (country) => {
  switch(country) {

    case 'CA':
      return 'CAD';
    case 'CH':
      return 'CHF';
    case 'UK':
      return 'GBP';
    default:
      return inEurope(country) ? 'EUR' : 'USD';
  }
};

export const getPriceFR = (price, taxesType) => {
  const tax = 0.2;
  const taxReduced = 0.055;

  switch(taxesType) {
    case TAXES_REDUCED:
      return (price * (1 + taxReduced));
    case TAXES_FULL:
      return (price * (1 + tax));
    default:
      return price;
  }
};

export const getPriceBE = (price, taxesType) => {
  const tax = 0.21;
  const taxReduced = 0.06;
  switch(taxesType) {

    case TAXES_REDUCED:
      return (price * (1 + taxReduced));
    case TAXES_FULL:
      return (price * (1 + tax));
    default:
      return price;
  }
};

export const getPriceLU = (price, taxesType) => {
  const tax = 0.17;
  const taxReduced = 0.03;

  switch(taxesType) {
    case TAXES_REDUCED:
      return (price * (1 + taxReduced));
    case TAXES_FULL:
      return (price * (1 + tax));
    default:
      return price;
  }

};

export const currencyConvert = (amount, fromCurrency, toCurrency) => {

  if(fromCurrency === toCurrency) {
    return (parseFloat(amount));
  }

  let eur = parseFloat(amount) * parseFloat(currencies[fromCurrency]);
  return (eur / parseFloat(currencies[toCurrency]));
};

//export const hasTaxes = (user, shippingCountry) => !user.taxNumber || user.country === "LU" || shippingCountry === "LU";
export const hasTaxes = (user, shippingCountry) => user.country === "LU" || shippingCountry === "LU";

export const getTaxesRate = (VAT, taxesType = TAXES_REDUCED) => {

  switch (taxesType) {
    case TAXES_SUBJECT_TO : //Assujetti
      return (parseFloat(VAT.subjectTo) / 100 );
    case TAXES_REDUCED : // Reduced
      return (parseFloat(VAT.reduced) / 100 );
    case TAXES_FULL : // Full
      return (parseFloat(VAT.full) / 100 );
  }
};

export const getProductPriceWithTaxes = (product, user, shippingAddress, quantity = 1, withDiscount = false) => {
  let {price, taxesType} = product;
  if(user?.id && shippingAddress?.VAT) {
    if (user.country !== "LU") {
      if (user.taxNumber) {
        price = price * ( 1 + getTaxesRate(shippingAddress.VAT, TAXES_SUBJECT_TO)) * quantity;
        return withDiscount ? getProductPriceWithDiscount(price, product, user) : parseFloat((price).toFixed(2));
      } else {
        price = price * ( 1 + getTaxesRate(shippingAddress.VAT, taxesType)) * quantity;
        return withDiscount ? getProductPriceWithDiscount(price, product, user) : parseFloat((price).toFixed(2));
      }
    } else {
      price = price * ( 1 + getTaxesRate(shippingAddress.VAT, taxesType))  * quantity;
      return withDiscount ? getProductPriceWithDiscount(price, product, user) : parseFloat((price).toFixed(2));
    }
  } else {
    return parseFloat((price * ( 1 + getTaxesRate({reduced: 6, full : 21}, taxesType)) * quantity).toFixed(2));
  }
};

export const getProductPriceWithDiscount = (price, product, user) => {
  let {discountPercent, discountMaxPercentage} = product;
  let discountRate = 0;
  if (discountPercent) {
    discountRate = Math.min(parseFloat(discountPercent), discountMaxPercentage ? parseFloat(discountMaxPercentage) : 100);
  } else if (user?.userDiscount) {
    discountRate = Math.min(parseFloat(user?.userDiscount?.percentage), discountMaxPercentage ? parseFloat(discountMaxPercentage) : 100);
  }

  return parseFloat((price * (1 - (discountRate / 100))).toFixed(2));
};
export const getEuropeCountries = [
  'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT',
  'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'LU'
];

export const inEurope = (country) => getEuropeCountries.includes(country);

export const getDirectCommissionMultiplier = (role) => {
  switch (role) {
    case DIRECTOR_ROLE: return 0.40;
    case MANAGER_ROLE: return 0.35;
    case COACH_ROLE: return 0.30;
    case CONSULTANT_ROLE: return 0.25;
    case DELEGATE_ROLE: return 0.20;
    default: return 0;
  }
};

export const hexToRgb = (hex) => {

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
};


export const uuidToRgb = (uuid) => {
  if(!uuid) {
    return '#FFFFFF';
  }
  return hexToRgb(`#${uuid[1]}${uuid[2]}${uuid[3]}${uuid[4]}${uuid[5]}${uuid[6]}`);
};

export const chartjsBarColorGenerator = (data) => {
  let backgrounds = [], borders = [];
  data.map(() => {
    let r = Math.round(Math.random()*255);
    let g = Math.round(Math.random()*255);
    let b = Math.round(Math.random()*255);
    backgrounds.push(`rgba(${r},${g},${b},0.4)`);
    borders.push(`rgb(${r},${g},${b})`);
  });
  return {
    backgrounds: backgrounds,
    borders: borders
  };
};

export const chartjsLineColorGenerator = (min, max) => {
  let r = Math.floor(Math.random()*(max-min+1)+min);
  let g = Math.floor(Math.random()*(max-min+1)+min);
  let b = Math.floor(Math.random()*(max-min+1)+min);
  return {
    background: `rgba(${r},${g},${b},0.04)`,
    border: `rgb(${r},${g},${b})`
  };
};

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const transformCountryCode = (countryCode) => {
  switch (countryCode) {
    case "FR":
      return "France";
    case "BE":
      return "Belgique";
    case "LU":
      return "Luxembourg";
    case "MC":
      return "Monaco";
    case "AD":
      return "Andorre";
    case "AL":
      return "Albanie";
    case "DE":
      return "Allemagne";
    case "AT":
      return "Autriche";
    case "BG":
      return "Bulgarie";
    case "CY":
      return "Chypre";
    case "HR":
      return "Croatie";
    case "DK":
      return "Danemark";
    case "ES":
      return "Espagne";
    case "EE":
      return "Estonie";
    case "FI":
      return "Finlande";
    case "GR":
      return "Grèce";
    case "HU":
      return "Hongrie";
    case "IE":
      return "Irlande";
    case "IT":
      return "Italie";
    case "LV":
      return "Lettonie";
    case "LT":
      return "Lituanie";
    case "MT":
      return "Malte";
    case "NL":
      return "Pays-Bas";
    case "PL":
      return "Pologne";
    case "PT":
      return "Portugal";
    case "CZ":
      return "République Tchèque";
    case "RO":
      return "Roumanie";
    case "GB":
      return "Royaume-Uni";
    case "SK":
      return "Slovaquie";
    case "SI":
      return "Slovénie";
    case "SE":
      return "Suède";
    case "CA":
      return "Canada";
    case "CH":
      return "Suisse";
    case "UK":
      return "Royaume-Uni";
    case "US":
      return "États-Unis";
    case "other":
      return "Autre";
    default:
      return countryCode;
  }
};