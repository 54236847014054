/**
 * Create the store with dynamic reducers
 */
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {applyMiddleware, compose,createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';

import createReducer from '../reducers';
import rootSaga from '../saga';

export const history = typeof window === 'object' ? createBrowserHistory() : {};

export const hydrateState = (state) => {

  let cartItems;
  if(state) {
    try { cartItems = JSON.parse(localStorage.getItem('cart')) }
    catch (e) { cartItems = {} }
    state.shop.cart.items = cartItems || {};
  }
  return state;
};

export default function configureStore(preloadedState = {}) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(),
    preloadedState,
    composeEnhancers(...enhancers),
  );

  sagaMiddleware.run(rootSaga);

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}
