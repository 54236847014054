import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@mui/material";
import {createFilterOptions} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import {makeStyles} from '@mui/styles';

import {SnackbarFormattedError} from "../../../helpers/snackbar";
import {userService} from "../../Admin/Users/store/services";

const useStyle = makeStyles(() => ({
  textField: {},
  option: {
    width: '100%',
    borderBottom: `1px dashed ${grey[600]}`,
    '& p': {
      margin: 0,
      padding: 0,
    },
    '& span': {
      marginRight: 5,
    },
    '& .identifier': {
      margin: 0,
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      color: grey[800]
    },
  },
}));

const SearchConsultantAutocomplete = ({autoFocus, onChange, target = 'all', label = null}) => {

  const {t} = useTranslation();
  const classes = useStyle();
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {

    if(userSearch.length === 2 || (userSearch.length > 2 && !users.length)) {
      setUserSearchLoading(true);
      userService.searchUsers(userSearch, target).then(users => {
        setUsers(users);
        setUserSearchLoading(false);
      })
        .catch(e => {
          SnackbarFormattedError(e.error);
          setUserSearchLoading(false);
        });
    }
    else if(userSearch.length < 2) {
      setUsers([]);
    }
  }, [userSearch]);

  const filterOptions = createFilterOptions({
    stringify: option => `${option.company}${option.zip}${option.city}${option.firstName} ${option.lastName} ${option.firstName}`,
  });

  const handleUserSearchChange = (e) => {

    setUserSearch(e.target.value);
  };

  return (
    <Autocomplete
      style={{width: '100%'}}
      open={userSearchOpen}
      filterOptions={filterOptions}
      autoHighlight={true}
      onOpen={() => {
        setUserSearchOpen(true);
      }}
      onClose={() => {
        setUserSearchOpen(false);
      }}
      onChange={onChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={
        (option) => `${option.company ? `${option.company} - ` : ''} ${option.lastName && option.firstName ? `${option.lastName} ${option.firstName} - ` : ''} ${option.zip} ${option.city}`
      }
      renderOption={(option) => <div className={classes.option}>
        {option.company ? <p>{option.company}</p> : ``}
        {option.lastName && option.firstName ? <span>{option.lastName} {option.firstName} - </span> : ''}
        <span>{option.zip} {option.city}</span>
      </div>}
      options={users}
      noOptionsText={t('noNutritionConsultantFound')}
      loading={userSearchLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          autoFocus={autoFocus}
          placeholder={label || t('searchByIdentifierCityOrPostalCode')}
          variant="outlined"
          label={label}
          onChange={handleUserSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {userSearchLoading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default SearchConsultantAutocomplete;
