import * as actionsType from './constants';

export const shopActions = {
  getShippingAddressesRequest,
  getShippingAddressesSuccess,
  getShippingAddressesFailed,
  addShippingAddressRequest,
  addShippingAddressSuccess,
  addShippingAddressFailed,
  updateShippingAddressRequest,
  updateShippingAddressSuccess,
  updateShippingAddressFailed,
  removeShippingAddressRequest,
  removeShippingAddressSuccess,
  removeShippingAddressFailed,
  setAsDefaultShippingAddressRequest,
  setAsDefaultShippingAddressSuccess,
  setAsDefaultShippingAddressFailed,
  setAsDefaultBillingAddressRequest,
  setAsDefaultBillingAddressSuccess,
  setAsDefaultBillingAddressFailed,
  getProductsRequest,
  getProductsSuccess,
  getProductsFail,
  setCurrentPage,
  getTopProductsRequest,
  getTopProductsSuccess,
  getTopProductsFail,
  getCatalogProductsRequest,
  getCatalogProductsSuccess,
  getCatalogProductsFail,
  searchProductsRequest,
  searchProductsSuccess,
  searchProductsFail,
  searchDrawerOpen,
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFail,
  getThemesRequest,
  getThemesSuccess,
  getThemesFail,
  getBrandsRequest,
  getBrandsSuccess,
  getBrandsFail,
  getCategoryRequest,
  getCategorySuccess,
  getCategoryFail,
  getProductRequest,
  getProductSuccess,
  getProductFail,
  addToCart,
  setCartItemQuantity,
  //DEPRECATED initCartRequest,
  //DEPRECATED initCartSuccess,
  //DEPRECATED initCartFail,
  setCartFromOrderRequest,
  setCartFromOrderSuccess,
  setCartFromOrderFail,
  openCartModal,
  closeCartModal,
  signInSessionRequest,
  signInSessionSuccess,
  signInSessionFail,
  signInSessionByDirectLinkRequest,
  signInSessionByDirectLinkSuccess,
  signInSessionByDirectLinkFail,
  updateSessionRequest,
  setShippingAddressSelected,
  selectShippingMethod,
  selectShippingPickupPointMethod,
  useWalletAmount,
  getShippingMethodsRequest,
  getShippingMethodsSuccess,
  getShippingMethodsFail,
  getCheckoutRequest,
  getCheckoutSuccess,
  getCheckoutFail,
  cleanCheckout,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFail,
  getGiftCardsRequest,
  getGiftCardsSuccess,
  getGiftCardsFail,
  selectGiftCard,
  deleteGiftCard,
  setCheckoutProcessing,
  setElementFormReady,
  setPaymentMethod,
  SetDrawersOpen,
  setPromotionCode,
  setActiveCampaign,
  addToFavoritesRequest,
  addToFavoritesSuccess,
  removeFromFavoritesRequest,
  removeFromFavoritesSuccess,
  setMultisafepayPaymentMethod,
  setShopProductRequestAnsweredEmpty,
  setFromInternalNavigation
};

export function getShippingAddressesRequest() {

  return {
    type: actionsType.GET_SHIPPING_ADDRESSES_REQUEST
  };
}

export function getShippingAddressesSuccess(shippingAddresses) {

  return {
    type: actionsType.GET_SHIPPING_ADDRESSES_SUCCESS,
    shippingAddresses
  };
}

export function getShippingAddressesFailed() {

  return {
    type: actionsType.GET_SHIPPING_ADDRESSES_FAILED
  };
}

export function addShippingAddressRequest(shippingAddress) {

  return {
    type: actionsType.ADD_SHIPPING_ADDRESS_REQUEST,
    shippingAddress
  };
}

export function addShippingAddressSuccess(shippingAddress) {

  return {
    type: actionsType.ADD_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress
  };
}

export function addShippingAddressFailed() {

  return {
    type: actionsType.ADD_SHIPPING_ADDRESS_FAILED
  };
}

export function updateShippingAddressRequest(shippingAddressId, shippingAddress) {

  return {
    type: actionsType.UPDATE_SHIPPING_ADDRESS_REQUEST,
    shippingAddressId, shippingAddress
  };
}

export function updateShippingAddressSuccess(shippingAddress) {

  return {
    type: actionsType.UPDATE_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress
  };
}

export function updateShippingAddressFailed() {

  return {
    type: actionsType.UPDATE_SHIPPING_ADDRESS_FAILED
  };
}

export function removeShippingAddressRequest(shippingAddress) {

  return {
    type: actionsType.REMOVE_SHIPPING_ADDRESS_REQUEST,
    shippingAddress
  };
}

export function removeShippingAddressSuccess(shippingAddress) {

  return {
    type: actionsType.REMOVE_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress
  };
}

export function removeShippingAddressFailed() {

  return {
    type: actionsType.REMOVE_SHIPPING_ADDRESS_FAILED
  };
}

export function setAsDefaultShippingAddressRequest(shippingAddress) {

  return {
    type: actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST,
    shippingAddress
  };
}

export function setAsDefaultShippingAddressSuccess(shippingAddress) {

  return {
    type: actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress
  };
}

export function setAsDefaultShippingAddressFailed() {

  return {
    type: actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_FAILED
  };
}

export function setAsDefaultBillingAddressRequest(billingAddress) {

  return {
    type: actionsType.SET_AS_DEFAULT_BILLING_ADDRESS_REQUEST,
    billingAddress
  };
}

export function setAsDefaultBillingAddressSuccess(billingAddress) {

  return {
    type: actionsType.SET_AS_DEFAULT_BILLING_ADDRESS_SUCCESS,
    billingAddress
  };
}

export function setAsDefaultBillingAddressFailed() {

  return {
    type: actionsType.SET_AS_DEFAULT_BILLING_ADDRESS_FAILED
  };
}

export function getProductsRequest(lang, country, section = 'category', sectionId = 'all', offset = "all", limit = "all") {

  return {
    type: actionsType.GET_PRODUCTS_REQUEST,
    lang,
    country,
    section,
    sectionId,
    offset,
    limit,
    //promo
  };
}

export function setCurrentPage(lang, country, section = 'category', sectionId = 'all', offset = "all") {
  return {
    type: actionsType.SET_CURRENT_PAGE,
    lang,
    country,
    section,
    sectionId,
    offset
  };
}

export function getProductsSuccess(lang, country, section, sectionId, products) {

  return {
    type: actionsType.GET_PRODUCTS_SUCCESS,
    lang,
    country,
    section,
    sectionId,
    products
  };
}

export function getProductsFail() {

  return {
    type: actionsType.GET_PRODUCTS_FAIL,
  };
}

export function getTopProductsRequest(lang, country) {

  return {
    type: actionsType.GET_TOP_PRODUCTS_REQUEST,
    lang,
    country
  };
}

export function getTopProductsSuccess(products) {

  return {
    type: actionsType.GET_TOP_PRODUCTS_SUCCESS,
    products
  };
}

export function getTopProductsFail() {

  return {
    type: actionsType.GET_TOP_PRODUCTS_FAIL,
  };
}

export function getCatalogProductsRequest(lang) {

  return {
    type: actionsType.GET_CATALOG_PRODUCTS_REQUEST,
    lang,
  };
}

export function getCatalogProductsSuccess(products) {

  return {
    type: actionsType.GET_CATALOG_PRODUCTS_SUCCESS,
    products
  };
}

export function getCatalogProductsFail() {

  return {
    type: actionsType.GET_CATALOG_PRODUCTS_FAIL,
  };
}

export function searchProductsRequest(lang, country, search) {

  return {
    type: actionsType.SEARCH_PRODUCTS_REQUEST,
    lang,
    country,
    search
  };
}

export function searchProductsSuccess(country, products) {

  return {
    type: actionsType.SEARCH_PRODUCTS_SUCCESS,
    country,
    products
  };
}

export function searchProductsFail() {

  return {
    type: actionsType.SEARCH_PRODUCTS_FAIL,
  };
}

export function searchDrawerOpen(isOpen) {

  return {
    type: actionsType.SEARCH_DRAWER_OPEN,
    isOpen
  };
}

export function getCategoriesRequest(lang) {

  return {
    type: actionsType.GET_CATEGORIES_REQUEST,
    lang
  };
}

export function getCategoriesSuccess(lang, categories) {

  return {
    type: actionsType.GET_CATEGORIES_SUCCESS,
    lang,
    categories
  };
}

export function getCategoriesFail() {

  return {
    type: actionsType.GET_CATEGORIES_FAIL,
  };
}

export function getThemesRequest(lang) {

  return {
    type: actionsType.GET_THEMES_REQUEST,
    lang
  };
}

export function getThemesSuccess(lang, themes) {

  return {
    type: actionsType.GET_THEMES_SUCCESS,
    lang,
    themes
  };
}

export function getThemesFail() {

  return {
    type: actionsType.GET_THEMES_FAIL,
  };
}

export function getBrandsRequest() {

  return {
    type: actionsType.GET_BRANDS_REQUEST,
  };
}

export function getBrandsSuccess(brands) {

  return {
    type: actionsType.GET_BRANDS_SUCCESS,
    brands
  };
}

export function getBrandsFail() {

  return {
    type: actionsType.GET_BRANDS_FAIL,
  };
}

export function getCategoryRequest(lang, categoryId) {

  return {
    type: actionsType.GET_CATEGORY_REQUEST,
    categoryId
  };
}

export function getCategorySuccess(category) {

  return {
    type: actionsType.GET_CATEGORY_SUCCESS,
    category
  };
}

export function getCategoryFail() {

  return {
    type: actionsType.GET_CATEGORY_FAIL,
  };
}

export function getProductRequest(lang, country, productId, allowHidden = false) {

  return {
    type: actionsType.GET_PRODUCT_REQUEST,
    lang,
    country,
    productId,
    allowHidden
  };
}

export function getProductSuccess(product) {

  return {
    type: actionsType.GET_PRODUCT_SUCCESS,
    product
  };
}

export function getProductFail() {

  return {
    type: actionsType.GET_PRODUCT_FAIL,
  };
}

export function addToCart(item, quantity) {

  return {
    type: actionsType.CART_ADD_ITEM,
    item,
    quantity
  };
}

export function setCartItemQuantity(item, quantity) {

  return {
    type: actionsType.CART_SET_ITEM_QUANTITY,
    item,
    quantity
  };
}

export function setCartFromOrderRequest(orderId, country) {
  return {
    type: actionsType.CART_SET_FROM_ORDER_REQUEST,
    orderId,
    country
  };
}

export function setCartFromOrderSuccess(lang, country, items) {
  return {
    type: actionsType.CART_SET_FROM_ORDER_SUCCESS,
    lang, country, items
  };
}

export function setCartFromOrderFail() {
  return {
    type: actionsType.CART_SET_FROM_ORDER_FAIL
  };
}

export function openCartModal() {

  return {
    type: actionsType.CART_OPEN_MODAL
  };
}

export function closeCartModal() {

  return {
    type: actionsType.CART_CLOSE_MODAL
  };
}

export function signInSessionRequest(consultantId, countryCode, redirect = null) {

  return {
    type: actionsType.SIGNIN_SESSION_REQUEST,
    consultantId,
    countryCode,
    redirect
  };
}

export function signInSessionSuccess(session) {

  return {
    type: actionsType.SIGNIN_SESSION_SUCCESS,
    session
  };
}

export function signInSessionFail() {

  return {
    type: actionsType.SIGNIN_SESSION_FAIL,
  };
}

export function signInSessionByDirectLinkRequest(directLink, countryCode = null) {

  return {
    type: actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST,
    directLink, countryCode
  };
}

export function signInSessionByDirectLinkSuccess(session) {

  return {
    type: actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_SUCCESS,
    session
  };
}

export function signInSessionByDirectLinkFail() {

  return {
    type: actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_FAIL,
  };
}

export function updateSessionRequest(updating) {

  return {
    type: actionsType.UPDATE_SESSION_REQUEST,
    updating
  };
}

export function setShippingAddressSelected(shippingAddress) {

  return {
    type: actionsType.SET_SHIPPING_ADDRESS_SELECTED,
    shippingAddress,
  };
}

export function selectShippingMethod(shippingMethod) {

  return {
    type: actionsType.SET_SHIPPING_METHOD_SELECTED,
    shippingMethod,
  };
}

export function selectShippingPickupPointMethod(shippingMethod, pickupPoint) {

  return {
    type: actionsType.SET_SHIPPING_PICKUP_POINT_SELECTED,
    shippingMethod, pickupPoint,
  };
}

export function useWalletAmount(useWallet) {

  return {
    type: actionsType.SET_USE_WALLET_SELECTED,
    useWallet,
  };
}

export function getShippingMethodsRequest(country, zip, weight, orderPrice) {

  return {
    type: actionsType.GET_SHIPPING_METHODS_REQUEST,
    country,
    zip,
    weight,
    orderPrice
  };
}

export function getShippingMethodsSuccess(shippingMethods) {

  return {
    type: actionsType.GET_SHIPPING_METHODS_SUCCESS,
    shippingMethods
  };
}

export function getShippingMethodsFail() {

  return {
    type: actionsType.GET_SHIPPING_METHODS_FAIL,
  };
}

export function getCheckoutRequest(lang, shippingMethodId, shippingAddressId, shippingPickupPoint, items, useWallet, giftCard, promotionCode) {

  return {
    type: actionsType.GET_CHECKOUT_REQUEST,
    lang,
    shippingMethodId,
    shippingAddressId,
    shippingPickupPoint,
    items,
    useWallet,
    giftCard,
    promotionCode
  };
}

export function getCheckoutSuccess(checkout) {

  return {
    type: actionsType.GET_CHECKOUT_SUCCESS,
    checkout
  };
}

export function getCheckoutFail() {

  return {
    type: actionsType.GET_CHECKOUT_FAIL,
  };
}

export function cleanCheckout() {

  return {
    type: actionsType.CLEAN_CHECKOUT
  };
}

export function getOrdersRequest(lang) {

  return {
    type: actionsType.GET_ORDERS_REQUEST,
    lang
  };
}

export function getOrdersSuccess(orders) {

  return {
    type: actionsType.GET_ORDERS_SUCCESS,
    orders
  };
}

export function getOrdersFail() {

  return {
    type: actionsType.GET_ORDERS_FAIL,
  };
}

export function getGiftCardsRequest() {

  return {
    type: actionsType.GET_GIFT_CARDS_REQUEST
  };
}

export function getGiftCardsSuccess(giftCards) {

  return {
    type: actionsType.GET_GIFT_CARDS_SUCCESS,
    giftCards
  };
}

export function getGiftCardsFail() {

  return {
    type: actionsType.GET_GIFT_CARDS_FAIL,
  };
}

export function selectGiftCard(giftCard) {

  return {
    type: actionsType.SELECT_GIFT_CARD,
    giftCard
  };
}

export function deleteGiftCard(giftCard) {

  return {
    type: actionsType.DELETE_GIFT_CARD,
    giftCard
  };
}

export function SetDrawersOpen(drawer, bool) {

  return {
    type: actionsType.SET_DRAWERS_OPEN,
    drawer, bool
  };
}

export function setCheckoutProcessing(processing) {

  return {
    type: actionsType.SET_CHECKOUT_PROCESSING,
    processing
  };
}

export function setElementFormReady(ready) {

  return {
    type: actionsType.SET_ELEMENT_FORM_READY,
    ready
  };
}

export function setPaymentMethod(paymentMethod) {

  return {
    type: actionsType.SET_PAYMENT_METHOD,
    paymentMethod
  };
}

export function setPromotionCode(promotionCode) {

  return {
    type: actionsType.SET_PROMOTION_CODE,
    promotionCode
  };
}

export function setActiveCampaign(data) {

  return {
    type: actionsType.SET_ACTIVE_CAMPAIGN,
    data
  };
}

export function setMultisafepayPaymentMethod(method) {

  return {
    type: actionsType.SET_MULTISAFEPAY_PAYMENT_METHOD,
    method
  };
}

export function addToFavoritesRequest(productId) {

  return {
    type: actionsType.ADD_TO_FAVORITES_REQUEST,
    productId
  };
}

export function addToFavoritesSuccess(productId) {

  return {
    type: actionsType.ADD_TO_FAVORITES_SUCCESS,
    productId
  };
}

export function removeFromFavoritesRequest(productId) {

  return {
    type: actionsType.REMOVE_FROM_FAVORITES_REQUEST,
    productId
  };
}

export function removeFromFavoritesSuccess(productId) {

  return {
    type: actionsType.REMOVE_FROM_FAVORITES_SUCCESS,
    productId
  };
}

export function setShopProductRequestAnsweredEmpty(value) {

  return {
    type: actionsType.SET_SHOP_PRODUCT_REQUEST_ANSWERED_EMPTY,
    value
  };
}

export function setFromInternalNavigation(value) {

  return {
    type: actionsType.SET_FROM_INTERNAL_NAVIGATION,
    value
  };
}
