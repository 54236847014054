export const SHIPPING_FREE_THRESHOLD_AMOUNT = {
  EUR: 60,
  CHF: 65,
  GBP: 55,
  USD: 120,
  CAD: 120,
};

export const TAXES_NONE    = 0;
export const TAXES_REDUCED = 1;
export const TAXES_FULL    = 2;
export const TAXES_SUBJECT_TO    = 3;

export const BPOST = 'bpost';
export const FEDEX_PRIORITY = 'FedEx - Priority';
export const DHL = 'DHL Express';
export const FEDEX_STANDARD = 'FedEx - Standard';
export const NONE = 'Aucun';

export const GET_SHIPPING_ADDRESSES_REQUEST = 'SHOP/GET_SHIPPING_ADDRESSES_REQUEST';
export const GET_SHIPPING_ADDRESSES_SUCCESS = 'SHOP/GET_SHIPPING_ADDRESSES_SUCCESS';
export const GET_SHIPPING_ADDRESSES_FAILED  = 'SHOP/GET_SHIPPING_ADDRESSES_FAILED';
export const REMOVE_SHIPPING_ADDRESS_REQUEST = 'SHOP/REMOVE_SHIPPING_ADDRESSES_REQUEST';
export const REMOVE_SHIPPING_ADDRESS_SUCCESS = 'SHOP/REMOVE_SHIPPING_ADDRESSES_SUCCESS';
export const REMOVE_SHIPPING_ADDRESS_FAILED  = 'SHOP/REMOVE_SHIPPING_ADDRESSES_FAILED';
export const ADD_SHIPPING_ADDRESS_REQUEST = 'SHOP/ADD_SHIPPING_ADDRESSES_REQUEST';
export const ADD_SHIPPING_ADDRESS_SUCCESS = 'SHOP/ADD_SHIPPING_ADDRESSES_SUCCESS';
export const ADD_SHIPPING_ADDRESS_FAILED  = 'SHOP/ADD_SHIPPING_ADDRESSES_FAILED';
export const UPDATE_SHIPPING_ADDRESS_REQUEST = 'SHOP/UPDATE_SHIPPING_ADDRESSES_REQUEST';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'SHOP/UPDATE_SHIPPING_ADDRESSES_SUCCESS';
export const UPDATE_SHIPPING_ADDRESS_FAILED  = 'SHOP/UPDATE_SHIPPING_ADDRESSES_FAILED';
export const SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST = 'SHOP/SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST';
export const SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS = 'SHOP/SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS';
export const SET_AS_DEFAULT_SHIPPING_ADDRESS_FAILED = 'SHOP/SET_AS_DEFAULT_SHIPPING_ADDRESS_FAILED';
export const SET_AS_DEFAULT_BILLING_ADDRESS_REQUEST = 'SHOP/SET_AS_DEFAULT_BILLING_ADDRESS_REQUEST';
export const SET_AS_DEFAULT_BILLING_ADDRESS_SUCCESS = 'SHOP/SET_AS_DEFAULT_BILLING_ADDRESS_SUCCESS';
export const SET_AS_DEFAULT_BILLING_ADDRESS_FAILED = 'SHOP/SET_AS_DEFAULT_BILLING_ADDRESS_FAILED';

export const GET_CATEGORIES_REQUEST = 'SHOP/GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'SHOP/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL    = 'SHOP/GET_CATEGORIES_FAIL';
export const GET_THEMES_REQUEST = 'SHOP/GET_THEMES_REQUEST';
export const GET_THEMES_SUCCESS = 'SHOP/GET_THEMES_SUCCESS';
export const GET_THEMES_FAIL    = 'SHOP/GET_THEMES_FAIL';
export const GET_BRANDS_REQUEST = 'SHOP/GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'SHOP/GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAIL    = 'SHOP/GET_BRANDS_FAIL';
export const GET_CATEGORY_REQUEST = 'SHOP/GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'SHOP/GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAIL    = 'SHOP/GET_CATEGORY_FAIL';

export const GET_PRODUCTS_REQUEST = 'SHOP/GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'SHOP/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL    = 'SHOP/GET_PRODUCTS_FAIL';

export const SET_CURRENT_PAGE    = 'SHOP/GET_PRODUCTS_FAIL';

export const GET_TOP_PRODUCTS_REQUEST = 'SHOP/GET_TOP_PRODUCTS_REQUEST';
export const GET_TOP_PRODUCTS_SUCCESS = 'SHOP/GET_TOP_PRODUCTS_SUCCESS';
export const GET_TOP_PRODUCTS_FAIL    = 'SHOP/GET_TOP_PRODUCTS_FAIL';

export const GET_CATALOG_PRODUCTS_REQUEST = 'SHOP/GET_CATALOG_PRODUCTS_REQUEST';
export const GET_CATALOG_PRODUCTS_SUCCESS = 'SHOP/GET_CATALOG_PRODUCTS_SUCCESS';
export const GET_CATALOG_PRODUCTS_FAIL    = 'SHOP/GET_CATALOG_PRODUCTS_FAIL';

export const SEARCH_PRODUCTS_REQUEST = 'SHOP/SEARCH_PRODUCTS_REQUEST';
export const SEARCH_PRODUCTS_SUCCESS = 'SHOP/SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAIL    = 'SHOP/SEARCH_PRODUCTS_FAIL';
export const SEARCH_DRAWER_OPEN      = 'SHOP/SEARCH_DRAWER_OPEN';

export const GET_PRODUCT_REQUEST = 'SHOP/GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'SHOP/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL    = 'SHOP/GET_PRODUCT_FAIL';

export const CART_ADD_ITEM               = 'SHOP/CART_ADD_ITEM';
export const CART_SET_ITEM_QUANTITY      = 'SHOP/CART_SET_ITEM_QUANTITY';
export const CART_OPEN_MODAL             = 'SHOP/CART_OPEN_MODAL';
export const CART_CLOSE_MODAL            = 'SHOP/CART_CLOSE_MODAL';
export const CART_SET_FROM_ORDER_REQUEST = 'SHOP/CART_SET_FROM_ORDER_REQUEST';
export const CART_SET_FROM_ORDER_SUCCESS = 'SHOP/CART_SET_FROM_ORDER_SUCCESS';
export const CART_SET_FROM_ORDER_FAIL    = 'SHOP/CART_SET_FROM_ORDER_FAIL';

export const SIGNIN_SESSION_REQUEST = 'SHOP/SIGNIN_SESSION_REQUEST';
export const SIGNIN_SESSION_SUCCESS = 'SHOP/SIGNIN_SESSION_SUCCESS';
export const SIGNIN_SESSION_FAIL    = 'SHOP/SIGNIN_SESSION_FAIL';
export const UPDATE_SESSION_REQUEST = 'SHOP/UPDATE_SESSION_REQUEST';
export const SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST = 'SHOP/SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST';
export const SIGNIN_SESSION_BY_DIRECT_LINK_SUCCESS = 'SHOP/SIGNIN_SESSION_BY_DIRECT_LINK_SUCCESS';
export const SIGNIN_SESSION_BY_DIRECT_LINK_FAIL    = 'SHOP/SIGNIN_SESSION_BY_DIRECT_LINK_FAIL';

export const SET_SHIPPING_ADDRESS_SELECTED      = 'SHOP/SET_SHIPPING_ADDRESS_SELECTED';
export const SET_SHIPPING_METHOD_SELECTED       = 'SHOP/SET_SHIPPING_METHOD_SELECTED';
export const SET_SHIPPING_PICKUP_POINT_SELECTED = 'SHOP/SET_SHIPPING_PICKUP_POINT_SELECTED';
export const SET_USE_WALLET_SELECTED            = 'SHOP/SET_USE_WALLET_SELECTED';

export const GET_SHIPPING_METHODS_REQUEST = 'SHOP/GET_SHIPPING_METHODS_REQUEST';
export const GET_SHIPPING_METHODS_SUCCESS = 'SHOP/GET_SHIPPING_METHODS_SUCCESS';
export const GET_SHIPPING_METHODS_FAIL    = 'SHOP/GET_SHIPPING_METHODS_FAIL';

export const GET_CHECKOUT_REQUEST = 'SHOP/GET_CHECKOUT_DETAILS_REQUEST';
export const GET_CHECKOUT_SUCCESS = 'SHOP/GET_CHECKOUT_DETAILS_SUCCESS';
export const GET_CHECKOUT_FAIL    = 'SHOP/GET_CHECKOUT_DETAILS_FAIL';

export const CLEAN_CHECKOUT = 'SHOP/CLEAN_CHECKOUT';

export const GET_ORDERS_REQUEST = 'SHOP/GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'SHOP/GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL    = 'SHOP/GET_ORDERS_FAIL';

export const GET_GIFT_CARDS_REQUEST = "SHOP/GET_GIFT_CARDS_REQUEST";
export const GET_GIFT_CARDS_SUCCESS = "SHOP/GET_GIFT_CARDS_SUCCESS";
export const GET_GIFT_CARDS_FAIL    = "SHOP/GET_GIFT_CARDS_FAIL";
export const SELECT_GIFT_CARD       = "SHOP/SELECT_GIFT_CARD";
export const DELETE_GIFT_CARD       = "SHOP/DELETE_GIFT_CARD";


export const SET_DRAWERS_OPEN              = "SHOP/SET_DRAWERS_OPEN";
export const SET_CHECKOUT_PROCESSING       = "SHOP/SET_CHECKOUT_PROCESSING";
export const SET_ELEMENT_FORM_READY        = "SHOP/SET_ELEMENT_FORM_READY";
export const SET_PAYMENT_METHOD            = "SHOP/SET_PAYMENT_METHOD";

export const SET_PROMOTION_CODE            = "SHOP/SET_PROMOTION_CODE";

export const SET_ACTIVE_CAMPAIGN           = "SHOP/SET_ACTIVE_CAMPAIGN";

export const SET_MULTISAFEPAY_PAYMENT_METHOD    = "SHOP/SET_MULTISAFEPAY_PAYMENT_METHOD";

export const ADD_TO_FAVORITES_REQUEST = "SHOP/ADD_TO_FAVORITES_REQUEST";
export const ADD_TO_FAVORITES_SUCCESS = "SHOP/ADD_TO_FAVORITES_SUCCESS";
export const ADD_TO_FAVORITES_FAIL    = "SHOP/ADD_TO_FAVORITES_FAIL";

export const REMOVE_FROM_FAVORITES_REQUEST = "SHOP/REMOVE_FROM_FAVORITES_REQUEST";
export const REMOVE_FROM_FAVORITES_SUCCESS = "SHOP/REMOVE_FROM_FAVORITES_SUCCESS";
export const REMOVE_FROM_FAVORITES_FAIL    = "SHOP/REMOVE_FROM_FAVORITES_FAIL";

export const SET_SHOP_PRODUCT_REQUEST_ANSWERED_EMPTY = "SHOP/SET_SHOP_PRODUCT_REQUEST_ANSWERED_EMPTY";
export const SET_FROM_INTERNAL_NAVIGATION = "SHOP/SET_FROM_INTERNAL_NAVIGATION";
