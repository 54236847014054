import React, {useEffect} from "react";
import Carousel from 'react-grid-carousel';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Box, Paper, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

import theme from "../../../../assets/styles/theme/theme";
import {shopActions} from "../store/actions";
import {sessionSelector, shopProductsLoaderSelector, shopTopProductsSelector} from "../store/selectors";

import Item from "./Item";
const TopSalesProducts = () => {

  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const session = useSelector(sessionSelector);
  const loader = useSelector(shopProductsLoaderSelector);
  const topProducts = useSelector(shopTopProductsSelector);
  const isUpXl = useMediaQuery(theme => theme.breakpoints.up("xl"));
  const isUpLg = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const isUpMd = useMediaQuery(theme => theme.breakpoints.up("md"));

  useEffect(() => {
    dispatch(shopActions.getTopProductsRequest(i18n.language, session?.country || 'LU'));
  }, [i18n.language, session?.country]);

  if(loader) {
    return (
      <Box>
        <CircularProgress/>
      </Box>
    );
  }

  if(!topProducts?.length > 0) return false;

  return (
    <Box>
      <Paper
        sx={{
          backgroundColor: theme.palette.primary.main,
          maxWidth: '290px',
          borderRadius: 0,
          textAlign: 'right',
          padding: '4px 8px',
          marginBottom: '40px',
          color: '#fff',
        }}>
        <Typography sx={{
          fontSize: '24px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat',
        }}>
          LES TOP VENTES
        </Typography>
      </Paper>
      <Container maxWidth={'xxl'} sx={{
        '& .bghjHX': {
          padding: '20px 0',
        }
      }}>
        <Carousel cols={isUpXl ? 5 : isUpLg ? 4 : isUpMd ? 4 : 3} rows={1} gap={20} loop={true}>
          {topProducts.map((product) => (
            <Carousel.Item key={product.id}>
              <Item product={product} session={session}/>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};

export default TopSalesProducts;
