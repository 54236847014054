import axios  from '../../../../helpers/axios';
import {getGeolocationCountryCode} from "../helpers/helper";

export const shopService = {
  getShippingAddresses,
  addShippingAddress,
  updateShippingAddress,
  removeShippingAddress,
  setAsDefaultShippingAddress,
  setAsDefaultBillingAddress,
  getProducts,
  getTopProducts,
  getProProducts,
  getCatalogProducts,
  searchProducts,
  getCategories,
  getCategory,
  getThemes,
  getBrands,
  getProduct,
  signInSession,
  signInSessionByDirectLink,
  getCheckout,
  getPickupPoints,
  checkoutPayment,
  checkoutValidate,
  getProductsByIds,
  getProductsByOrderId,
  getShippingMethods,
  getOrders,
  getOrderStatus,
  saveOrder,
  getReviewsTodo,
  addReview,
  getProductReviews,
  getGiftCards,
  getGiftCardByCode,
  deletePaymentMethod,
  calculateOrderPrice,
  findAvailablePromotion,
  findUserAvailablePromotion,
  getUnpaidIntent,
  setCartLog,
  updateOrderSavePaymentMethod,
  getOrderById,
  getApiToken,
  createMultisafepayOrder,
  createAdminMultisafepayOrder,
  deleteMultisafepayOrder,
  addToFavorites,
  removeFromFavorites,
  findCart
};

function getShippingAddresses() {

  return new Promise((resolve, reject) => {

    axios.get('/user/shippingAddresses')
      .then(({shippingAddresses}) => {
        resolve({shippingAddresses});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addShippingAddress(shippingAddress) {

  return new Promise((resolve, reject) => {

    axios.post('/user/shippingAddress', shippingAddress)
      .then(({shippingAddress}) => {
        resolve({shippingAddress});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateShippingAddress(id, shippingAddress) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/shippingAddress/${id}`, shippingAddress)
      .then(({shippingAddress}) => {
        resolve({shippingAddress});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeShippingAddress(shippingAddress) {

  return new Promise((resolve, reject) => {

    axios.delete(`/user/shippingAddress/${shippingAddress.id}`)
      .then(() => {
        resolve({shippingAddress});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setAsDefaultShippingAddress(shippingAddress) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/shippingAddress/${shippingAddress.id}/default`)
      .then(() => {
        resolve({shippingAddress});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setAsDefaultBillingAddress(billingAddress) {

  return new Promise((resolve, reject) => {

    axios.patch(`/user/shippingAddress/${billingAddress.id}/defaultBilling`)
      .then(() => {
        resolve({billingAddress});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProducts(lang, country, section = 'category', sectionId = 'all', offset = "all", limit = "all") {

  return new Promise((resolve, reject) => {
    //let path = `/${section}`;
    //
    //if(sectionId && sectionId !== 'all') {
    //  path = `/${section}/${sectionId}`;
    //}
    axios.get(`/shop/products/${lang}/${country}/${section}/${sectionId}/${offset}/${limit}`)
      .then(({products, next}) => {
        resolve({products, next});
      })
      .catch((e) => {
        reject(e.response.data);
      });


  });
}

function getTopProducts(lang, country) {

  return new Promise((resolve, reject) => {
    axios.get(`/shop/products/top/${lang}/${country}`)
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });


  });
}

function getProProducts(lang, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/products/${lang}/${country}/pro`)
      .then(({products, next}) => {
        resolve({products, next});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCatalogProducts(lang) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/products/${lang}/LU/all`)
      .then(({products}) => {
        resolve({products});
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductsByIds(lang, country, ids) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/products/${lang}/${country}`, {ids: ids})
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductsByOrderId(lang, orderId, country) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/products/${lang}/${country}/order/${orderId}`)
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function searchProducts(lang, countryCode = "FR", search, adminWithoutRestriction) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop${adminWithoutRestriction ? '/admin' : ''}/products/${lang}/${countryCode}/search/${search}`)
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategories(lang) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/categories/${lang}`)
      .then(({categories}) => {
        resolve(categories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategory(lang, categoryId) {

  return new Promise((resolve, reject) => {

    if(categoryId === 'all') {
      resolve(null);
      return;
    }
    axios.get(`/shop/category/${lang}/${categoryId}`)
      .then(({category}) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getThemes(lang) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/themes/${lang}`)
      .then(({themes}) => {
        resolve(themes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBrands() {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/brands`)
      .then(({brands}) => {
        resolve(brands);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProduct(lang, countryCode, productId, allowHidden = false) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/product/${lang}/${countryCode}/${productId}${allowHidden ? `/allow/hidden` : ''}`)
      .then(({product}) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

async function signInSession(consultantId, countryCode) {

  axios.post(`/shop/session`, {consultantId, countryCode: await getGeolocationCountryCode(countryCode)})
    .then(({session}) => (session))
    .catch((e) => (e.response.data));
}

async function signInSessionByDirectLink(directLink, countryCode) {
  axios.post(`/shop/link/session`, {directLink, countryCode: await getGeolocationCountryCode(countryCode)})
    .then(({session}) => (session))
    .catch((e) => (e.response.data));
}
//Old route for stripe payment intent
//function getCheckout(lang, shippingMethodId, shippingAddressId, shippingPickupPoint, items, useWallet, giftCard, promotionCode) {
//
//  return new Promise((resolve, reject) => {
//
//    axios.post(`/shop/checkout`, {
//      lang,
//      shippingMethodId,
//      shippingAddressId,
//      shippingPickupPoint,
//      items: Object.keys(items).map(key => ({id: items[key].id, quantity:items[key].quantity})),
//      useWallet,
//      giftCard,
//      promotionCode
//    })
//      .then(({checkout}) => {
//        resolve(checkout);
//      })
//      .catch((e) => {
//        reject(e.response.data);
//      });
//  });
//}
//
function getCheckout(lang, shippingMethodId, shippingAddressId, shippingPickupPoint, items, useWallet, giftCard, promotionCode) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/multisafepay/checkout`, {
      lang,
      shippingMethodId,
      shippingAddressId,
      shippingPickupPoint,
      items: Object.keys(items).map(key => ({id: items[key].id, quantity:items[key].quantity})),
      useWallet,
      giftCard,
      promotionCode
    })
      .then(({checkout}) => {
        resolve(checkout);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function createMultisafepayOrder(data) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/multisafepay/paymentIntent`,{data})
      .then(({response}) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function createAdminMultisafepayOrder(data) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/admin/multisafepay/paymentIntent`,{data})
      .then(({response}) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deleteMultisafepayOrder(orderId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/shop/multisafepay/paymentIntent/${orderId}`)
      .then(({response}) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getApiToken() {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/multisafepay/apiToken`)
      .then(({token}) => {
        resolve(token);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPickupPoints(shippingAddressId, userId, admin, type) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/pickupPoints/${shippingAddressId}/${type}${userId ? `/${userId}` : ''}`)
      .then(({pickupPoints}) => {
        resolve(pickupPoints);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function checkoutPayment(payment) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/stripe/success`, {
      payment
    })
      .then(({invoice}) => {
        resolve(invoice);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function checkoutValidate(order) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/order/validate`, {
      id: order.id
    })
      .then(({invoice}) => {
        resolve(invoice);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUnpaidIntent(orderId) {

  return new Promise((resolve, reject) => {
    axios.get(`/shop/order/${orderId}/intent`)
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}


function saveOrder(orderId) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/order/${orderId}/save`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getShippingMethods(country, zip, weight, price = 0) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/shipping/methods/user/${country}/${zip}/weight/${weight}/price/${price}`)
      .then(({shippingMethods}) => {
        resolve(shippingMethods);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrders(lang) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/orders/${lang}`)
      .then(({orders}) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrderById(orderId) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/order/${orderId}`)
      .then(({order}) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrderStatus(orderId, withoutRestriction= "0") {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/order/${orderId}/status/${withoutRestriction === "1" ? withoutRestriction : "0"}`)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getReviewsTodo(lang) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/products/${lang}/reviews/todo`)
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addReview(productId, lang, rating, review) {

  return new Promise((resolve, reject) => {

    axios.put(`/shop/product/${lang}/${productId}/review`, {rating, review})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductReviews(productId, offset = 0) {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/product/${productId}/reviews${offset ? `/${offset}` : ''}`)
      .then(({reviews}) => {
        resolve(reviews);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getGiftCards() {

  return new Promise((resolve, reject) => {

    axios.get(`/user/gifts`)
      .then(({giftCards}) => {
        resolve(giftCards);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getGiftCardByCode(code) {

  return new Promise((resolve, reject) => {

    axios.get(`/user/gift/${code}`)
      .then(({giftCard}) => {
        resolve(giftCard);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}


function deletePaymentMethod(paymentMethodId) {

  return new Promise((resolve, reject) => {

    axios.post(`/user/shop/paymentMethod/delete`, {paymentMethodId:paymentMethodId})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function calculateOrderPrice(userId, products, shippingAddress, shippingMethodPrice, shippingMethodId) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/price`,{userId,products,shippingAddress, shippingMethodPrice, shippingMethodId})
      .then(({prices}) => {
        resolve(prices);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findAvailablePromotion(products, code) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/promotion/available`,{products, code})
      .then(({promotion}) => {
        resolve(promotion);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function findUserAvailablePromotion(userId, products, code) {

  return new Promise((resolve, reject) => {

    axios.post(`/admin/user/promotion/available`,{userId, products, code})
      .then(({promotion}) => {
        resolve(promotion);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setCartLog(message, meta) {

  return new Promise((resolve, reject) => {

    axios.post(`/logs/cartInfo`,{message, meta})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateOrderSavePaymentMethod(orderId, savePaymentMethod) {

  return new Promise((resolve, reject) => {

    axios.patch(`/shop/order/${orderId}/savePaymentMethod`, {savePaymentMethod})
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addToFavorites(productId) {

  return new Promise((resolve, reject) => {

    axios.post(`/shop/product/favorite/${productId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeFromFavorites(productId) {

  return new Promise((resolve, reject) => {

    axios.delete(`/shop/product/favorite/${productId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findCart() {

  return new Promise((resolve, reject) => {

    axios.get(`/shop/cart`)
      .then(({products}) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
