import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Toolbar} from "@mui/material";
import { Grid } from "@mui/material";
import { Hidden } from "@mui/material";
import Button from "@mui/material/Button";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import {makeStyles} from '@mui/styles';
import classnames from "classnames";

import { userSelector } from "../../../containers/Authentication/store/selectors";
import { walletActions } from "../../../containers/Customer/Wallet/store/actions";
import { walletSelector } from "../../../containers/Customer/Wallet/store/selectors";
import { Link } from "../../../helpers/multilingual";
import {menuActions} from "../Menu/store/actions";

import SearchDrawer from "./SearchDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 1px 2px !important;'
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.black,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    textDecoration: 'none',
    '& img': {
      height: 42
    }
  },
  signoutAmount: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
  langSelectorButton: {
    marginLeft: 10,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.black,
      fontSize: 24
    },
    '& i': {
      color: theme.palette.common.black,
      textTransform: 'uppercase',
      fontStyle: 'normal',
      fontSize: 14
    }
  },
  navButton: {
    marginLeft: 10,
  },
  buttonIcons: {
    '& .MuiIconButton-root': {
      display: 'inherit',
      //marginLeft: 10,
      cursor: 'pointer',
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.black,
        fontSize: 26
      }
    },
  },
  membershipButton: {
    backgroundColor: red[600],
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const wallet = useSelector(walletSelector());
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    dispatch(menuActions.toggleMenu());
  };

  useEffect(() => {
    if(!wallet.isSet) {
      dispatch(walletActions.getWalletRequest());
    }
  }, []);

  return (
    <AppBar elevation={1} position="sticky" className={classes.root}>
      <Toolbar className={classes.appBar}>
        <Grid container alignItems="center">
          <Grid item xs={2} sm={3}>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Hidden smDown implementation={"css"}>
                  <Link className={classes.logo} to="/pro">
                    <img src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo-pro.svg`} alt=""/>
                  </Link>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} sm={9}>
            <Grid container alignItems="center" justify="flex-end" className={classes.buttonIcons}>
              {/*user.displayWalletSetting && (
                <Grid item>
                  <Button className={classes.walletAmount} onClick={() => history.push('/pro/points')}>
                    {Math.trunc(wallet?.amount)} POINTS
                  </Button>
                </Grid>
              )*/}
              <IconButton className={classnames(classes.navButton, classes.signoutAmount)} onClick={() => history.push('/')}>
                <ExitToAppIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <SearchDrawer searchDrawerOpen={searchDrawerOpen} setSearchDrawerOpen={setSearchDrawerOpen}/>
      </Toolbar>
    </AppBar>
  );
}
