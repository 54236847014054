import React, {useEffect, useState} from "react";
import {Autocomplete} from "@mui/material";
import { grey } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import {makeStyles} from '@mui/styles';

import {SnackbarFormattedError} from "../../../../helpers/snackbar";
import {shopService} from "../store/services";

const useStyle = makeStyles(() => ({
  textField: {},
  option: {
    width: '100%',
    borderBottom: `1px dashed ${grey[600]}`,
    '& p': {
      margin: 0,
      padding: 0,
    },
    '& span': {
      marginRight: 5,
    },
    '& .identifier': {
      margin: 0,
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      color: grey[800]
    },
  },
}));

const SearchCommissionCategoriesAutocomplete = ({value, autoFocus, onSelected, label = null, defaultValue}) => {

  const classes = useStyle();
  const [inputValue, setInputValue] = useState("");
  const [commissionCategories, setCommissionCategories] = useState([]);

  useEffect(() => {
    shopService.findCommissionCategories()
      .then((commissionCategories) => {
        setCommissionCategories(commissionCategories);
      })
      .catch(e => {
        SnackbarFormattedError(e.response.data.error);
      });
  }, []);

  return (
    <Autocomplete
      style={{width: '100%'}}
      autoHighlight={true}
      inputValue={inputValue}
      onChange={(event, value) => {
        onSelected(value);
      }}
      defaultValue={defaultValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      options={commissionCategories}
      noOptionsText={'Pas de commissions trouvé'}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          className={classes.textField}
          autoFocus={autoFocus}
          placeholder={label || 'Chercher une catégorie de commission'}
          variant="outlined"
          //onChange={handleUserSearchChange}
        />
      )}
    />
  );
};
export default SearchCommissionCategoriesAutocomplete;
