import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Box, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DotsLoader from "../../../../../components/DotsLoader";
import {getLangPath} from "../../../../../helpers/multilingual";
import {userSelector} from "../../../../Authentication/store/selectors";
import {shopActions} from "../../store/actions";
import {
  countItemsFromCartSelector,
  isFreeShippingSelector, paymentMethodSelector, processingCheckoutSelector
} from "../../store/selectors";
import {shopService} from "../../store/services";

const CheckoutFooter = ({order, handleGoToNextTab, currentIndex}) => {

  const {t} = useTranslation();
  const isFreeShipping = useSelector(isFreeShippingSelector);
  const [total, setTotal] = useState(0);
  const [displayTotal, setDisplayTotal] = useState(false);
  const paymentMethod = useSelector(paymentMethodSelector);
  const processing = useSelector(processingCheckoutSelector);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [noPaymentOrderProcessing, setNoPaymentOrderProcessing] = useState(false);
  const count = useSelector(countItemsFromCartSelector);
  const history = useHistory();

  useEffect(() => {
    let newTotal = Math.max(0, parseFloat((((order?.totalPrice + order?.taxes - order?.walletAmount - order?.giftCardAmount) * 100) / 100).toFixed(2)));
    if (isNaN(newTotal)) {
      setDisplayTotal(false);
    } else {
      setTotal(newTotal);
      setDisplayTotal(true);
    }
  }, [order?.totalPrice, order?.taxes, order?.walletAmount, order?.giftCardAmount]);

  const handleClick = (e) => {
    e.preventDefault();
    if (currentIndex !== 2) handleGoToNextTab(e, Math.min(2, currentIndex + 1));
  };

  const generateMultisafepayPaymentPageLink = async (ev) => {
    ev.preventDefault();

    dispatch(shopActions.setCheckoutProcessing(true));
    // Enregistrez l'état dans sessionStorage

    shopService.setCartLog("Le client clique sur valider le paiement", {
      from: "checkoutFooter/generateMultisafepayPaymentPageLink",
      userId: user?.id
    }).then().catch();

    shopService.createMultisafepayOrder({orderId: order.id, paymentMethod: paymentMethod?.type}).then(response => {
      if(!response || !response.success) {
        dispatch(shopActions.setCheckoutProcessing(false));
      } else {
        window.location.href = response.data?.payment_url;
      }
    });
    //paymentComponentRef?.current?.getPaymentData();
    //dispatch(shopActions.setCheckoutProcessing(false));
    setTimeout(() => {
      dispatch(shopActions.setCheckoutProcessing(false));
    }, 4000);
  };
  
  const validateNoPaymentOrder = async () => {
    setNoPaymentOrderProcessing(true);
    await shopService.checkoutValidate(order);
    history.push(`${getLangPath()}/shop/?checkout_oid=${order.id}`);
  };

  return (
    <Card sx={{
      boxShadow: '6px 6px 12px #00000021',
      borderRadius: '0',
      position: "absolute",
      bottom: "0px",
      right: "0px",
      width: "100% !important",
      '& .MuiTypography-root': {
        fontFamily: 'Open Sans',
        fontSize: '0.9rem',
        color: '#7C909B',
        textAlign: 'right',
      }
    }}>
      <CardContent sx={{p: '16px !important'}}>
        <Grid container spacing={2} justifyContent={"space-between"} alignContent={"center"}
          alignItems={"flex-start"}>
          <Grid item xs={4}>
            <Typography sx={{textAlign: "left !important"}}>
              {count} articles
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Stack gap={0} alignItems={"flex-end"}>
              {order ?
                <>
                  <Typography>
                    {t("shipping")} :
                    <Box component={"span"} sx={{color: "primary.main"}}>
                      {order?.shippingPrice !== null && order?.shippingPrice !== undefined ?
                        (isFreeShipping || order?.shippingPrice === 0) ? " Offerte" : ` ${order?.shippingPrice}  €`
                        : ' '}
                    </Box>
                  </Typography>
                  {order?.taxes && order?.taxes > 0 ?
                    <Typography>
                      {t("taxes")} :
                      <Box component={"span"} sx={{color: "primary.main"}}>
                        {` ${order?.taxes.toFixed(2)} €`}
                      </Box>
                    </Typography>
                    : null
                  }
                  {order?.promotionAmount && order?.promotionAmount > 0 ?
                    <Typography>
                      Réduction :
                      <Box component={"span"} sx={{color: "primary.main"}}>
                        {` ${order?.promotionAmount} €`}
                      </Box>
                    </Typography>
                    : null
                  }
                </>
                : <DotsLoader/>
              }
              <Typography>
                Montant Total :
                <Typography component={"span"} sx={{
                  fontFamily: 'Montserrat !important',
                  fontSize: '1.1rem !important',
                  fontWeight: 'bold !important',
                }}>
                  {` ${total} €`}
                </Typography>
              </Typography>
              {order && (order.paymentProvider === "points" || order.paymentProvider === "points&giftCard" || order.paymentProvider === "giftCard")  && currentIndex === 2 && (

                <Button
                  title={t("validate")}
                  variant={"contained"}
                  onClick={() => validateNoPaymentOrder()}
                  disabled={noPaymentOrderProcessing}
                  sx={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    textTransform: 'capitalize',
                    borderRadius: '30px',
                    color: '#fff',
                    lineHeight: '1.3',
                    padding: '8px 8px',
                    width: '150px'
                  }}>
                  {noPaymentOrderProcessing && (
                    `${t("loading")} ...`
                  )}
                  {!noPaymentOrderProcessing && (
                    `${t("validate")}`
                  )}
                </Button>
              )}

              {currentIndex !== 2 && (
                <Button
                  title={t("validate")}
                  variant={"contained"}
                  onClick={handleClick}
                  sx={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    textTransform: 'capitalize',
                    borderRadius: '30px',
                    color: '#fff',
                    lineHeight: '1.3',
                    padding: '8px 8px',
                    width: '150px'
                  }}>
                  {t('validate')}
                </Button>
              )}
              {currentIndex === 2 && (order?.paymentProvider === "multisafepay") && (
                <Button
                  title={t("validate")}
                  variant={"contained"}
                  disabled={processing || !paymentMethod || !paymentMethod.type}
                  onClick={generateMultisafepayPaymentPageLink}
                  sx={{
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    textTransform: 'capitalize',
                    borderRadius: '30px',
                    color: '#fff',
                    lineHeight: '1.3',
                    padding: '8px 8px',
                    width: '150px'
                  }}>
                  {processing ? 'Paiement en cours' : 'Payer'}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CheckoutFooter;