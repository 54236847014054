import React, {useState} from "react";
import { Trans, useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import {makeStyles} from '@mui/styles';

import FormValidator from "../../../../components/Validators/FormValidator";
import TextFieldValidator from "../../../../components/Validators/TextFieldValidator";
import { SnackbarConfirm, SnackbarError, SnackbarSuccess } from "../../../../helpers/snackbar";
import SearchConsultantAutocomplete from "../../../Authentication/components/SearchConsultantAutocomplete";
import { customersService } from "../store/services";


const useStyle = makeStyles((theme) => ({

  root: {
    overflow: 'auto',
    minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80
    }
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30
  }
}));

const CustomerConsultantRedirectionModal = ({customer, handleClose, open}) => {

  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [message, setMessage] = useState(t('customerRedirectionDefaultMessage'));
  const [consultant, setConsultant] = useState(null);

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };
  const handleSelectConsultant = (e, consultant) => {
    setConsultant(consultant);
  };

  const handleSubmit = () => {

    if(consultant) {
      SnackbarConfirm(t('entrustCustomerTo', {name: `${consultant.firstName} ${consultant.lastName}`}), () => {
        customersService.certifiedConsultantRedirectionRequest(consultant.id, customer.id, message)
          .then(() => {
            SnackbarSuccess(t('requestSent'));
            handleClose();
          })
          .catch(() => {
            SnackbarError(t('errorMessageAnErrorOccurred'));
          });
      });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen}>
      <CloseIcon className={classes.closeButton} onClick={handleClose}/>
      <DialogTitle id="responsive-dialog-title"><Trans i18nKey="entrustCustomerToNutritionConsultant">Entrust the customer to a nutrition consultant</Trans></DialogTitle>
      <DialogContent>
        <Card elevation={0} className={classes.root}>
          <CardContent>
            <FormValidator onSubmit={handleSubmit} noValidate autoComplete={"off"} onError={console.log}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <Trans i18nKey={"entrustCustomerToNutritionConsultantDescription1"}>
                                            You are about to entrust your client to a nutrition advisor. This requires your client to have professional support adapted to his needs.
                    </Trans>
                  </Typography>
                  <Typography>
                    <Trans i18nKey={"entrustCustomerToNutritionConsultantDescription2"}>
                                            This help is free as long as you are not certified. <br/> After certification, you will have the choice between recovering your customer or paying for the service up to 5% of your commissions.
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldValidator
                    fullWidth
                    multiline
                    required
                    rows={3}
                    variant={"outlined"}
                    name="content"
                    label={t("message")}
                    value={message}
                    onChange={handleChangeMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify={"center"}>
                    <Grid item xs={12}>
                      <SearchConsultantAutocomplete onChange={handleSelectConsultant} target={"certified"}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justify={"flex-end"}>
                    <Grid item>
                      <Button
                        fullWidth
                        type={"submit"}
                        variant="contained"
                        color="primary"
                        disabled={!consultant}
                      >
                        <Trans i18nKey={"submit"}>
                                                    Submit
                        </Trans>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="button"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        <Trans i18nKey={"cancelButton"}>
                                                    Cancel
                        </Trans>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormValidator>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
export default CustomerConsultantRedirectionModal;
